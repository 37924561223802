import React from 'react';
import Color from 'color';
import { Colors } from '@capcenter/shared';
import Icon from '../components/Icon';
import { getUrlWithUtmQueryString } from '@capcenter/shared-legacy';

class Button extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      active: false,
    };
  }

  toggleHover = () => this.setState({ hover: !this.state.hover });
  toggleActive = () => this.setState({ active: !this.state.active });

  render() {
    let {
      text,
      textColor,
      color,
      style,
      light,
      href,
      onClick,
      className,
      icon,
      iconStyle,
      iconRight,
      iconRightStyle,
      borderColor,
      target,
      iconPulse,
      children,
      tabIndex,
    } = this.props;

    const btnColor = color ? color : Colors.primary;

    const btnColorDark = Color(btnColor).darken(0.2);

    const thisTextColor = textColor ? textColor : light ? Colors.black500 : Colors.white100;

    const textDark = Color(thisTextColor).darken(0.4);

    let styles = {
      color: thisTextColor,
      backgroundColor: btnColor,
      borderColor: borderColor ? borderColor : btnColor,
    };

    if (this.state.hover) {
      styles.backgroundColor = btnColorDark;
      styles.borderColor = btnColorDark;
      styles.color = btnColor === 'transparent' ? textDark : thisTextColor;
      styles.textDecoration = btnColor === 'transparent' ? 'underline' : 'none';
    }

    if (this.state.active) {
      styles.boxShadow = btnColor === 'transparent' ? 'none' : 'inset 0 1px 7px rgba(0,0,0,.25)';
    }

    const iconStyles = {
      marginRight: text === '' ? 0 : 5,
    };

    const iconRightStyles = {
      marginLeft: text === '' ? 0 : 5,
    };

    if (text === undefined) {
      text = <span className="alert-danger">Text Missing</span>;
    }

    const BtnInnards = (
      <>
        {children}
        {icon && <Icon icon={icon} pulse={iconPulse} style={{ ...iconStyles, ...iconStyle }} />}
        {text}
        {iconRight && <Icon icon={iconRight} style={{ ...iconRightStyles, ...iconRightStyle }} />}
      </>
    );

    if (href !== undefined) {
      return (
        <a
          role="button"
          id={this.props.id}
          className={'btn ' + className}
          href={getUrlWithUtmQueryString(href)}
          target={target}
          onClick={onClick}
          style={{ ...styles, ...style }}
          tabIndex={tabIndex}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
          onMouseDown={this.toggleActive}
          onMouseUp={this.toggleActive}
        >
          {BtnInnards}
        </a>
      );
    } else {
      return (
        <div
          role="button"
          id={this.props.id}
          className={'btn ' + className}
          onClick={onClick}
          style={{ ...styles, ...style }}
          tabIndex={tabIndex}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
          onMouseDown={this.toggleActive}
          onMouseUp={this.toggleActive}
        >
          {BtnInnards}
        </div>
      );
    }
  }
}

export default Button;
