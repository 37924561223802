import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AppInt from '../constants/App.interface';
const AppList = require('../constants/AppList.json');

const AppHelmet: React.SFC<{
	appItem?: AppInt;
	app?: string;
  custom?: any;
	ogTitle?: string;
	pageTitle?: string;
}> = props => {
  const item = (props.appItem !== undefined && Object.keys(props.appItem).length > 0) ? props.appItem : (props.app ? AppList[props.app] : undefined) ?? props.custom ?? {};
  if (item === undefined || Object.keys(item).length === 0) return <></>;
  const titleMod = item.metaTitle ?? item.title;
  const metaDescriptionMod = item.metaDescription ?? item.description ?? '';
  const keywordsMod = item.keywords ?? metaDescriptionMod ?? '';
  const canonicalUrl = item.url;

  return (
    <HelmetProvider>
      <Helmet defer={false}>
        <title>
          { props.pageTitle ? props.pageTitle : titleMod } | CapCenter
        </title>

        <meta name="description" content={metaDescriptionMod} />
        <meta name="keywords" content={keywordsMod} />
        <meta name="robots" content="index, follow" />

        <meta itemProp="name" content={props.ogTitle ? props.ogTitle : item.title} />
        <meta itemProp="description" content={metaDescriptionMod} />
        <meta itemProp="keywords" content={keywordsMod} />
        <meta itemProp="image" content={'https://www.capcenter.com/site-map/images/' + item.title.replace(/\s/g, '') + '.png'} />

        <meta property="og:title" content={props.ogTitle ? props.ogTitle : item.title} />
        <meta property="og:description" content={metaDescriptionMod} />
        <meta property="og:site_name" content="CapCenter: Realty, Mortgage, and Insurance" />

        {(item.industry === 'Realty' &&
          <meta property="og:url" content={'https://www.capcenterhomes.com' + item.url} />
        )}
        {((item.industry === 'Mortgage' || item.industry === 'About' || item.industry === '' || !item.industry) &&
          <meta property="og:url" content={'https://www.capcenter.com' + item.url} />
        )}
        {item.industry === 'Insurance' && (
          <meta property="og:url" content={'https://www.capcenterinsurance.com' + item.url} />
        )}

        {item.acceptsQuery && item.industry === 'Realty' && (
          <link rel="canonical" href={`https://www.capcenterhomes.com${canonicalUrl}`} />
        )}

        {item.acceptsQuery && item.industry === 'Insurance' && (
          <link rel="canonical" href={`https://www.capcenterinsurance.com${canonicalUrl}`} />
        )}

        {item.acceptsQuery && item.industry === 'Mortgage' && (
          <link rel="canonical" href={`https://www.capcenter.com${canonicalUrl}`} />
        )}

        <meta
          property="og:image"
          content={'https://www.capcenter.com/site-map/images/' + item.title.replace(/\s/g, '') + '.png'}
        />

        <meta name="twitter:title" content={props.ogTitle ? props.ogTitle : item.title} />
        <meta name="twitter:description" content={metaDescriptionMod} />
        <meta name="twitter:image" content={'https://www.capcenter.com/site-map/images/' + item.title.replace(/\s/g, '') + '.png'} />
        <meta name="twitter:site" content="@CapCenterLLC" />
        <meta name="twitter:creator" content="@CapCenterLLC" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    </HelmetProvider>
  );
};

export default AppHelmet;
