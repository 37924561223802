import React, { Component } from 'react';
import { Colors, AppIcon } from '@capcenter/shared'
import { Icon } from '@capcenter/shared-legacy'

class Action extends Component {
	
	render(){

    return (
			<a href={this.props.app.url + (this.props.query ? this.props.query : "")} className='cc--action-btn' style={{
					cursor: "pointer", 
					display: "flex", 
					justifyContent: "space-between", 
					alignItems: "center", 
					padding: 15, 
					borderBottom: this.props.last ? 0 :"2px solid " + Colors.gray350,
					color: Colors.black500,
					textDecoration: "none",
				}}>
				<div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
					<div style={{width: "3rem", height: "3rem", marginRight: 20}}>
						<AppIcon app={this.props.app}/>
					</div>
					<div style={{fontSize: "130%", lineHeight: 1.2}}>
						<div style={{fontSize: "145%"}}>
							{this.props.app.title}
						</div>
						<div style={{}}>
							{this.props.app.actionDescription}
						</div>
					</div>
				</div>
				<Icon color={Colors.gray400} icon={["icon","chevron-right"]} style={{fontSize:"160%"}}/>
			</a>
		) 
	}   
};

export default Action;