import { Colors, Weights } from '@capcenter/shared';
import React from 'react';
import Icon from './Icon';
import Tooltip from './Tooltip';
import './BtnGroup.scss';

class BtnGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      currentTip: null,
      vertical: false,
      hover: false,
      active: false,
      disabled: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this.checkSize);
    this.checkSize();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', null);
  }

  checkSize = () => {
    if (this.state.vertical === true) {
      this.setState({ vertical: false }, () => {
        const element = this.btnGroup;

        if (element && element.offsetWidth < element.scrollWidth) {
          this.setVertical();
        }
      });
    } else {
      const element = this.btnGroup;

      if (element && element.offsetWidth < element.scrollWidth) {
        this.setVertical();
      }
    }
  };

  setVertical = () => {
    if (!this.state.vertical) {
      this.setState({ vertical: true });
    }
  };

  btnClick = (btn, i) => {
    const count = this.state.count;

    const btnText = btn.text !== undefined ? btn.text : 'htmlText';

    // set this button tooltip to active, record what we're waiting for, and give it a unique number
    this.setState({ currentTip: 'btn' + i, waiting: btnText + i + count, count: count + 1 });

    // perform actual behavior
    if (!btn.disabled) {
      if (this.props.onClick) {
        this.props.onClick(btn);
      } else {
        btn.onClick();
      }
    } else {
      btn.onDisabled && btn.onDisabled();
    }

    let tipLength = 0;

    if (btn.disabled) {
      tipLength = btn.disabledTooltip?.toString().length ?? 0;
    } else if (btn.tooltip) {
      tipLength = btn.tooltip.toString().length;
    }

    const holdMultiplier = 50;
    const holdLength = tipLength * holdMultiplier + 400;

    // if the tooltip hasn't hidden yet, hide it
    setTimeout(
      function () {
        if (this.state.waiting === btnText + i + count && this._isMounted) {
          this.setState({ currentTip: null });
        }
      }.bind(this),
      holdLength,
    );
  };

  hideTooltip = () => {
    this.setState({ currentTip: null });
  };


  toggleActive = i => {
    this.setState({ active: i !== undefined ? i : false });
  };

  render() {
    let btnsShown = [];
    this.props.btns.map((btn, i) => {
      if (btn.hide !== false) {
        btnsShown.push({
          className: btn.className ?? "",
          active: btn.active ?? false,
          disabled: btn.disabled ?? false,
          text: btn.text ?? "",
          ...btn
        });
      }
      return btnsShown;
    });

    return (
      <div
        role="group"
        className={this.props.className}
        id={this.props.id}
        style={{
          ...{
            marginBottom: 10,
            display: 'flex',
            verticalAlign: 'middle',
            position: 'relative',
            flexDirection: this.state.vertical ? 'column' : 'row',
          },
          ...this.props.groupStyle,
        }}
        ref={component => {
          this.btnGroup = component;
        }}
      >
        {btnsShown.map((btn, i) => {

          return (
            <button
              key={i}
              type="button"
              id={btn.id}
              className={(
                `${btn.className} bold btngroup` + // Default styles
                ((btn.active || this.props.active === btn.text) ? ' active' : '') + // Actively selected
                (this.state.vertical ? ' vertical' : '') + // Mobile vertical
                (this.props.invalid ? ' invalid' : '') + // Invalid
                (btn.disabled ? ' disabled' : '') + // Disabled
                (i === 0 ? ' leftEdge' : '') + // Left-most button (top on vertical)
                (this.props.btns.length - 1 === i ? ' rightEdge' : '') // Right-most button (bottom on vertical)
              )}
              onClick={() => this.btnClick(btn, i)}
              onBlur={this.hideTooltip}
              tabIndex={0}
              style={{ ...this.props.style }}
              onMouseDown={() => this.toggleActive(i)}
              onMouseUp={this.toggleActive}
            >
              <Tooltip
                id={'tooltip' + btn.text + 'btn' + i}
                html={
                  btn.tooltip ? (
                    <span>
                      {btn.tooltipTitle ? (
                        <strong style={{ borderBottom: '1px solid ' + Colors.gray450 }}>
                          {btn.tooltipTitle}
                          <br />
                        </strong>
                      ) : (
                        ''
                      )}
                      {btn.tooltip}
                    </span>
                  ) : (
                    undefined
                  )
                }
                error={btn.disabled}
                errorHtml={btn.disabledTooltip}
                open={this.state.currentTip === 'btn' + i && this.props.tooltips !== false}
                distance={15}
                arrow={true}
              >
                <div>
                  {btn.bigIcon && (
                    <>
                      <Icon icon={btn.bigIcon} style={{ borderColor: '#647778', fontSize: '275%', padding: 5 }} />
                      <br />
                    </>
                  )}

                  {btn.icon && (
                    <span style={{ borderColor: '#647778', fontSize: '90%', verticalAlign: '.05rem' }}>
                      <Icon icon={btn.icon} />
                      &nbsp;&nbsp;
                    </span>
                  )}

                  {btn.children ? (
                    <span>{btn.children}</span>
                  ) : (
                    <span>
                      {btn.text}
                      <br />
                      {(btn.bigText || btn.bigNumber) && (
                        <span
                          style={{
                            fontSize: '24px',
                            fontWeight: Weights.light,
                            fontVariantNumeric: btn.bigNumber ? 'tabular-nums' : 'inherit',
                          }}
                        >
                          {btn.bigNumber || btn.bigText}
                        </span>
                      )}
                      {btn.smallText ? <small>{btn.smallText}</small> : <></>}
                    </span>
                  )}
                </div>
              </Tooltip>
            </button>
          );
        })}

        {this.props.append && this.props.append}
      </div>
    );
  }
}
export default BtnGroup;
