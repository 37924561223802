import { quoteUrl } from '@capcenter/shared';
import checkSessionStorage from './checkSessionStorage';

export default async function getProductAvailability(CountyId: string): Promise<Array<any> | string> {
  const url: string = quoteUrl + 'Quote/GetProductAvailability?CountyID=' + CountyId;

  const dataId = 'ccProductAvailability';
  const sessionData = checkSessionStorage(dataId);

  if (CountyId !== '') {
    if (sessionData.CountyId === CountyId) {
      return sessionData;
    } else {
      try {
        let response = await fetch(url);
        let parsedResponse = await response.json();
        let productAvailability: any = parsedResponse;

        window.sessionStorage.setItem(dataId, JSON.stringify(productAvailability));
        window.sessionStorage.setItem(dataId + 'Date', JSON.stringify(new Date()));

        return productAvailability;
      } catch (err) {
        console.log(err);
        return 'Unexpected Product Availability Database Error. Please try again later.';
      }
    }
  } else {
    console.error('Product Avaliability API Requires County ID.');
    return 'Product Avaliability API Requires County ID.';
  }
}
