import React, {Component} from 'react'

export class Images extends Component {

  render() {

    return (
      <div style={{height: "100%", width: "100%"}}>
        <div style={{height: "100%", width: "100%"}}>
          {this.props.images.map((image, i)=>(
            <div key={i} style={{
                width: "50%",
                height: "200px",
                float: "left",
                justifyContent: "center",
                alignItems: "center",
                overflow:"hidden",
                backgroundImage: `url(${image})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"}} />
                ))}
            </div>
        </div>
    );
  }
}

export default Images