import React from 'react';
import {baseUrl} from '../../Config'
const AppList = require('../../constants/AppList.json')

const SVGIcon: React.SFC<{icon: string}> = (props) => {
	
	return (
		<img className="img-fluid align-top" src={baseUrl + AppList.siteMap.url + "/svg-icons/"+props.icon+".svg"} alt={props.icon + " icon"}/>
	)
}

export default SVGIcon