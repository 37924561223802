import React from 'react';
import Color from 'color';
import { Colors } from '@capcenter/shared';
import Tooltip from './Tooltip';

class SavingsBtnGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      currentTip: null,
      vertical: false,
      hover: false,
      active: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', this.checkSize);
    this.checkSize();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', null);
  }

  checkSize = () => {
    if (this.state.vertical === true) {
      this.setState({ vertical: false }, () => {
        const element = this.savingsBtnGroup;

        if (element && element.offsetWidth < element.offsetHeight * 3) {
          this.setVertical();
        }
      });
    } else {
      const element = this.savingsBtnGroup;

      if (element && element.offsetWidth < element.offsetHeight * 3) {
        this.setVertical();
      }
    }
  };

  setVertical = () => {
    if (!this.state.vertical) {
      this.setState({ vertical: true });
    }
  };

  btnClick = (btn, i) => {
    if (!this.props.refi) {
      const count = this.state.count;

      const btnText = btn.text !== undefined ? btn.text : 'htmlText';

      // set this button tooltip to active, record what we're waiting for, and give it a unique number
      this.setState({ currentTip: 'btn' + i, waiting: btnText + i + count, count: count + 1 });

      // perform actual behavior
      if (!btn.disabled) {
        if (this.props.onClick) {
          this.props.onClick(btn);
        } else {
          btn.onClick();
        }
      } else {
        btn.onDisabled && btn.onDisabled();
      }

      let tipLength = 0;

      if (btn.disabled) {
        tipLength = btn.disabledTooltip?.toString().length ?? 0;
      } else if (btn.tooltip) {
        tipLength = btn.tooltip.toString().length;
      }

      const holdMultiplier = 50;
      const holdLength = tipLength * holdMultiplier + 400;

      // if the tooltip hasn't hidden yet, hide it
      setTimeout(
        function () {
          if (this.state.waiting === btnText + i + count && this._isMounted) {
            this.setState({ currentTip: null });
          }
        }.bind(this),
      
        holdLength,
      );
    }
  };

  hideTooltip = () => {
    this.setState({ currentTip: null });
  };

  toggleHover = i => {
    this.setState({ hover: i !== undefined ? i : false });
  };

  toggleActive = i => {
    this.setState({ active: i !== undefined ? i : false });
  };

  render() {
    let btnsShown = [];

    this.props.btns.map((btn, i) => {
      if (btn.hide !== false) {
        btnsShown.push(btn);
      }
      return btnsShown;
    });

    const btnColorDark = Color(this.props.color).darken(0.2);

    const btnRefi = this.props.refi;

    let styleBtn = (btn, i) => {
      const isActive = btn.active || (this.props.active === btn.text && this.props.active !== undefined) ? true : false;

      let styles = {
        display: 'inline-block',
        fontSize: this.props.textSm ? '.85rem' : 'inherit',
        fontFamily: 'omnes-pro, Arial, sans-serif',
        cursor: 'pointer',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: i === 0 && this.props.clipBorder ? 0 : 1,
        borderRightWidth: btnsShown.length - 1 === i && this.props.clipBorder ? 0 : 1,
        borderStyle: 'solid',
        width: this.props.refi ? '55%' : '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
        padding: '.375rem .375rem',

        borderTopLeftRadius: 0 === i ? '.25rem' : 0,
        borderTopRightRadius: btnRefi ? (0 === i ? '.25rem' : 0) : this.props.btns.length - 1 === i ? '.25rem' : 0,
        borderBottomRightRadius: this.props.btns.length - 1 === i ? '.25rem' : 0,
        borderBottomLeftRadius:
          this.props.btns.length - 1 === i && btnRefi ? '.25rem' : 0 === i ? (btnRefi ? 0 : '.25rem') : 0,

        transition:
          'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
        backgroundColor: isActive ? (this.props.color ? this.props.color : Colors.primary) : Colors.white100,
        borderColor: this.props.invalid
          ? Colors.brightRed
          : isActive
          ? this.props.color
            ? this.props.color
            : Colors.primary
          : '#647778',
        zIndex: isActive ? 2 : 1,
        color: isActive ? Colors.white100 : Colors.black500,
        whiteSpace: 'normal',
        marginLeft: btnRefi ? "22.5%" : 0 === i ? 0 : -1,
        marginTop: btnRefi ? (0 === i ? 0 : -1) : 0,
        maxWidth: btn.maxWidth ? btn.maxWidth : 'none',
      };

      if (this.state.hover === i) {
        styles.backgroundColor = btn.disabled ? Colors.gray350 : this.props.color ? btnColorDark : Colors.orange600;
        styles.color = this.props.light ? Colors.black500 : Colors.white100;
        styles.borderColor = btn.disabled ? Colors.gray450 : this.props.color ? btnColorDark : Colors.orange600;
      }

      if (this.state.active === i) {
        styles.backgroundColor = btn.disabled
          ? Colors.red500
          : this.props.color
          ? Color(this.props.color).darken(0.3)
          : Colors.orange600;
        styles.color = Colors.white100;
        styles.borderColor = this.props.color ? Color(this.props.color).darken(0.3) : Colors.orange600;
      }

      return styles;
    };

    const groupStyles = {
      marginBottom: 10,
      display: 'flex',
      verticalAlign: 'middle',
      position: 'relative',
    };

    const disabledStyles = {
      color: Colors.gray450,
      backgroundColor: Colors.gray350,
      borderColor: Colors.gray400,
      zIndex: 0,
    };

    let propsStyle = this.props.style;

    if (propsStyle !== undefined && propsStyle.borderRadius !== undefined) {
      propsStyle.borderTopLeftRadius = propsStyle.borderRadius;
      propsStyle.borderBottomLeftRadius = propsStyle.borderRadius;
      propsStyle.borderTopRightRadius = propsStyle.borderRadius;
      propsStyle.borderBottomRightRadius = propsStyle.borderRadius;
      delete propsStyle.borderRadius;
    }

    return (
      <div
        role="group"
        className={this.props.className}
        id={this.props.id}
        style={{ ...groupStyles, ...this.props.groupStyle }}
        ref={component => {
          this.savingsBtnGroup = component;
        }}
      >
        {btnsShown.map((btn, i) => {
          const isActive = btn.active || this.props.active === btn.text ? true : false;

          const activeStyle = isActive ? this.props.activeStyle : null;

          const disabledStyle = btn.disabled ? disabledStyles : null;

          const btnTooltip = btn.tooltip ? (
            <span>
              {btn.tooltipTitle ? (
                <strong style={{ borderBottom: '1px solid ' + Colors.gray450 }}>
                  {btn.tooltipTitle}
                  <br />
                </strong>
              ) : (
                ''
              )}
              {btn.tooltip}
            </span>
          ) : undefined;

          return (
            <button
              key={i}
              type="button"
              id={btn.id}
              className={btn.className + ' ' + (isActive ? 'active' : '')}
              onClick={() => this.btnClick(btn, i)}
              onBlur={this.hideTooltip}
              tabIndex={0}
              style={{ ...styleBtn(btn, i), ...propsStyle, ...activeStyle, ...disabledStyle }}
              onMouseEnter={() => this.toggleHover(i)}
              onMouseLeave={this.toggleHover}
              onMouseDown={() => this.toggleActive(i)}
              onMouseUp={this.toggleActive}
            >
              <Tooltip
                id={'tooltip' + btn.text + 'btn' + i}
                html={btnTooltip}
                error={btn.disabled}
                errorHtml={btn.disabledTooltip}
                open={this.state.currentTip === 'btn' + i && this.props.tooltips !== false}
                distance={15}
                arrow={true}
              >
                <div>
                  
                  {btn.children ? (
                    <span>{btn.children}</span>
                  ) : (
                    <span>
                      <span style={{display: "inline-block", verticalAlign: "top", paddingRight: this.state.vertical ? '.25rem': "16px", paddingLeft: this.state.vertical ? '.25rem' : "16px", paddingTop: this.state.vertical ? '.5rem' : "6px", paddingBottom: this.state.vertical ? '0.125rem' : "6px"}}>{this.state.vertical ? btn.smallImage : btn.image}</span>
                      {this.state.vertical && (
                        <br />
                      )}
                      <span style={{display: "inline-block", paddingTop: "6px", paddingBottom: this.state.vertical ? '8px' : '0px', textAlign: this.state.vertical ? 'center' : 'left'}}>
                        {btn.text}
                        <br />
                        {(btn.bigText || btn.bigNumber) && (
                          <span
                            style={{
                              fontSize: this.state.vertical ? '2rem' : '38px',
                              fontWeight: 700,
                              lineHeight: this.state.vertical ? '2rem' : '36px',
                              fontVariantNumeric: btn.bigNumber ? 'tabular-nums' : 'inherit',
                              textAlign: this.state.vertical ? 'center' : 'left'
                            }}
                          >
                            {btn.bigNumber || btn.bigText}
                          </span>
                        )}
                        {btn.smallText ? <small 
                          style={{
                            fontSize: this.state.vertical ? '1rem' : '18px', 
                            fontWeight: 500,
                            lineHeight: '22px',
                            textAlign: this.state.vertical ? 'center' : 'left'
                            }}
                          >
                            {btn.smallText}
                          </small> : <></>}
                      </span>
                    </span>
                  )}
                </div>
              </Tooltip>
            </button>
          );
        })}

        {this.props.append && this.props.append}
      </div>
    );
  }
}
export default SavingsBtnGroup;
