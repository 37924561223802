import React, { Component } from 'react';
import { Colors, Weights } from '@capcenter/shared';
import './BuyHero.scss';

export class BuyHero extends Component {
  render() {
    return (
      <div className='buy-hero'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='buy-hero-left col-lg'>

             </div>
            <div className='buy-hero-right col-lg buy-hero-image'>

            </div>
          </div>
        </div>
        <div className='container text-overlap'>
          <div className='row'>
            <div className='buy-hero-left col-lg buy-hero-main'>
              <h1>Buy a home with a <br /> <span style={{ color: Colors.primary, fontWeight: Weights.bold }}>ZERO closing cost</span> mortgage</h1>
              <h2>Close on-time and save thousands with CapCenter</h2>

              <div className='row'>
                <div className='col-sm'>
                  <a href='/apply-now/purchase' title="Get a pre-approval first"><button className='btn-primary'>Get pre-approved, fast</button></a>
                </div>
                <div className='col-sm'>
                  <a href='/mortgage-calculator/purchase' title="Mortgage calculator that shows estimated savings and any fees"><button>See savings details</button></a>
                </div>
              </div>
            </div>
            <div className='buy-hero-right col-lg'>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BuyHero;