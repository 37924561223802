import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { verifyLogin } from '../LoginTimeoutModal/LoginTimeoutModal';
import { handleCredentialResponse, remoteConfig } from '../../services/Firebase';
import { useState } from 'react';

export const GoogleLoginModal = () => {
  const [displayModal, setDisplayModal] = useState(false);
  const [loaded, setLoaded] = useState(false);


  React.useEffect(() => {
    if (!loaded && window.location.host.includes("capcenter.com")) {
      remoteConfig.fetchAndActivate().then(() => {
        if(remoteConfig.getConfigBoolean("showGoogleSignInModal")){
          verifyLogin().then(response => {
            if (response && response.status >= 400) setDisplayModal(true);
            setLoaded(true);
          });
        };
      });
    }
  }, []);

  if (displayModal) {
    return (
      <div style={{display: 'none'}}>
      <GoogleLogin
        onSuccess={credentialResponse => {
          const credential = credentialResponse.credential;
          if (credential) {
            handleCredentialResponse({ credential });
          }
        }}
        onError={() => {
          console.error('Login Failed');
        }}
        useOneTap
        use_fedcm_for_prompt
      />
      </div>
    );
  } else {
    return <></>;
  }
};
