import React, { Component } from 'react';
import Tippy from '@tippyjs/react';
import Icon from './Icon';
import { Colors, Weights } from '@capcenter/shared';
import Color from 'color';
import 'tippy.js/dist/tippy.css';
import './Tooltip.scss';

class CCTooltip extends Component {
  render() {
    const errorHtml = (
      <span style={{ color: Color(Colors.red400).lighten(0.2) }}>
        <Icon icon="exclamation-triangle" color={Colors.red500} /> &nbsp;{this.props.errorHtml}
      </span>
    );

    let tippyOptions = {
      interactive: this.props.interactive ? true : false,
      offset: [this.props.offset ? this.props.offset : 0, this.props.distance ? this.props.distance : 0],
      placement: this.props.position ? this.props.position : 'top',
      content: (
        <div style={{ fontWeight: Weights.bolder }}>
          {this.props.html !== undefined || this.props.html === false ? (
            this.props.error ? (
              errorHtml
            ) : (
              this.props.html
            )
          ) : (
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          )}
        </div>
      ),
    };

    if (this.props.trigger) {
      tippyOptions.trigger = this.props.trigger ? this.props.trigger : 'manual';
    } else {
      tippyOptions.visible = this.props.html === undefined || this.props.html === false ? false : this.props.open;
    }

    return (
      <Tippy className="cctooltip" {...tippyOptions}>
        <div className="d-inline">{this.props.children}</div>
      </Tippy>
    );
  }
}

export default CCTooltip;
