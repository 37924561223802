import React, {Component} from 'react'
import { Colors } from '@capcenter/shared'

export class Images extends Component {

  render() {

    return (
	    <div style={{height: "100%", width: "100%", background:"linear-gradient(to bottom, #FF9144 0%, #F47C28 100%)", color: Colors.white100}}>
				<div className="container d-sm-none" style={{height: "100%", width: "100%", padding: 0}}>
						{this.props.textBlocks.map((text, i)=>(
							<div key={i} style={{
								width: "50%", 
								height: "50%",
								padding: "10px 15px",
								float: "left",}}>
								<div>
								<strong>{text.title}</strong><br/>
								<small style={{display: "inline-block", lineHeight: 1.2}}>{text.text}</small>
								</div>
								
							</div>
						))}
				</div>
				<div className="container d-none d-sm-block" style={{height: "100%", width: "100%"}}>
						{this.props.textBlocks.map((text, i)=>(
							<div key={i} style={{
								width: "50%", 
								height: "50%",
								padding: 15,
								float: "left",}}>
								<div>
								<strong>{text.title}</strong><br/>
								<small style={{display: "inline-block", lineHeight: 1.2}}>{text.text}</small>
								</div>
								
							</div>
						))}
				</div>
	    </div>
    );
  }
}

export default Images