const checkSessionStorage = (item: string) => {
  const ccItem: any = JSON.parse((sessionStorage as any).getItem(item));
  const ccItemDate: any = JSON.parse((sessionStorage as any).getItem(item + 'Date'));

  if (ccItem !== null && ccItemDate !== null) {
    const hours = (Date.now() - new Date(ccItemDate).getTime()) / 1000 / 60 / 60;

    if (hours < 12) {
      return ccItem;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export default checkSessionStorage;
