import { getUtmCookieAsUrlQueryString } from '@capcenter/shared'

// There is a similar file in both Shared and Shared Legacy

function isCapCenterDomain(lowercaseUrl : string) {
  let capCenterDomain = false;

  if (
    lowercaseUrl.indexOf('.capcenter.com') > -1
    || lowercaseUrl.indexOf('.capcenterhomes.com') > -1
    || lowercaseUrl.indexOf('.capcenterinsurance.com') > -1
    || lowercaseUrl.indexOf('.capcenterrealty.com') > -1) {
    capCenterDomain = true;
  }

  return capCenterDomain;
}

  const getUrlWithUtmQueryString = (url : string | undefined) => {

    let returnVal = url;
    const isAbsolute = url && url.toLowerCase().startsWith("http");
    const utmCookieAsUrlQueryString = getUtmCookieAsUrlQueryString();
    const lowercaseUrl = url?.toLowerCase();
    const isDifferentCapCenterDomain = lowercaseUrl && !lowercaseUrl.startsWith(window.location.origin.toLowerCase()) && isCapCenterDomain(lowercaseUrl);

  if (((isAbsolute && window.location.origin && isDifferentCapCenterDomain) || !isAbsolute) && utmCookieAsUrlQueryString) {
    if (returnVal && returnVal.indexOf('?') > -1 && returnVal.indexOf('?') === returnVal.length - 1) {
      returnVal += utmCookieAsUrlQueryString.substring(1);
    } else if (returnVal && returnVal.indexOf('?') > -1) {
      returnVal += "&" + utmCookieAsUrlQueryString.substring(1);
    } else {
      returnVal += utmCookieAsUrlQueryString;
    }
  }

  return returnVal;
}

export default getUrlWithUtmQueryString;
