import ProductKeys from '../ProductKeys.json';

const createExceptionsObject = () => {
  let exceptionsObject: { [k: string]: any } = {};

  // LOOP THROUGH QUOTE FIELDS
  Object.keys(ProductKeys).forEach((key: string, i: number) => {
    // FOR EACH FIELD ADD AN EXCEPTION OBJECT
    exceptionsObject[key] = {} as { [k: string]: any };

    // LOOP THROUGH FIELD OPTIONS
    (ProductKeys as any)[key as string].map((key2: string) => {
      (exceptionsObject as any)[key][key2] = {} as { [k: string]: Array<string> };

      Object.keys(ProductKeys).forEach((key3: string) => {
        // FOR EACH
        (exceptionsObject as any)[key][key2][key3] = [];
      });

      return null;
    });
  });

  return exceptionsObject;
};

export default createExceptionsObject;
