import isJsonString from '../functions/isJsonString';
import { contentUrl } from '../Config';

const getContent = (contentPath : string) => {
  return fetch(contentUrl + contentPath + "/" + contentPath + ".json?" + Date.now(), {
    method: "GET",
    headers: {
      Accept: "text/plain"
    }
	})
  .then((response: any) => {
	  
	  //console.log(response)
	  
	  let responseText = response.text()
	  
	  if(response.status === 200){
	  	return responseText;
	  }else{
	  	return response;
	  }
	  
	})
  .then((responseText) => {
	  
	  //console.log(JSON.parse(responseText))

	  if(responseText.status === undefined){
	    
	    if(isJsonString(responseText)){
		    //console.log("responseText", responseText);
		    let state = responseText.replace(/(&quot;)/g, '\\"').replace(/(&apos;)/g, "'").replace(/<br\/>/g, "\\n");
		    //console.log("state",state)
		    let parsed = JSON.parse(state)
		    //console.log(state.length, responseText);

				return parsed;
			}else{
				//console.log(responseText);
				return {status: "", statusText: "JSON is not valid"};
			}
		}else{
			return responseText
		}
  })
  .catch((error) => {
    //console.error("Get Error", error);
    return {status: false, statusText: "Error connecting to database"}
  });
}

export default getContent;