import React, { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import FourOFourImage from './Missing.png';

import { AppWrapper } from '@capcenter/shared';
import { RTB, RTBImage } from '@capcenter/shared-legacy';

class FourOFour extends Component {
  render() {
    return (
      <AppWrapper
        appItem={{
          title: '404 Error',
          description: "Sorry, we can't find that page.",
          icon: 'error',
        }}
      >
        <HelmetProvider>
          <Helmet defer={false}>
            <meta name="robots" content="noindex" />
            <meta name="prerender-status-code" content="404" />
          </Helmet>
        </HelmetProvider>

        <RTB preLoad={true}>
          <RTBImage preLoad={true} image={FourOFourImage} />
        </RTB>
      </AppWrapper>
    );
  }
}

export default FourOFour;
