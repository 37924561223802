import React, { Component } from 'react';
import numeral from 'numeral'
import { Colors, Weights } from '@capcenter/shared'
import { Icon, Tooltip } from '@capcenter/shared-legacy'

class SellSavingsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
	    modified: false,
	    hovering: false,
	  }
	}

	modified = () => {
		if(!this.state.modified){
			this.setState({modified: true})
		}
	}

	enter = () => {
		this.setState({hovering: true})
	}

	leave = () => {
		this.setState({hovering: false})
	}

	render(){

    return (

			<div className="text-center" style={{fontWeight: Weights.bold, fontSize: "160%", padding: "15px 10%", width: "100%", MozUserSelect:"none", WebkitUserSelect: "none", msUserSelect:"none"}}>
				<div  onMouseEnter={this.enter} onMouseLeave={this.leave}>
					<div>
						{this.props.text} <span style={{fontVariantNumeric: "tabular-nums"}}>{"$" + numeral(this.props.value).format("0,0")}</span>
					</div>

					<div style={{color: Colors.green500, marginBottom:20}}>
						Save <span style={{fontVariantNumeric: "tabular-nums"}}>{this.props.savings ? "$" + numeral(this.props.savings).format("0,0") : "$_,___"}</span>
					</div>

					<Tooltip
						html={<><Icon icon={["icon","grab"]}/> &nbsp;Slide to Adjust</>}
						open={this.state.hovering && !this.state.modified}
						position={"bottom"}
						distance={8}
						offset={this.props.offset}>
						<div onSelect={this.modified} onClick={this.modified}>
							{this.props.children}
						</div>
					</Tooltip>
				</div>

				<table className="table table-borderless text-left table-sm" style={{fontSize: "80%", margin: 0}}>
					<tbody>
						{this.props.table.map((row, i)=>(
							<tr key={i} style={{
									color: row.color ? row.color : Colors.black500,
									borderTop: row.total ? "2px solid " + Colors.gray350 : 0,
								}}>
								<td >{row.text}</td>
								<td className="text-right" style={{fontVariantNumeric: "tabular-nums"}}>
									{(row.value !== undefined && row.value !== false) ? "$" + numeral(row.value).format("0,0") : "$_,___"}
								</td>
							</tr>
						))}
					</tbody>
				</table>

				{this.props.desc && <span><small className="font-italic">{this.props.desc}</small><br/></span>}

				{this.props.link && <small><a href={this.props.link}>See Details &#8250;</a></small>	}

				{this.props.disclaimer &&
					<React.Fragment>
						<br/>
						<span style={{ fontSize : "50%" }}>{this.props.disclaimer}</span>
					</React.Fragment>
				}
			</div>

		)
	}
}

export default SellSavingsComponent;