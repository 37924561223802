import React from 'react';
import { default as GlobalIcons } from '../constants/GlobalIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

GlobalIcons();

const Icon = (props) => {
  
  const className = " " + props.className + " " + (props.pulse ? "fa-pulse":"")
  
  if( props.icon && typeof props.icon === "string"){
    return <i className={"fas fa-" + props.icon + className} style={props.style}/>
  }else if( props.icon && typeof props.icon === "object" && props.icon[0] === "fas" ){
    return <i className={"fas fa-" + props.icon[1] + className} style={props.style}/>
  }else if( props.icon && typeof props.icon === "object" && props.icon[0] === "fab" ){
    return <i className={"fab fa-" + props.icon[1] + className} style={props.style}/>
  }else if( props.icon && typeof props.icon === "object" && props.icon[0] === "far" ){
    return <i className={"far fa-" + props.icon[1] + className} style={props.style}/>
  }else{
	  return <FontAwesomeIcon {...props}/>    
  }

}

export default Icon