const insureItemExists = (item:any) =>{
	
	if(item !== undefined && item !== null && item !== "" && item !==false && item.length !== 0){
		return true;
	}else{
		return false;
	}
	
}

export default insureItemExists