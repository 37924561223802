import React, { Component } from 'react';
import { Colors, Weights } from '@capcenter/shared';
import Tooltip from '../components/Tooltip';

export class Hero extends Component {
  render() {
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          color: Colors.white100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          fontSize: '200%',
          lineHeight: 1.2,
          background: 'rgba(0,0,0,0.4)',
          zIndex: 100,
        }}
      >
        <div className="container">
          <div className="row" style={{
            padding: '0 50px'
          }}>
            <h1 style={{
              fontFamily: 'proxima-nova',
              fontSize: '3.2rem',
              fontWeight: Weights.bold,
              textShadow: '1px 1px 1px #555',
            }}>{this.props.hero[0]}</h1>
          </div>
          <div className="row" style={{
            padding: '0 50px'
          }}>
            <h2 style={{
              fontFamily: 'acumin-pro',
              fontSize: '1.5rem',
              textShadow: '1px 1px 1px #555',
            }}>
              {
                this.props.tooltip ? (
                  <Tooltip trigger="mouseenter click" interactive html={this.props.tooltip} position="bottom">
                    {this.props.hero[2]}
                  </Tooltip>
                  ) : this.props.hero[2]
              }
            </h2>
          </div>
        </div>
      </div>
    );
  }
}

export default Hero;