import React from 'react';
import { Input } from '@capcenter/shared-legacy';

export default class PercentageFee extends React.Component<{
  active: string;
  onChange: Function;
  fields: any;
  label: string;
  inputKey: string;
  disabled: Boolean;
  invalid: Boolean;
}> {
  readonly state = {
    initialLoad: true,
    tempValue: '',
    tempCount: 0,
    value: '',
  };

  componentDidUpdate() {
    if (this.state.initialLoad && this.state.value !== this.props.active) {
      this.setState({initialLoad: false, value: this.props.active});
    }
  }

  convertDollar = (value: string) => Number(value.replace(/[^0-9-]+/g, ''));

  onChange = (value: number) => {
    const thisTempCount = this.state.tempCount + 1;

    this.setState({ tempCount: thisTempCount, tempValue: value.toString() });

    setTimeout(() => {
      if (thisTempCount.toString() === this.state.tempCount.toString()) {
        this.changeValue(value);
      }
    }, 400);
  };

  changeValue = (value: number) => {
    const newState = {
      tempValue: '',
      value: value.toString(),
    };

    if (value.toString() !== this.state.value.toString()) {
      this.setState(newState, this.props.onChange(value.toString(), this.props));
    }
  };

  convertPercent = (value: string) => {
    let valInt;

    valInt = value.replace(/[^\d.-]/g, '');

    return parseFloat(valInt);
  };

  onChangePercent = (value: string) => {
    const percent: any = this.convertPercent(value);
    if (!Number.isNaN(percent)) {
      const dollarAmount = Math.round((percent / 100) * parseInt(this.props.fields.PurchasePrice));
      this.changeValue(dollarAmount);
    }
  };

  render() {
    const currentPercent: any =
      this.props.fields.PurchasePrice !== ''
        ? (this.convertDollar(this.state.value) * 100) / parseInt(this.props.fields.PurchasePrice)
        : '';

    if (this.props.disabled !== true) {
      return (
        <>
        <div className="form-row">
          <div className="col-12">
            <label htmlFor={'quote--' + this.props.inputKey}>{
              <>
                {this.props.label + " (Annual)"}
              </>
            }</label>
          </div>
        </div>
        <div className="form-row">
          <div className="col-8">
            <Input
              id={'quote--' + this.props.inputKey}
              hasexternallabel
              disabled={this.props.disabled}
              value={
                this.state.tempValue === ''
                  ? this.state.value === ''
                    ? ''
                    : parseInt(this.state.value)
                  : parseInt(this.state.tempValue)
              }
              mask="dollar"
              invalid={this.props.invalid}
              onChange={(e: any) => this.onChange(this.convertDollar(e.target.value))}
            />
          </div>
          <div className="col-4">
            <Input
              id={'quote--' + this.props.inputKey + '-percent'}
              hasexternallabel
              value={currentPercent !== null ? currentPercent : 0}
              disabled={this.props.fields.PurchasePrice === ''}
              mask="percent"
              invalid={this.props.invalid}
              onChange={(e: any) => this.onChangePercent(e.target.value)}
            />
          </div>
        </div>
        </>
      );
    } else {
      return (
        <div className="form-row">
          <div className="col-8">
            <Input mask="dollar" disabled={true} label={this.props.label} value={''} />
          </div>
          <div className="col-4">
            <Input mask="percent" disabled={true} label={<>&nbsp;</>} value={''} />
          </div>
        </div>
      );
    }
  }
}
