import React from 'react';
import getContent from '../../api/getContent';
import shuffleArray from '../../functions/shuffleArray'
import insureEmployeeImage from '../../functions/insureEmployeeImage'
import SVGIcon from '../SVGIcon'
import './EmployeeIcon.scss'

export default class EmployeeIcon extends React.Component<{team?: string}, {profile: any, loaded: boolean}> {
  constructor(props: any) {
    super(props);
    this.state = {
		profile: {image:"", name: ""},
		loaded: false,
    };
  }

	_isMounted : boolean = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

	componentDidMount(){
		this._isMounted = true;
		
		let allProfiles : Array<any> = []
		
		// no match, realty, loan, about

		switch(this.props.team) { 
			case "realty": { 

				getContent("OurTeam").then(ourTeam => {
					
					if(ourTeam.status === undefined){
				
						ourTeam.length !== 0 && (
							ourTeam[0].regions.map((region: any) => {
								
								region.profiles.map((profile : any) => {
									
									allProfiles.push({image: insureEmployeeImage(profile), name: profile.name})
									
									return null;
								})
								return null;
							})
						)
						
						this.setProfile(allProfiles)
						
					}else{
						
						this.setError(ourTeam.statusText)
						
					}					
					
				})
 
			  break; 
			} 
			case "loan": { 
				
				getContent("OurTeam").then(ourTeam => {
					
					if(ourTeam.status === undefined){
				
						ourTeam.length !== 0 && (
							ourTeam[1].regions.map((region : any) => {
								
								region.profiles.map((profile : any) => {
									
									allProfiles.push({image: insureEmployeeImage(profile), name: profile.name})
									
									return null;
								})
								return null;
							})
						)
						
						this.setProfile(allProfiles)
						
					}else{
						
						this.setError(ourTeam.statusText)
						
					}					
					
				})
				
			  break; 
			} 
			case "client-relations": { 
				
				getContent("OurTeam").then(ourTeam => {
					
					if(ourTeam.status === undefined){
				
						ourTeam.length !== 0 && (
							ourTeam[4].regions.map((region : any) => {
								
								region.profiles.map((profile : any) => {
									
									allProfiles.push({image: insureEmployeeImage(profile), name: profile.name})
									
									return null;
								})
								return null;
							})
						)
						
						this.setProfile(allProfiles)
						
					}else{
						
						this.setError(ourTeam.statusText)
						
					}					
					
				})
				
			  break; 
			} 
			case "team": { 
				
				getContent("OurTeam").then(ourTeam => {
					
					if(ourTeam.status === undefined){
				
						ourTeam.map((team : any) => {
							
							team.regions.map((region : any) => {
								
								region.profiles.map((profile : any) => {
									
									allProfiles.push({image: insureEmployeeImage(profile), name: profile.name})
									
									return null;
								})
								return null;
							})
							return null;
						})
						
						this.setProfile(allProfiles)
						
					}else{
						
						this.setError(ourTeam.statusText)
						
					}					
					
				})
				
			  break; 
			} 
			case "about": { 
				getContent("AboutUs").then(ourTeam => {
					
					if(ourTeam.status === undefined){
				
						ourTeam.length !== 0 && (
								
								ourTeam.profiles.map((profile : any) => {
									
									allProfiles.push({image: insureEmployeeImage(profile), name: profile.name})
									
									return null;
								})
						)
						
						this.setProfile(allProfiles)
						
					}else{
						
						this.setError(ourTeam.statusText)
						
					}					
					
				})
			  break; 
			} 
			default: { 

				getContent("OurTeam").then(ourTeam => {
					
					if(ourTeam.status === undefined){
				
						ourTeam.length !== 0 && (
							ourTeam.map((team:any) => {
								team.regions.map((region : any) => {
									
									region.profiles.map((profile : any) => {
										
										allProfiles.push({image: insureEmployeeImage(profile), name: profile.name})
										
										return null;
									})
									return null;
								})
								return null;
							})
						)
						
						getContent("AboutUs").then(ourTeam => {
							
							if(ourTeam.status === undefined){
						
								ourTeam.length !== 0 && (
										
										ourTeam.profiles.map((profile : any) => {
											
											allProfiles.push({image: insureEmployeeImage(profile), name: profile.name})
											
											return null;
										})
								)
								
								let seenNames = {} as any;
								
								// Remove Duplicates
								const allProfilesFiltered = allProfiles.filter(function(currentObject) {
								    if (currentObject.name in seenNames) {
								        return false;
								    } else {
								        seenNames[currentObject.name] = true;
								        return true;
								    }
								});
								
								this.setProfile(allProfilesFiltered)
								
							}else{
								
								this.setError(ourTeam.statusText)
								
							}					
							
						})
						
					}else{
						
						this.setError(ourTeam.statusText)
						
					}					
					
				})

			  break; 
			} 
		}

	}

	setProfile = (allProfiles:any) => {
		const shuffled = shuffleArray(allProfiles)	
		
		if(this._isMounted){
			this.setState({profile: shuffled[0], loaded: true})
	  }
	}
	
	setError = (error:string) => {
		console.log(error)
	}
	
  render() {
	  
    return (
	    <div className={"employee--icon " + (this.state.loaded ? "employee--icon-loaded " : " ")}>
		    
		    <div className="employee--icon-bg rounded-circle"/>
	    	
	    	{this.state.profile ? (
		    	<div className="employee--icon-image">
    				<img src={this.state.profile.image} className="img-fluid rounded-circle" alt={this.state.profile.name}/>
    			</div>		    	
		    ):(
			    <div className="employee--icon-bg">
		    		<SVGIcon icon="agent-circle"/>
		    	</div>
		    )}
    	</div>
    )
  }
}
