import React from 'react';
import './EmployeeUse.scss';

interface EmployeeUseState {
  hidden: boolean;
  hover: boolean;
  height: string | number;
}
class EmployeeUse extends React.Component<any, EmployeeUseState> {
  constructor(props: any) {
    super(props);

    this.state = {
      hidden: false,
      hover: false,
      height: 'inherit',
    };
  }

  content: any;

  componentDidMount() {
    let height = 0;
    let hidden = true;

    height = this.content.clientHeight;

    this.setState({ height, hidden });
  }

  componentDidUpdate() {
    if (this.state.height === 'inherit' && this.props.children !== undefined) {
      this.setState({ hidden: true, height: this.content.clientHeight });
    }
  }

  updateDimensions = () => {
    this.setState({ hidden: false, height: 'inherit' });
  };

  toggle = () => this.setState({ hidden: !this.state.hidden });

  toggleHover = () => this.setState({ hover: !this.state.hover });

  render() {
    return (
      <>
        <div
          className={
            'employee-use ' +
            (this.state.hidden
              ? this.state.hover
                ? 'employee-use--visible'
                : 'employee-use--hidden'
              : 'employee-use--visible')
          }
          onClick={this.toggle}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
        >
          For CapCenter Employee Use <i className={'fas ' + (this.state.hidden ? 'fa-caret-down' : 'fa-caret-up')} />
        </div>

        <div
          style={{
            maxHeight: this.state.hidden ? 0 : this.state.height,
            transition: this.state.height !== 'inherit' ? 'max-height 0.5s, opacity .5s' : 'opacity .5s',
            opacity: this.state.hidden ? 0 : 1,
            pointerEvents: this.state.hidden ? 'none' : 'auto',
          }}
          ref={content => (this.content = content)}
        >
          {this.props.children}
        </div>
      </>
    );
  }
}

export default EmployeeUse;
