import React, { Component } from 'react';
import { Colors } from '@capcenter/shared'
import Action from './Action'
import './Actions.scss'

class Actions extends Component {
	
	Action = ({i, action}) => <Action app={action} query={action.acceptsQuery ? this.props.query : false} last={i===this.props.actions.length -1}/>
	
	render(){

    return (
	    <>
	    <div className="d-lg-none" style={{backgroundColor: Colors.white100, paddingBottom: 15}}>
				<div className="container" style={{padding: 0}}>
					<div className="row" style={{margin: 0}}>
						
						<div className="col-12"  style={{padding: 0}}>
							{this.props.actions.map((action, i)=>(
									<this.Action key={i} i={i} action={action}/>
								))}				
						</div>
					</div>
				</div>
			</div>
	    <div style={{backgroundColor: Colors.white100, paddingBottom: 15}}>
	    	<br className="d-none d-lg-block"/>
				<div className="container" style={{padding: 0}}>
					<div className="row" style={{margin: 0}}>						
						<div className="d-flex col-12 col-lg-6 col-xl-7" style={{backgroundColor: Colors.white100, justifyContent: "center", alignItems: "center", padding: 0}}>
							<div className="container" style={{padding: 0}}>
								{this.props.children}
							</div>
						</div>
		
						<div className="d-none d-lg-flex col col-lg-6 col-xl-5" style={{padding: 0, backgroundColor: Colors.white100, borderLeft: "2px solid " + Colors.gray350, alignItems: "center"}}>
							<div style={{width: "100%"}}>
							{this.props.actions.map((action, i)=>(
								<this.Action key={i} i={i} action={action}/>
							))}
							</div>	
						</div>
					
					</div>
				</div>
				<br className="d-none d-lg-block"/>
			</div>
			</>
		) 
	}   
};

export default Actions;