import React from 'react';
import FieldsInt from '@capcenter/quote/src/components/Fields.interface';
import {Colors, Slider, Weights} from "@capcenter/shared";
import {Icon, Tooltip} from "@capcenter/shared-legacy";
import numeral from "numeral";

export default class RefiSavingsTooltipFields extends React.Component<{
  fields: FieldsInt;
  onChange: Function;
  onChangeBoolean: Function;
  LoanPurpose?: Boolean;
  quotes: any;
  availability: any;
  exceptions: any;
  tooltipOpen : Function;
  setOpen : Function;
  tabClick : Function;
  label: any;
  savings: any;
  sliderValue: any;
  setSliderValue : Function;
  setRefiSavings: Function;
  link: any;
  value: any;
  text: any;
  offset: any;
  table: any;
  desc: any;
  disclaimer: any;
}> {
  readonly state = {
    modified: false,
    hovering: false,
    invalid: false,
  };

  componentDidMount() {
    this.props.onChange("Yes", {inputKey: 'CountyRealty'});
  }

  componentDidUpdate() {
    if (this.props.LoanPurpose && this.props.fields.LoanPurpose === '') {
      this.props.onChange(this.props.LoanPurpose, { inputKey: 'LoanPurpose' });
    }
  }

  modified = () => {
    if(!this.state.modified){
      this.setState({modified: true})
    }
  }

  enter = () => {
    this.setState({hovering: true})
  }

  leave = () => {
    this.setState({hovering: false})
  }

  toggleEditing = () => {
    this.setState({modified: true})

    if(this.props.tooltipOpen !== this.props.label){
      this.props.setOpen(this.props.label)
    } else {
      this.props.setOpen("")
    }
  }

  updateValue = (value: number) => {
    this.setState({ modified: true });
    this.props.setSliderValue(value);
  };

  onAfterChange = (value: number) => {
    const loanDollar = value;
    const price = value / 0.8;
    const downDollar = price - loanDollar;
    this.props.onChange(price, {inputKey: 'PurchasePrice'}, () => {
      this.props.onChange(downDollar, {inputKey: 'DownPayment'}, () => {
        this.props.onChange(loanDollar, {inputKey: 'LoanAmount'}, () => {
          this.props.setRefiSavings({
            value: value,
            stickyValue: value,
            modified: true,
            savings: 0,
            query: this.props.fields
          });
        });
      });
    });
  };

  render() {
    return (

      <div className="text-center" style={{fontWeight: Weights.bold, fontSize: "160%", padding: "15px 10%", width: "100%", MozUserSelect:"none", WebkitUserSelect: "none", msUserSelect:"none"}}>
        <div  onMouseEnter={this.enter} onMouseLeave={this.leave}>
          <div>
            {this.props.text} <span style={{fontVariantNumeric: "tabular-nums"}}>{"$" + numeral(this.props.sliderValue).format("0,0")}</span>
          </div>
          <div style={{color: Colors.green500, marginBottom:20}}>
            Save <span style={{fontVariantNumeric: "tabular-nums"}}>{this.props.savings ? "$" + numeral(this.props.savings).format("0,0") : "$_,___"}</span>
          </div>
          <Slider
            min={100000}
            value={this.props.sliderValue}
            max={1000000}
            onChange={this.updateValue}
            onAfterChange={this.onAfterChange}
            step={5000}
          />

          <Tooltip
            html={<><Icon icon={["icon","grab"]}/> &nbsp;Slide to Adjust</>}
            open={this.state.hovering && !this.state.modified}
            position={"bottom"}
            distance={8}
            offset={this.props.offset}>
            <div onSelect={this.modified} onClick={this.modified}>
              {this.props.children}
            </div>
          </Tooltip>
        </div>

        <table className="table table-borderless text-left table-sm" style={{fontSize: "80%", margin: 0}}>
          <tbody>
          {this.props.table.map((row: any, i: any)=>(
            <tr key={i} style={{
              color: row.color ? row.color : Colors.black500,
              borderTop: row.total ? "2px solid " + Colors.gray350 : 0,
            }}>
              <td >{row.text}</td>
              <td className="text-right" style={{fontVariantNumeric: "tabular-nums"}}>
                {(row.value !== undefined && row.value !== false) ? "$" + numeral(row.value).format("0,0") : "$_,___"}
              </td>
            </tr>
          ))}
          </tbody>
        </table>

        {this.props.desc && <span><small className="font-italic">{this.props.desc}</small><br/></span>}

        {this.props.link && <small><a href={this.props.link}>See Details &#8250;</a></small>	}

        {this.props.disclaimer &&
        <React.Fragment>
          <br/>
          <span style={{ fontSize : "50%" }}>{this.props.disclaimer}</span>
        </React.Fragment>
        }
      </div>
    );
  }
}
