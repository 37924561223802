export enum LoanProgram {
    All = "All",
    Conventional = "Conventional",
    FHA = "FHA",
    VA = "VA"
}
export enum LoanPurpose {
    All = "All",
    Purchase = "Purchase",
    Refinance = "Refinance",
    DURefiPlus = 'DU Refi Plus',
    CashOutRefinance = "Cash Out Refinance"
}
export enum LoanAmount {
    All = "All",
    Conforming = "Conforming",
    HighBalance = "High-Balance",
    Jumbo = "Jumbo",
    LowBalanceConforming = "Low-Balance Conforming"
}
export enum LoanTerm {
    All = "All",
    ThirtyYears = 30,
    TwentyYears = 20,
    FifteenYears = 15,
    TenYears = 10
}
export enum LoanType {
    All = "All",
    Fixed = "Fixed",
    Arm5To6 = "5/6 ARM",
    Arm7To6 = "7/6 ARM",
    Arm10To6 = "10/6 ARM"
}
export enum PropertyType {
    All = "All",
    SingleFamily = "Single Family Residence",
    Condo = "Condominium",
    SingleWideManufacturedHome = "Single-wide Manufactured Home",
    DoubleWideManufacturedHome = "Double-wide Manufactured Home"
}
export enum PropertyUse {
    All = "All",
    PrimaryResidence = "Primary Residence",
    SecondaryResidence = "Second Home",
    InvestmentProperty = "Investment Property"
}

export enum StateCode {
    VA = 51,
    NC = 37,
    SC = 45,
    DC = 11,
    MD = 24,
    GA = 13,
    FL = 12
}

export enum FicoScore {
    B = "(780+) Excellent",
    C = "(760 - 779) Very Good",
    D = "(740 - 759) Very Good",
    E = "(720 - 739) Good",
    F = "(700 - 719) Good",
    G = "(680 - 699) Good",
    H = "(660 - 679) Fair",
    I = "(640 - 659) Fair",
    J = "(620 - 639) Fair",
    K = "(< 620) Needs Improvement"
}

export enum BuyDown {
    None = "None",
    OneZero = "1/0",
    TwoOne = "2/1",
    ThreeTwoOne = "3/2/1"
}

export enum LockPeriod {
    ThirtyDays = "30 Days",
    SixtyDays = "60 Days",
    NinetyDays = "90 Days",
    OneTwentyDays = "120 Days",
    OneEightyDays = "180 Days"
}