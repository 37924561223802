import React, { Component } from 'react';

class LoadingPulseWrap extends Component {
  constructor(props) {
    super(props);

    this.state = {
	    loadingPulse: 0,
	  }  
	}
	
	componentDidMount(){

		this.loadingPulse = window.setInterval(function(){
			this.setState({loadingPulse: this.state.loadingPulse + 1})
		}.bind(this), 750);

	}
	
	componentWillUnmount(){
		clearInterval(this.loadingPulse)
	}


	render(){

    return (
	    <div style={{transition: "opacity .75s ease", opacity: this.state.loadingPulse % 2 ?  1 : .25, pointerEvents: "none", cursor: "not-allowed"}}>
      	{this.props.children}
      </div>
    );
  }
}

export default LoadingPulseWrap;

