import ProductKeys from '../ProductKeys.json';
import createExceptionsObject from './createExceptionsObject';

const findAllExceptions = (allProducts: any) => {
  // FOR EACH BUTTON FIND EXCEPTIONS ( EXAMPLE : PROPERTY USE / INVESTMENT )

  let allExceptions = createExceptionsObject();

  Object.keys(ProductKeys).forEach(function (buttonGroup) {
    (ProductKeys as any)[buttonGroup].map((buttonOption: any) => {
      // FILTER PRODUCTS BY OPTION ( EX: INVESTMENT )

      //console.log(buttonGroup, buttonOption)

      let thisFilter: Function;

      switch (buttonGroup) {
        case 'LoanProgram':
          thisFilter = (row: any) => row.LoanProgram === buttonOption;
          break;
        case 'PropertyUse':
          thisFilter = (row: any) => row.PropertyUse === buttonOption;
          break;
        case 'LoanPurpose':
          thisFilter = (row: any) => row.LoanPurpose === buttonOption;
          break;
        case 'LoanTerm':
          thisFilter = (row: any) => row.LoanTerm === buttonOption;
          break;
        case 'LoanType':
          thisFilter = (row: any) => row.LoanType === buttonOption;
          break;
        case 'LoanAmountType':
          thisFilter = (row: any) => row.LoanAmount === buttonOption;
          break;
        default:
          return null;
      }

      const filteredProducts = JSON.parse(JSON.stringify(allProducts.filter(thisFilter)));

      // GET LIST OF KEYS FOR EACH OPTION
      let keySet = {} as { [k: string]: any };

      filteredProducts.map((product: any, i: number) => {
        Object.keys(product).forEach(function (key) {
          let thisKey = key === 'LoanAmount' ? 'LoanAmountType' : key;

          if (i === 0) {
            keySet[thisKey] = [];
          }

          if (!keySet[thisKey].includes(product[key])) {
            keySet[thisKey].push(product[key]);
          }

          //console.log(key, product[key])
        });

        return null;
      });

      // MATCH ID KEY SET FOR COMPARISON
      Object.keys(keySet).forEach(function (product, i) {
        let typeExists = false;

        Object.keys(ProductKeys).forEach(function (idKey, i) {
          //console.log(keySet, ProductKeys, product, idKey)

          // CHECK IF TYPE EXISTS IN THE KEYSET
          if (product === idKey) {
            typeExists = true;
          }
        });

        if ((ProductKeys as any)[product] !== undefined && typeExists) {
          (ProductKeys as any)[product].map((idOption: string) => {
            let optionExists = false;

            // CHECK IF ALL ID KEY OPTIONS EXISTS IN OPTION KEYSET
            keySet[product].map((pOption: string) => {
              if (pOption === idOption) {
                optionExists = true;
              }

              return null;
            });

            //console.log(allExceptions, product, idOption)

            // IF OPTION DOES NOT EXIST, IT'S AN EXCEPTION
            if (optionExists === false && idOption !== 'All' && product !== buttonGroup) {
              if (allExceptions[product][idOption][buttonGroup].indexOf(buttonOption) === -1) {
                allExceptions[product][idOption][buttonGroup].push(buttonOption);
              }

              if (allExceptions[buttonGroup][buttonOption][product].push(idOption) === -1) {
                allExceptions[buttonGroup][buttonOption][product].push(idOption);
              }
            }

            return null;
          });
        }
      });
      return null;
    });
  });

  return allExceptions;
};

export default findAllExceptions;
