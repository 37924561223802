import React from 'react';
import '../Realty.scss';

class DescriptionSection extends React.Component {
  state = {
    title: '',
    lines: [],
    figureContents: <></>,
  };

  componentDidMount() {
    this.setState({ figureContents: this.props.figureContents });
    this.setState({ title: this.props.title });
    this.setState({ lines: this.props.lines });
  }

  render() {
    return (
      <section className="section-description rtb-flex">
        <div>
          <figure>{this.state.figureContents}</figure>
        </div>
        <div>
          <div>
            <h2 id="descriptionHeader">
              <svg className="h-5 capcenter-logo" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#EA701B"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.564 17.82H11.153V14.981L21.825 8.716V0L6 9.086V32.984H21.825V27.068H13.564V17.82Z"
                ></path>
                <path
                  fill="#EA701B"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.104 0V8.716L35.775 14.981V17.82H33.362V27.068H25.104V32.984H40.926V9.086L25.104 0Z"
                ></path>
              </svg>
              {this.state.title}
            </h2>
            {this.state.lines.map((line, index) => {
              return (
                <div key={`description${index}`}>
                  <div
                    className="rtb-sub-title"
                    data-aos="zoom-in"
                    data-aos-once="true"
                    data-aos-delay={300 * index}
                    data-aos-duration="1000"
                    data-aos-anchor="#descriptionHeader"
                    data-aos-anchor-placement="bottom-center"
                  >
                    {line.subtitle}
                  </div>
                  <p className="mb-4">{line.text}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default DescriptionSection;
