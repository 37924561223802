import React, { MutableRefObject, useEffect, useRef, createRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from "../../Modal"
import SignInMenuJson from './sign-in-menu.json';
import './SignIn.scss';
import {LikeHomeIconSVG, SearchSVG} from "../MegaMenuSVG";
import { getUrlWithUtmQueryString } from '@capcenter/shared';

function SignInModal(props: any) {

  let signInMenuColumns: any[] = [];
  let signInMenuColumnCards: any[] = [];

  SignInMenuJson.forEach(function (menu) {
    menu.cards.forEach(function (card) {
      signInMenuColumnCards.push(
        <a key={card.title} className="cc-nav-sign-in-modal-card" href={getUrlWithUtmQueryString(card.link)} target={card.linkTarget}>
          <div className="cc-nav-dropdown-menu-card-icon">
            { SearchSVG(card.icon) }
          </div>
          <div className="cc-nav-dropdown-menu-card-info">
            <p className="cc-nav-dropdown-menu-card-title">{card.title}</p>
            <p className="cc-nav-dropdown-menu-card-caption">{card.caption}</p></div>
        </a>
      );
    });

    signInMenuColumns.push(
      <div key={menu.menu} className="cc-nav-sign-in-modal-column">
        <h4>{menu.menu}</h4>
        {signInMenuColumnCards}
      </div>);

    signInMenuColumnCards = [];
  });

  return (
    <Modal
      visible={props.show}
      close={props.onHide}
      className="cc-nav-sign-in-modal"
      size="lg"
      title={"Where would you like to go?"}
      modalDialogClassName="cc-nav-modal-diaglog"
    >
      <div className="cc-nav-sign-in-modal-group">
        {signInMenuColumns}
      </div>
    </Modal>
    );
}

export default SignInModal;
