import React, { Component } from 'react';
import Tabs from '../navs/Tabs'
import { AppIcon } from '@capcenter/shared'

class App extends Component {
	
  render() {
	  
	  let tabs = []
	  
	  this.props.pages.map((page,i)=>{
		  
		  if(i!==0 && page.app.url !== '/best-realtor-buy-home'){
		  
			  tabs.push({
				  image: <AppIcon app={page.app}/>,
				  text: page.app.title,
				  linkConst: page.app.url,
				  link: this.props.active === page.app.url ? "/" : page.app.url,
				  
			  })
			  
		  }
		  
		  return null;
		  
	  })

    return (
	    	<div style={{backgroundColor: "transparent", position: "relative"}}>
	    		
	    		{this.props.active === tabs[0].linkConst && (
	    			<div style={{position: "absolute", top:0,bottom:0,left:0, width: "30%", backgroundColor: "white", zIndex:0}}/>
	    		)}

	    		{this.props.active === tabs[tabs.length-1].linkConst && (
	    			<div style={{position: "absolute", top:0,bottom:0,right:0, width: "30%", backgroundColor: "white", zIndex:0}}/>
	    		)}
	    		
					<div className="container" style={{position: "relative", padding: 0, zIndex: 100}}>

						<Tabs 
							active={this.props.active}
							tabClick={this.props.tabClick}
							tabs={tabs}/>
								
					</div>
					
					
					
						    	
				</div>
    );
  }
}

export default App;
