import React from 'react';
import DropSearch from'../components/DropSearch';
import { getTeams } from '@capcenter/shared';
import Tooltip from '../components/Tooltip';
import GetImage from '../functions/GetImage'

class RequestInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
	    agents: [],
	    loading: true,
	    error: false,
	  }
	  
	}

	componentDidMount(){
		
		getTeams(this.props.team, this.props.random).then( allProfiles => {
			
			if(typeof allProfiles !== "string"){
			
				allProfiles.map((profile) => {
					
					profile.image = GetImage(profile)
					profile.email = profile.email + "@capcenter.com";
					profile.text = profile.name;
					profile.active = this.props.active.name === profile.name;
					profile.onClick =  () => this.props.onClick(profile, this.props);
					
					return null;			
				})
			
				this.setState({agents: allProfiles, loading: false})
				
			}else{
				
				this.setError(allProfiles)
				
			}
			
		})


	}
	
	componentDidUpdate(){
		if(this.state.agents.length !== 0 && this.props.active.name !== undefined && this.props.active.email === undefined && this.state.agents[0].email !== undefined){
			this.state.agents.map((profile) => {
				if(profile.name === this.props.active.name){
					this.props.onClick(profile, this.props)
				}
				return null;
			})
		}
	}

	setError = (error) => {
		
		console.log(error)
		
		this.setState({error, loading: false});
		setTimeout(() => { this.setState({error: false})} , 3000)
	}

  render() {
	


	
		return (

			<Tooltip open={this.state.error !== false} error={this.state.error !== false} errorHtml={this.state.error} html={<div />}>				
				<DropSearch 
					btns={this.state.agents} 
					label={this.props.label ? this.props.label : "Submitted By"} 
					placeholder="Select Employee" />
			</Tooltip>

		)
	}
	
}

export default RequestInfo