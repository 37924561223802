import React from 'react';
import { baseRealEstateUrl, getUrlWithUtmQueryString } from '@capcenter/shared';
import '../Realty.scss';

class HomesForSaleSection extends React.Component {
  headerText = 'homes for sale';
  subHeadersText = 'for sale';
  filter = '';

  constructor(props) {
    super(props);
    if (props.pageType === 'sell') {
      this.headerText = 'recently sold homes';
      this.subHeadersText = 'sold';
      this.filter = '/filters/propertyType=RecentlySold';
    }
  }

  render() {
    return (
      <section className="section-homes-for-sale">
        <h2>
          <i className="fas fa-search"></i> Search {this.headerText}
        </h2>

        <div className="rtb-flex">
          <article className="homes-for-sale-state">
            <h3>
              <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/state/virginia${this.filter}`)}>
                Homes {this.subHeadersText} in Virginia
              </a>
            </h3>

            <article className="homes-for-sale-city">
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/alexandria-va${this.filter}`)}>
                  Homes {this.subHeadersText} in Alexandria, VA
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/mclean-va${this.filter}`)}>
                  Homes {this.subHeadersText} in McLean, VA
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/richmond-va${this.filter}`)}>
                  Homes {this.subHeadersText} in Richmond, VA
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/virginia-beach-va${this.filter}`)}>
                  Homes {this.subHeadersText} in Virginia Beach, VA
                </a>
              </h4>
              <div className="show-more">
                <a href="" className="show-more-link">
                  <i className="far fa-plus"></i> Show more cities
                </a>
              </div>

              <div className="d-none">
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/arlington-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Arlington, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/aldie-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Aldie, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/ashburn-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Ashburn, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/chesterfield-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Chesterfield, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/county/chesterfield-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Chesterfield County, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/fairfax-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Fairfax, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/goochland-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Goochland, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/great-falls-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Great Falls, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/county/hanover-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Hanover County, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/county/henrico-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Henrico County, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/louisa-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Louisa, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/midlothian-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Midlothian, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/new-kent-va${this.filter}`)}>
                    Homes {this.subHeadersText} in New Kent, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/powathan-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Powathan, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/reston-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Reston, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/south-riding-va${this.filter}`)}>
                    Homes {this.subHeadersText} in South Riding, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/tuckahoe-creek-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Tuckahoe Creek, VA
                  </a>
                </h4>
                <h4>
                  <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/williamsburg-va${this.filter}`)}>
                    Homes {this.subHeadersText} in Williamsburg, VA
                  </a>
                </h4>
              </div>
            </article>
          </article>

          <article className="homes-for-sale-state">
            <h3>
              <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/state/north-carolina${this.filter}`)}>
                Homes {this.subHeadersText} in North Carolina
              </a>
            </h3>

            <article className="homes-for-sale-city">
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/cary-nc${this.filter}`)}>
                  Homes {this.subHeadersText} in Cary, NC
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/charlotte-nc${this.filter}`)}>
                  Homes {this.subHeadersText} in Charlotte, NC
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/greenville-nc${this.filter}`)}>
                  Homes {this.subHeadersText} in Greenville, NC
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/raleigh-nc${this.filter}`)}>
                  Homes {this.subHeadersText} in Raleigh, NC
                </a>
              </h4>
              <div className="show-more">
                <a href="" className="show-more-link">
                  <i className="far fa-plus"></i> Show more cities
                </a>
              </div>
            </article>
          </article>

          <article className="homes-for-sale-state">
            <h3>
              <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/state/south-carolina${this.filter}`)}>
                Homes {this.subHeadersText} in South Carolina
              </a>
            </h3>

            <article className="homes-for-sale-city">
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/charleston-sc${this.filter}`)}>
                  Homes {this.subHeadersText} in Charleston, SC
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/columbia-sc${this.filter}`)}>
                  Homes {this.subHeadersText} in Columbia, SC
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/greenville-sc${this.filter}`)}>
                  Homes {this.subHeadersText} in Greenville, SC
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/lexington-sc${this.filter}`)}>
                  Homes {this.subHeadersText} in Lexington, SC
                </a>
              </h4>
              <div className="show-more">
                <a href="" className="show-more-link">
                  <i className="far fa-plus"></i> Show more cities
                </a>
              </div>
            </article>
          </article>

          <article className="homes-for-sale-state">
            <h3>
              <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/state/maryland${this.filter}`)}>
                Homes {this.subHeadersText} in Maryland
              </a>
            </h3>

            <article className="homes-for-sale-city">
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/columbia-md${this.filter}`)}>
                  Homes {this.subHeadersText} in Columbia, MD
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/baltimore-md${this.filter}`)}>
                  Homes {this.subHeadersText} in Baltimore, MD
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/bethesda-md${this.filter}`)}>
                  Homes {this.subHeadersText} in Bethesda, MD
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/rockville-md${this.filter}`)}>
                  Homes {this.subHeadersText} in Rockville, MD
                </a>
              </h4>
              <div className="show-more">
                <a href="" className="show-more-link">
                  <i className="far fa-plus"></i> Show more cities
                </a>
              </div>
            </article>
          </article>

          <article className="homes-for-sale-state">
            <h3>
              <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/state/washington-dc${this.filter}`)}>
                Homes {this.subHeadersText} in District of Columbia
              </a>
            </h3>

            <article className="homes-for-sale-city">
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/postal-code/20057${this.filter}`)}>
                  Homes {this.subHeadersText} in Georgetown, DC
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/postal-code/20007${this.filter}`)}>
                  Homes {this.subHeadersText} in Glover Park, DC
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/postal-code/20009${this.filter}`)}>
                  Homes {this.subHeadersText} in Kalorama, DC
                </a>
              </h4>
              <h4>
                <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/postal-code/20420${this.filter}`)}>
                  Homes {this.subHeadersText} in Logan Circle, DC
                </a>
              </h4>
              <div className="show-more">
                <a href="" className="show-more-link">
                  <i className="far fa-plus"></i> Show more neighborhoods
                </a>
              </div>
            </article>
          </article>
        </div>
      </section>
    );
  }
}

export default HomesForSaleSection;
