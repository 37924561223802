import React from 'react'
import { Colors } from '@capcenter/shared';

export class Break extends React.Component {

  render() {

    return (
	    <div style={{
		    backgroundColor: Colors.gray350, 
		    height: 15
		   }}>
      </div>
    );
  }
}

export default Break