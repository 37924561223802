// FA Solid
import { library } from '@fortawesome/fontawesome-svg-core';

// Custom Icons
import CapCenter from '../icons/CapCenter';
import BBB from '../icons/BBB';
import EHO from '../icons/EHO';
import CloseThin from '../icons/CloseThin';
import ChevronLeftLg from '../icons/ChevronLeftLg';
import external from '../icons/external';
import buy from '../icons/buy';
import resources from '../icons/resources';
import sell from '../icons/sell';
import chevronForward from '../icons/chevron-forward';
import chevronBack from '../icons/chevron-back';
import chevronJumpBack from '../icons/chevron-jump-back';
import lockDirection from '../icons/lockDirection';
import arm from '../icons/arm';
import fha from '../icons/fha';
import fixed from '../icons/fixed';
import va from '../icons/va';
import cash from '../icons/cash';
import refi from '../icons/refi';
import gps from '../icons/gps';
import gpsError from '../icons/gpsError';
import house from '../icons/house';
import townhouse from '../icons/townhouse';
import condo from '../icons/condo';
import click from '../icons/click';
import secondary from '../icons/secondary';
import primary from '../icons/primary';
import investment from '../icons/investment';
import apply from '../icons/apply';
import purchase from '../icons/purchase';
import phone from '../icons/phone';
import tag from '../icons/tag';
import moreCash from '../icons/more-cash';
import lock from '../icons/lock';
import chartLine from '../icons/chart-line';
import checkCircle from '../icons/check-circle';
import crown from '../icons/crown';
import home from '../icons/home';

const icons = {
  chevronForward,
  chevronBack,
  chevronJumpBack,
  CapCenter,
  BBB,
  EHO,
  CloseThin,
  ChevronLeftLg,
  external,
  buy,
  resources,
  sell,
  lockDirection,
  arm,
  fha,
  fixed,
  va,
  cash,
  refi,
  gps,
  gpsError,
  house,
  townhouse,
  condo,
  click,
  secondary,
  primary,
  investment,
  apply,
  purchase,
  phone,
  tag,
  moreCash,
  lock,
  chartLine,
  checkCircle,
  crown,
  home
};

const addIcons = () => library.add(icons);

export default addIcons;
