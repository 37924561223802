import React, { Component } from 'react';
import BigBtns from './BigBtns';

class Tabs extends Component {
	

	render(){

    return (
			<div>
				<div>
					<BigBtns btns={this.props.tabs} active={this.props.active} onClick={this.props.tabClick}/>	
				</div>
				{this.props.tabs.map((tab, i)=>(
					
					<div key={i} >
						
						{this.props.active === tab.text && tab.child }				
					
					</div>
					
				))}

			</div>
		) 
	}   
};

export default Tabs;