import React from 'react';
import { Tooltip, Input } from '@capcenter/shared-legacy';

export default class ExistingLoanBalance extends React.Component<{
  active: string;
  onChange: Function;
  label: string;
  inputKey: string;
  disabled: Boolean;
  invalid: Boolean;
}> {
  readonly state = {
    invalidValue: '',
  };

  convertDollar = (value: string) => Number(value.replace(/[^0-9-]+/g, ''));

  onChange = (value: number) => {
    if (value < 0) {
      this.setState({ invalidValue: value.toString() });
    } else {
      this.setState({ invalidValue: '' }, this.props.onChange(value.toString(), this.props));
    }
  };

  render() {
    return (
      <Tooltip
        html={<div />}
        error={true}
        distance={10}
        errorHtml={'Amount must be higher than $0.00'}
        open={this.state.invalidValue !== ''}
      >
        <Input
          label={this.props.label}
          invalid={(this.props.invalid || this.state.invalidValue !== '') && !this.props.disabled}
          invalidCheck={true}
          disabled={this.props.disabled}
          value={
            this.state.invalidValue !== ''
              ? this.state.invalidValue
              : this.props.active === ''
              ? ''
              : parseInt(this.props.active)
          }
          mask="dollar"
          onChange={(e: any) => this.onChange(this.convertDollar(e.target.value))}
        />
      </Tooltip>
    );
  }
}
