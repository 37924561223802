import React, {Component} from 'react'
import getQuote from '../api/quote/getQuote'
import moment from 'moment'

export class RatesUpdated extends Component {
	_isMounted = false;

  constructor(props) {
    super(props);  
    this.state = {
	   	rates: [],
	   	loading: true,
    }
  }
  

  componentWillUnmount() {
    this._isMounted = false;
  }

	componentDidMount(){
		this._isMounted = true;
		
getQuote("CountyId=123&DownPayment=60000&LoanAmount=240000&LoanProgram=1&LoanPurpose=1&LoanTerm=1&LoanType=1&NeedRealtyTeam=true&PropertyType=1&PropertyUse=1&PropertyValue=300000&PurchasePrice=300000&RegularMilitary=false&PreviousVALoan=false&HomeownersInsuranceEscrow=0&PropertyTax=0").then(quote => {
			
			if(quote.status === undefined){
		
				if(this._isMounted){
					this.setState({rates: quote, loading: false})
				}
				
			}else{
				
				this.setError(quote.statusText)
				
			}					
			
		})
	}
	
	setError = (error) => {
		console.log(error)
		this.setState({error: error})
	}

  render() {



    return (
	    <>
				Rates last updated {(this.state.rates.length !== 0 && this.state.rates[0].Rate) ? moment(this.state.rates[0].Rate.RateLastUpdated).format("MM/DD/YYYY, h:mm A") : "..."}
	    </>
    );
  }
}

export default RatesUpdated