import React from 'react';
import { Tooltip, Input } from '@capcenter/shared-legacy';

export default class DownPayment extends React.Component<{
  active: string;
  onChange: Function;
  fields: any;
  label: string;
  inputKey: string;
  disabled: Boolean;
  invalid: Boolean;
}> {
  readonly state = {
    invalid: false,
    tempValue: '',
    value: '',
    percent: '',
    tempPercent: ''
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    let changeDownpayment = false;

    if (this.props.fields.PurchasePrice !== '' &&
      this.props.fields.LoanAmount !== '' &&
      this.state.value === '') {
      // Down payment blank but purchase price and loan amount is available
      changeDownpayment = true;
    } else if (
      // Purchase price changed and down payment is not empty
      this.props.fields.PurchasePrice !== prevProps.fields.PurchasePrice &&
      this.state.value !== ''
    ) {
      changeDownpayment = true;
    } else if (
      // Loan amount changed and purchase price and loan ammount is not empty
      this.props.fields.LoanAmount !== prevProps.fields.LoanAmount &&
      this.props.fields.PurchasePrice !== '' &&
      this.props.fields.LoanAmount !== ''
    ) {
      changeDownpayment = true;
    }

    if (changeDownpayment && !this.state.invalid) {
      const updatedDownpayment =
        this.convertDollar(this.props.fields.PurchasePrice) -
        this.convertDollar(this.props.fields.LoanAmount);

      this.changeValue( updatedDownpayment > 0 ? updatedDownpayment : 0);
    }
  }

  dollarToPercent = (dollar: number) => {
    return ((dollar * 100) / parseInt(this.props.fields.PurchasePrice)).toFixed(2).toString()
  }


  convertDollar = (value: string) => Number(value.replace(/[^0-9-]+/g, ''));

  onChange = (value: number) => {
    this.changeValue(value);
  };

  changeValue = (value: number) => {
    if (value.toString() !== this.state.value.toString()) {
      if (value > this.props.fields.PurchasePrice) {
        this.setState({
          tempValue: value.toString(),
          value: '',
          invalid: true
        }, this.props.onChange('', {inputKey: 'DownPayment'}));
      } else {
        this.setState({
          tempValue: '',
          value: value.toString(),
          invalid: false
        }, this.props.onChange(value.toString(), {inputKey: 'DownPayment'}));
      }

      this.changePercent(this.props.fields.PurchasePrice !== '' ? this.dollarToPercent(value) + '%' : '');
    }
  };

  convertPercent = (value: string) => {
    let valInt;

    valInt = value.replace(/[^\d.-]/g, '');

    return parseFloat(valInt);
  };

  onChangePercent = (value: string) => {
    this.changePercent(value);
    const percent: any = this.convertPercent(value);
    if (!Number.isNaN(percent)) {
      const dollarAmount = Math.round((percent / 100) * parseInt(this.props.fields.PurchasePrice));
      this.changeValue(dollarAmount);
    }
  };

  changePercent = (percent: string) => {
    if (percent !== this.state.percent) {
      if (parseInt(percent) > 100 || parseInt(percent) < 0){
        this.setState({
          tempPercent: percent,
          percent: '',
          invalid: true
        })
      }
      else {
        this.setState({
          tempPercent: '',
          percent: percent,
          invalid: false
        })
      }
    }
  };

  render() {
    if (this.props.disabled !== true) {
      return (
        <div className="form-row">
          <div className="col-8">
            <Tooltip
              html={<div />}
              error={true}
              distance={-20}
              errorHtml={'Amount cannot be greater than purchase price'}
              open={this.state.invalid}
            >
              <Input
                id='quote--DownPayment'
                label={this.props.label}
                disabled={this.props.disabled}
                value={
                  this.state.tempValue === ''
                    ? this.state.value === ''
                      ? ''
                      : parseInt(this.state.value)
                    : parseInt(this.state.tempValue)
                }
                mask="dollar"
                invalid={this.props.invalid || this.state.invalid}
                invalidCheck={true}
                onChange={(e: any) => this.onChange(this.convertDollar(e.target.value))}
              />
            </Tooltip>
          </div>
          <div className="col-4">
            <Input
              id='quote--DownPaymentPercent'
              label={<>&nbsp;</>}
              value={this.state.tempPercent === ''
                ? this.state.percent === ''
                  ? ''
                  : this.state.percent
                : this.state.tempPercent}
              disabled={this.props.fields.PurchasePrice === ''}
              mask="percent"
              invalid={this.props.invalid || this.state.invalid}
              invalidCheck={true}
              onChange={(e: any) => this.onChangePercent(e.target.value)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="form-row">
          <div className="col-8">
            <Input mask="dollar" disabled={true} label={this.props.label} value={''} id='quote--DownPayment' />
          </div>
          <div className="col-4">
            <Input mask="percent" disabled={true} label={<>&nbsp;</>} value={''} id='quote--DownPaymentPercent' />
          </div>
        </div>
      );
    }
  }
}
