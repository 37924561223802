import React from 'react';
import StabilityCheck from './StabilityCheck';
import QuoteCall from './QuoteCall';
import AvailabilityCall from './AvaliabilityCall';
import QuoteState from './QuoteState';

function QuoteLoader(props: { defaultState?: boolean; queryParameters?: boolean; quoteWidget?: object; children: (props: any) => JSX.Element }) {
  return (
    <QuoteState defaultState={props.defaultState} queryParameters={props.queryParameters} quoteWidget={props.quoteWidget}>
      {(fProps: any) => (
        <AvailabilityCall fields={fProps.fields}>
          {(aProps: any) => (
            <StabilityCheck fields={fProps.fields}>
              {(sProps: any) => (
                <QuoteCall fields={fProps.fields} ready={sProps.ready} readyFields={sProps.readyFields}>
                  {(qProps: any) => (
                    <>
                      {props.children({
                        quotes: qProps.quotes,
                        fields: fProps.fields,
                        onChange: fProps.onChange,
                        onChangeBoolean: fProps.onChangeBoolean,
                        availability: aProps.availability,
                        exceptions: aProps.exceptions,
                      })}
                    </>
                  )}
                </QuoteCall>
              )}
            </StabilityCheck>
          )}
        </AvailabilityCall>
      )}
    </QuoteState>
  );
}

export default QuoteLoader;
