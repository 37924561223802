import React, { Component } from 'react';
import { Colors, Weights, getUrlWithUtmQueryString } from '@capcenter/shared';
import { GetNetProceeds, Slider } from '@capcenter/shared-legacy';
import SellSavingsTooltip from './SellSavingsTooltip';
import SellSavingsComponent from './SellSavingsComponent';
import numeral from 'numeral';

const initVal = 400000;

class SellSavings extends Component {
  constructor(props) {
    super(props);

    if (this.props.sellSavings && Object.keys(this.props.sellSavings).length !== 0) {
      this.state = this.props.sellSavings;
    } else {
      this.state = {
        modified: false,
        value: initVal,
        stickyValue: initVal,
        savings: 0,
      };
    }

    this.props.setSellSavings(this.state);
  }

  updateValue = value => {
    this.setState({ value, modified: true });
  };

  slider = ({ min }) => (
    <Slider
      min={min || 100000}
      value={this.state.value}
      max={1000000}
      onChange={this.updateValue}
      onAfterChange={this.getNetProceeds}
      step={5000}
    />
  );

  componentDidMount() {
    this.getNetProceeds();
  }

  getNetProceeds = () => {
    // Call API
    GetNetProceeds('0', this.state.value, 0).then(data => {

      const tFees = data.costs.listFee.tValue + data.costs.brokerage.tValue
      const ccFees = data.costs.listFee.value + data.costs.brokerage.value
      this.setState({
        savings: data.vals.savings.value,
        tFees,
        ccFees,
        disclaimer: data.minListFee.message
      }, this.props.setSellSavings(this.state));
    });
  };

  render() {
    return (
      <>
        {this.props.tooltip ? (
          <SellSavingsTooltip
            link={getUrlWithUtmQueryString(this.props.thisPage.app.url)}
            active={this.props.active}
            tabClick={this.props.tabClick}
            tooltipOpen={this.props.tooltipOpen}
            setOpen={this.props.setOpen}
            text="Zero Listing Fees"
            savings={'$' + numeral(this.state.savings).format('0,0')}
            label="Sell for"
            value={'$' + numeral(this.state.value).format('0a')}
            first
          >
            <div
              style={{
                color: Colors.white100,
                fontWeight: Weights.bold,
                fontSize: '120%',
                padding: 15,
                width: 260,
                MozUserSelect: 'none',
                WebkitUserSelect: 'none',
                msUserSelect: 'none',
              }}
            >
              <div style={{ fontSize: '120%', fontVariantNumeric: 'tabular-nums', marginBottom: 15 }}>
                ${numeral(this.state.value).format('0,0')}
              </div>
              <this.slider min={100000} />
              Adjust Home Value
            </div>
          </SellSavingsTooltip>
        ) : (
          <SellSavingsComponent
            text="Sell for"
            value={this.state.value}
            savings={this.state.savings}
            table={[
              {
                text: <span>CapCenter&apos;s Low Fees</span>,
                value: this.state.ccFees,
                color: Colors.primary,
              },
              {
                text: <span>Industry Average Fees</span>,
                value: this.state.tFees,
              },
              {
                text: 'Savings with Low Fees',
                value: this.state.savings,
                color: Colors.green500,
                total: true,
              },
            ]}
            link={getUrlWithUtmQueryString('/home-sale-estimate/valuation') + '#quote'}
            disclaimer={this.state.disclaimer}
          >
            <this.slider />
          </SellSavingsComponent>
        )}
      </>
    );
  }
}

export default SellSavings;
