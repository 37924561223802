const mapQuoteRates = (fields: any) => {

  if (fields.LoanProgram === 'All') {
    fields.LoanProgram = 'Conventional'
  }

  const request = {
    AnnualIncome: fields.AnnualIncome,
    CountyID: fields.CountyId,
    FirstTimeHomeBuyer: fields.FirstTimeHomeBuyer,
    LoanAmount: fields.LoanAmount,
    LoanAmountType: fields.LoanAmountType,
    LoanProgram: fields.LoanProgram,
    LoanPurpose: fields.LoanPurpose,
    LoanTerm: fields.LoanTerm,
    LoanType: fields.LoanType,
    NeedRealtyTeam: fields.NeedRealtyTeam,
    PropertyLocation: fields.PropertyLocation,
    State: fields.State,
    ZipCode: fields.ZipCode
  }

  return JSON.stringify(request);
};

export default mapQuoteRates;
