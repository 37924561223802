import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

export const variants = {
  filled: 'filled',
  outline: 'outline',
  outlineRounded: 'outline-rounded',
  flat: 'flat',
};

export const colors = {
  primary: 'primary',
  orangeSecondary: 'orange-secondary',
  secondary: 'secondary',
  bluegray: 'bluegray',
  gray: 'gray',
  accent: 'accent',
  white: 'white',
  positive: 'positive',
  negative: 'negative',
  warning: 'warning',
  darkBlue: 'dark-blue',
  darkGreen: 'dark-green',
  darkRed: 'dark-red',
  empty: '',
};

export const sizes = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

export const types = {
  submit: 'submit',
  reset: 'reset',
  button: 'button',
}

export interface ButtonProps {
  className?: string;
  variant?: string;
  color?: string;
  darkRipple?: boolean;
  disabled?: boolean;
  size?: string;
  children?: any;
  label?: any;
  iconSuffix?: string;
  icon?: string;
  fullWidth?: boolean;
  rounded?: boolean;
  bold?: boolean;
  onClick?: Function;
  href?: string;
  id?: string;
  newTab?: boolean;
  tabIndex?: number;
  resetText?: boolean;
  noDecoration?: boolean;
  title?: string;
  type?: 'submit' | 'reset' | 'button' | undefined
}

const ButtonType = React.forwardRef((props: any, ref) => {
  if (props.href) {
    return (
      <a
        role="button"
        target={props.newTab ? '_blank' : '_self'}
        rel={props.newTab ? 'noopener noreferrer' : ''}
        href={props.href}
        id={props.id}
        className={props.className}
      >
        {props.children}
      </a>
    );
  } else {
    return (
      <button disabled={props.disabled} onClick={props.onClick} id={props.id} className={props.className} type={props.type} title={props.title}>
        {props.children}
      </button>
    );
  }
});

export const Button: React.FunctionComponent<ButtonProps> = props => {
  const ref = React.useRef<HTMLButtonElement>(null);
  return (
    <ButtonType
      className={
        'cc-btn ' +
        (props.className ? props.className : '') +
        (props.variant ? ' cc-btn--' + props.variant : '') +
        (props.color ? ' cc-btn--' + props.color : '') +
        (props.darkRipple ? ' ripple--dark' : '') +
        (props.disabled ? ' disabled' : '') +
        (props.size ? ' cc-btn--' + props.size : '') +
        (props.fullWidth ? ' cc-btn--full' : '') +
        (props.rounded ? ' cc-btn--rounded' : '') +
        (props.bold ? ' cc-font-weight--bold' : '') +
        (props.resetText ? ' cc-btn--reset-text' : '') +
        (props.noDecoration ? ' text-decoration-none' : '')
      }
      title={props.title}
      disabled={props.disabled && props.disabled}
      onClick={props.onClick}
      href={props.href}
      newTab={props.newTab}
      id={props.id}
      ref={ref}
      tabIndex={props.tabIndex}
      type={props.type ? props.type : 'button'}
    >
      {props.icon && props.label !== '' ? <i className={'cc-btn__icon cc-btn__icon--prefix ' + props.icon} /> : <></>}
      {props.label && props.label}

      {props.icon && props.label === '' && <i className={'cc-btn__icon ' + props.icon} />}
      {props.iconSuffix ? <i className={'cc-btn__icon cc-btn__icon--suffix ' + props.iconSuffix} /> : <></>}
      {props.children && props.children}
    </ButtonType>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  label: PropTypes.any,
  variant: PropTypes.oneOf(Object.keys(variants)),
  color: PropTypes.oneOf(Object.keys(colors)),
  size: PropTypes.oneOf(Object.keys(sizes)),
  darkRipple: PropTypes.bool,
  disabled: PropTypes.bool,
  iconSuffix: PropTypes.string,
  icon: PropTypes.string,
  fullWidth: PropTypes.bool,
  bold: PropTypes.bool,
  id: PropTypes.string,
  newTab: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  resetText: PropTypes.bool,
  type: PropTypes.any,
  noDecoration: PropTypes.bool,
};

Button.defaultProps = {
  label: 'Button',
  variant: variants.filled,
  color: colors.primary,
  darkRipple: false,
  disabled: false,
  fullWidth: false,
};
