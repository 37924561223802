import React from 'react';
import { baseUrl } from '@capcenter/shared';
import '../Realty.scss';
import { getUrlWithUtmQueryString } from '@capcenter/shared-legacy';

class RefinanceSection extends React.Component {

  render() {
    return (
      <section className="section-refinance rtb-flex">
        <div className="rtb-text my-2">
          <h2>
            <i className="fas fa-user-clock"></i> Waiting for home prices to fall?
          </h2>
          <p>
            Rising interest rates put a downward pressure on home prices because higher rates hurt affordability.
          </p>
          <p>
            <strong>However</strong>, we have a long line of pre-approved applicants that have been actively searching
            for the right home. If rates or home prices fall, we may see more bidding wars with the large pool of
            pre-approved buyers reinforced by new buyers that enter the market.
          </p>
          <p>
            With existing home sale inventory at historically low levels with strong demand coupled with high inflation
            affecting rent, it's hard to imagine a dramatic drop in home prices. We think it'll be difficult to time the
            market for this reason.
          </p>
        </div>
        <div className="rtb-text my-2">
          <h2>
            <i className="fas fa-user-chart"></i> Worried about high rates?
          </h2>
          <div>
            Interest rates have been trending up in 2023 along with the federal funds rate.
            <br />
            <br />
            <ul>
              <li>
                <strong>If rates go down</strong> after you purchase your home, refinance your mortgage with CapCenter's
                Zero Closing Cost refinance and save big!
              </li>
              <li>
                <strong>If rates go up</strong> after you purchase your home, congratulations, you locked in a great
                rate. If rates fall in the future, refinance with CapCenter's Zero Closing Cost refinance and save big!
              </li>
            </ul>
          </div>
          <p>
            <strong>We'll automatically alert you when rates drop.</strong> This is the beauty of CapCenter's Zero
            Closing Cost mortgages and a big reason why experienced home buyers love to use CapCenter.
          </p>

          <div>
            <a href={getUrlWithUtmQueryString(`${baseUrl}/guides/refinance-guide`)} className={'mr-4'}>
              <button className="btn-primary mb-2" title="CapCenter's Zero Closing Cost Refinancing">
                Learn more about refinancing
              </button>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default RefinanceSection;
