import React from 'react';

class InputWrap extends React.Component {
	
	render(){
		
		const {label, name} = this.props;
	
		return (
			<div className="form-group bold">
		    <label htmlFor={name} className={"mb-1 bold " + label === undefined ? "alert-danger":""}>{label===undefined ? "Label Missing":label}</label>
				
				{this.props.children}
			</div>
		)
	}
}

export default InputWrap