import React, { useState } from 'react';
import { Colors } from '@capcenter/shared';

import './ServiceAreaStaticImg.css';

const ServiceAreaStaticImg = ({ image }) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <div className="service-area-static-img--wrapper">
      <div className="service-area-static-img--img-block">
        <img
          style={{ opacity: imgLoaded ? 1 : 0 }}
          src={image}
          alt="Our Service Area"
          onLoad={() => setImgLoaded(true)}
        />
      </div>

      <div className="service-area-static-img--legend-block">
        <div className="pr-3">
          <img
            className="d-inline fa-fw mr-1"
            src={'/site-map/svg-icons/property-details.svg'}
            style={{ width: '1.25rem', height: '1.25rem' }}
            alt="o"
          />
          Office Location
        </div>
        <div className="pr-3">
          <i className="fa-fw fas fa-square mr-1" style={{ color: Colors.red500 }} />
          Realty &amp; Mortgage Services
        </div>
        <div>
          <i className="fa-fw fas fa-square mr-1" style={{ color: Colors.primary }} />
          Mortgage Services
        </div>
      </div>
    </div>
  );
};

export default ServiceAreaStaticImg;
