var moment = require('moment');

const IsJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const ArrayBufferToBase64 = (buffer) => {
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));

  bytes.forEach((b) => binary += String.fromCharCode(b));

  return window.btoa(binary);
};

const bytes = (s) => ~-encodeURI(s).split(/%..|./).length
const getJsonSize = (s) => bytes( JSON.stringify(s) )
const formatBytes = (a,b) => {if(0===a)return"0 Bytes";var c=1024,d=b||2,e=["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"],f=Math.floor(Math.log(a)/Math.log(c));return parseFloat((a/Math.pow(c,f)).toFixed(d))+" "+e[f]}

const JsonSize = (num) => {
	return formatBytes(getJsonSize(num))
}

const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

const mergeDeep = (target, source) => {
  let output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target))
          Object.assign(output, { [key]: source[key] });
        else
          output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}

const isThere = (item) =>{
	
	if(item !== undefined && item !== null && item !== "" && item !==false && item.length !== 0){
		return true;
	}else{
		return false;
	}
	
}

const arraymove = (arr, fromIndex, toIndex) => {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}

const newDate = () => {
	return moment().utc().format("MM/DD/YYYY HH:mm:ss")
}

export { IsJsonString, ArrayBufferToBase64, JsonSize, mergeDeep, isThere, arraymove, newDate }
