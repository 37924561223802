import { quoteApiUrlLenderPrice, quoteUrl } from '@capcenter/shared';

export default async function getQuoteRates(queryParameters : string, signal?: any): Promise<any | string> {
  const url: string = quoteApiUrlLenderPrice + 'v2/get-quote-rates';
  try {
    const data = await fetch(url, {
      signal: signal,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: queryParameters
    });
    
    return data.json();
  } catch (err: any) {
    if (err && err.name === 'AbortError') {
      console.log('Fetch request was aborted');
      return undefined;
    } else {
      console.log(err);
      return 'Unexpected Quote Rates Database Error. Please try again later.';
    }
  }
}