import React, { Component } from 'react';
import { Colors } from '@capcenter/shared';
import Button from '../components/Button';
import './RTB.scss';
import { getUrlWithUtmQueryString } from '@capcenter/shared-legacy';

const LinkWrap = ({ link, bigLink, children }) => {
  if (bigLink) {
    return (
      <a href={getUrlWithUtmQueryString(link)} style={{ textDecoration: 'none' }}>
        {children}
      </a>
    );
  } else {
    return <div>{children}</div>;
  }
};

export class ReasonToBelieve extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      rtbTop: false,
    };

    if (this.props.preLoad) {
      this.state.show = true;
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  refCallback = element => {
    if (element) {
      this.setState({ rtbTop: element.getBoundingClientRect() }, this.handleScroll);
    }
  };

  handleScroll = () => {
    const scrollBottom = window.pageYOffset + window.innerHeight;

    //console.log(scrollBottom , this.state.rtbTop.top, scrollBottom > this.state.rtbTop.top)

    if (scrollBottom > this.state.rtbTop.top) {
      this.setState({ show: true });
    }
  };

  rtb = React.createRef();

  render() {
    return (
      <div className="rtb-text-block"
        style={{
          paddingBottom: this.props.hideBottomPadding || this.props.hero ? 0 : 30,
          overflow: 'hidden',
        }}
        ref={this.refCallback}
      >
        {this.props.title && (
          <div>
            <div className="container" style={{ padding: '20px 15px' }}>
              <p className="rtb-title">
                {this.props.title}
              </p>

              <p className="rtb-body">
                {this.props.text.map((text, i) => (
                  <span key={i} className="d-block d-lg-inline" dangerouslySetInnerHTML={{__html: text}}>
                  </span>
                ))}
              </p>

              {(this.props.link || this.props.onClick) && (
                <Button
                  className="btn-lg"
                  color="transparent"
                  textColor={Colors.primary}
                  href={this.props.link}
                  target={this.props.linkTarget}
                  onClick={this.props.onClick}
                  text={this.props.linkText}
                />
              )}

              {this.props.links && (
                <>
                  {this.props.links.map((link, i) => (
                    <div>
                      <Button
                        key={i}
                        className="btn-lg"
                        color="transparent"
                        textColor={Colors.primary}
                        href={link.link}
                        target={link.linkTarget}
                        onClick={link.onClick}
                        text={link.linkText}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        )}

        <LinkWrap link={this.props.link} bigLink={this.props.bigLink}>
          <div
            className="rtb d-flex"
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              overflow: this.props.hero ? 'visible' : 'hidden',
              position: 'relative',
              zIndex: 200,
            }}
          >
            <div style={{ width: '100%', height: '100%', transition: '.5s', opacity: this.state.show ? 1 : 0 }}>
              {this.state.show && this.props.children}
            </div>
          </div>
        </LinkWrap>
      </div>
    );
  }
}

export default ReasonToBelieve;
