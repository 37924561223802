/*global google*/
import React from 'react';
import MatchGoogle from './MatchGoogle';
import { googleMapsKey } from '@capcenter/shared';
import { getStates, getCounties } from '@capcenter/shared';

const initialState = {
  counties: [],
  states: [],
  firstAttempt: false,
};
type State = Readonly<typeof initialState>;

export default class CountyId extends React.Component<{
  fields?: any;
  onChange: Function;
}> {
  readonly state: State = initialState;

  componentDidMount() {
    // Load Google Maps
    if (!document.getElementById('AddressSearchGoogleMaps')) {
      var script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=' + googleMapsKey + '&libraries=places';
      script.id = 'AddressSearchGoogleMaps';
      script.async = true;
      document.head.appendChild(script);
    }

    getCounties().then((counties: any) => {
      getStates().then((states: any) => {
        let firstAttempt = false;

        if (typeof google !== 'undefined') {
          firstAttempt = true;
        }

        this.setState({ counties, states, firstAttempt }, () => {
          if (this.props.fields.PropertyLocation !== '') {
            this.handleSelect(this.props.fields.PropertyLocation);
          }
        });
      });
    });
  }

  componentDidUpdate(prevProps: any) {
    if (this.state.counties.length !== 0 && this.state.states.length !== 0) {
      if (
        prevProps.fields.PropertyLocation !== this.props.fields.PropertyLocation &&
        prevProps.fields.PropertyLocation !== ''
      ) {
        this.handleSelect(this.props.fields.PropertyLocation);
      }

      if (
        this.props.fields.PropertyLocation !== '' &&
        this.state.firstAttempt === false &&
        typeof google !== 'undefined'
      ) {
        this.setState({ firstAttempt: true });
        this.handleSelect(this.props.fields.PropertyLocation);
      }
    }
  }

  handleSelect = (suggestion: string) => {
    if (typeof google !== 'undefined' && google.maps) {
      MatchGoogle(suggestion, this.state.counties, this.state.states).then((countyData: any) => {
        if (typeof countyData !== 'string' && countyData !== undefined) {
          this.props.onChange(
            countyData.realtyActive ? 'Yes' : 'No',
            { inputKey: 'CountyRealty' },
            this.props.onChange(
              countyData.stateId,
              { inputKey: 'StateId' },
              this.props.onChange(
                countyData.region,
                { inputKey: 'region' },
                this.props.onChange(countyData.countyId, { inputKey: 'CountyId' }),
              ),
            ),
          );
        } else {
          console.error(countyData === undefined ? 'Undefined' : countyData);
        }
      });
    }
  };

  render() {
    return <></>;
  }
}
