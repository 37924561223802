import React from 'react';
import Icon from '../NavFooterIcon';
import { baseUrl } from '../../Config';
import './Nav.scss';
import Colors from '../../constants/_colors.scss';
import Weights from '../../constants/_weights.scss';

const AppList = require('../../constants/AppList.json');

interface NavState {
  open: boolean;
  showUserIcon: boolean;
}

export default class Nav extends React.Component<
  { transparent?: boolean; children: React.ReactElement<any> },
  NavState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
      showUserIcon: false,
    };
  }

  toggle = () => {
    if (this.state.open) {
      document.body.classList.remove('hideTooltips');
    } else {
      document.body.classList.add('hideTooltips');
    }
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <div className="navbar--wrap">
        <div className={'nav--pad-child ' + (this.props.transparent ? ' ' : 'nav--pad-child-pad')}>
          {this.props.children}
        </div>

        <nav
          className={
            'navbar ' + (this.props.transparent ? ' ' : 'navbar-dark ') + (this.state.open ? 'nav--open ' : ' ')
          }
        >
          <div className="container">
            <div className="d-flex justify-content-start" style={{ minWidth: 85 }}>
              <button className="navbar-toggler pl-0" type="button" onClick={this.toggle}>
                <i className="fas fa-bars" />
              </button>
            </div>

            <a href={baseUrl} className="navbar-brand">
              CapCenter &nbsp;
              <Icon icon={AppList.capcenter.icon} />
            </a>

            <div className="d-flex justify-content-end" style={{ minWidth: 85 }}>
              <a
                className="btn-sm ml-2"
                role="button"
                id="nav--apply-now"
                href={baseUrl + AppList.applyNow.url}
                style={{
                  color: Colors.white100,
                  backgroundColor: 'transparent',
                  borderWidth: 2,
                  fontWeight: Weights.bold,
                  border: '1px solid ' + Colors.white100,
                }}
              >
                <>
                  Apply <span className="d-none d-sm-inline">Now</span>
                </>
              </a>
            </div>

            <div className="nav--background" onClick={this.toggle} />

            <div className="nav--collapse">
              <ul className="list-group list-group-flush">
                <button onClick={this.toggle} className="list-group-item list-group-item-action text-right">
                  Close &nbsp;&nbsp;
                  <i className="fas fa-times" />
                </button>

                {Object.keys(AppList).map((app: string, i: number) => {
                  if (AppList[app].priority > 0.6) {
                    return (
                      <a
                        key={i}
                        href={(AppList[app].url.startsWith('/') ? baseUrl : '') + AppList[app].url}
                        target={AppList[app].url.startsWith('/') ? '_self' : '_blank'}
                        className={
                          'list-group-item list-group-item-action ' +
                          (AppList[app].priority <= 0.8 ? 'lgi-indent ' : ' ')
                        }
                      >
                        {AppList[app].iconSmall && <Icon icon={AppList[app].iconSmall} />}
                        &nbsp;&nbsp;&nbsp;
                        {AppList[app].title}
                      </a>
                    );
                  } else {
                    return null;
                  }
                })}
              </ul>
              <a href={baseUrl + AppList.siteMap.url} className="list-group-item list-group-item-action text-center">
                View All
              </a>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
