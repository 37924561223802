import React from 'react';
import findProductCombination from './findProductCombination';

export default class CountyId extends React.Component<{
  fields: any;
  onChange: Function;
  inputKey: string;
  disabled: Boolean;
  availability: any;
}> {
  componentDidUpdate(prevProps: any) {
    if (Object.keys(this.props.availability).length !== 0) {
      this.handleSelect();
    }
  }

  handleSelect = () => {
    if (!this.props.disabled) {
      const ProductCombination = findProductCombination(this.props.fields, this.props.availability.ProductCombinations);

      if (ProductCombination.length > 0) {
        const ProductCombinationId = ProductCombination[0].ProductCombinationId.toString();

        if (ProductCombinationId !== this.props.fields.ProductCombinationId) {
          this.props.onChange(ProductCombinationId, this.props);
        }
      }
    }
  };

  render() {
    return <></>;
  }
}
