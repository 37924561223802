import React, { Component } from 'react';
import { Colors, Weights } from '@capcenter/shared';
import './RefiHero.scss';

export class RefiHero extends Component {
  render() {
    return (
      <div className='refi-hero'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='refi-hero-left col-lg'>

             </div>
            <div className='refi-hero-right col-lg refi-hero-image'>

            </div>
          </div>
        </div>
        <div className='container text-overlap'>
          <div className='row'>
            <div className='refi-hero-left col-lg refi-hero-main'>
              <h1>Refinance with a <br /> <span style={{ color: Colors.primary, fontWeight: Weights.bold }}>ZERO closing cost</span> mortgage</h1>
              <h2>Get a great rate and save thousands with CapCenter</h2>

              <div className='row'>
                <div className='col-sm'>
                  <a href='/apply-now/refinance' title="Apply now to refinance"><button className='btn-primary'>Apply now</button></a>
                </div>
                <div className='col-sm'>
                  <a href='/mortgage-calculator/refinance' title="Mortgage calculator that shows estimated savings and any fees"><button>See savings details</button></a>
                </div>
              </div>
            </div>
            <div className='refi-hero-right col-lg'>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RefiHero;