import React from 'react';
import numeral from 'numeral';
import FindHighestLTV from './FindHighestLTV';
import FindLoanLimits from './FindLoanLimits';
import FindLoanAmountType from './FindLoanAmountType';
import { Input, Tooltip } from '@capcenter/shared-legacy';

// TURN THIS ON WHEN DEBUGGING
const isDebug = false;

export default class LoanAmount extends React.Component<{
  active: string;
  onChange: Function;
  fields: any;
  label: string | JSX.Element;
  inputKey: string;
  disabled: Boolean;
  invalid: Boolean;
  availability: any;
  exceptions: any;
}> {
  readonly state = {
    invalidHigh: false,
    invalidLow: false,
    tempValue: '',
    value: '',
    percent: '',
    maxLoanAmount: 0,
    minLoanAmount: 0,
    minPercent: 0,
    minPercentString: '',
    maxPercent: 0,
    maxPercentString: '',
    loanAmountType: '',
    highestLTV: 0,
    showLtvMaxLimitInsteadOfLoanMaxLimit: false
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    const previousCountyId = prevProps.availability?.CountyId;
    const countyId = this.props.availability?.CountyId;

    if (
      Object.keys(prevProps.availability).length === 0 &&
      Object.keys(this.props.availability).length !== 0 &&
      this.props.active !== ''
    ) { // On initial load after API and active value is loaded
      this.changeValue(this.convertDollar(this.props.active));
    } else if (Object.keys(this.props.availability).length !== 0 && this.props.disabled !== true) { // If availability data is loaded and field is not disabled

      // if purchase price changed, down payment changed, county changed, fthb changed, property type changed, loan amount changed, loan program

      if (prevProps.fields.LoanAmount !== this.props.fields.LoanAmount
        || prevProps.fields.PurchasePrice !== this.props.fields.PurchasePrice
        || prevProps.fields.DownPayment !== this.props.fields.DownPayment
        || prevProps.fields.CountyId !== this.props.fields.CountyId
        || prevProps.fields.FirstTimeHomeBuyer !== this.props.fields.FirstTimeHomeBuyer
        || prevProps.fields.PropertyType !== this.props.fields.PropertyType
        || prevProps.fields.LoanProgram !== this.props.fields.LoanProgram
        || prevProps.fields.LoanTerm !== this.props.fields.LoanTerm
        || prevProps.fields.LoanType !== this.props.fields.LoanType
        || prevProps.fields.AnnualIncome !== this.props.fields.AnnualIncome
        || prevProps.fields.PropertyUse !== this.props.fields.PropertyUse
        || prevProps.fields.LoanPurpose !== this.props.fields.LoanPurpose
        || previousCountyId !== countyId) {

        let modified = false;

        if (this.props.fields.LoanPurpose === 'Purchase' && this.props.fields.PurchasePrice !== '' && this.props.fields.DownPayment !== '') {
          // If Purchase and loan amount is blank but can be calculated
          let quoteStateLoanAmount =
            this.convertDollar(this.props.fields.PurchasePrice) - this.convertDollar(this.props.fields.DownPayment);
          let inputFieldLoanAmount =
            this.convertDollar(this.state.tempValue ? this.state.tempValue.toString() : this.state.value.toString());
          let shouldBeZero =
            this.convertDollar(this.props.fields.PurchasePrice)
            - this.convertDollar(this.props.fields.DownPayment)
            - this.convertDollar(this.state.tempValue ? this.state.tempValue.toString() : this.state.value.toString());

          // don't update if error scenario
          // detect value update loop
          // update if mismatch of fields
          if ((shouldBeZero !== 0 || (inputFieldLoanAmount === 0 && quoteStateLoanAmount > 0))
            && (quoteStateLoanAmount !== inputFieldLoanAmount)
            && (inputFieldLoanAmount.toString() !== this.state.tempValue.toString() && inputFieldLoanAmount.toString() !== this.state.value.toString()
              || prevProps.fields.DownPayment !== this.props.fields.DownPayment
            )
          )
          {
            if (quoteStateLoanAmount.toString() !== this.props.fields.LoanAmount) {
              this.changeValue(quoteStateLoanAmount);
              modified = true;
            }
          }
        }

        if (!modified &&
          (this.state.tempValue !== '' || this.state.value !== '')) {
          this.changeValue(this.convertDollar(this.state.tempValue ? this.state.tempValue.toString() : this.state.value.toString())); // update values to reflect any change in parameter

          if (this.props.fields.LoanPurpose === 'Refinance' || this.props.fields.LoanPurpose === 'Cash Out Refinance' ) {
            const updatedLoanAmountValue = this.state.tempValue ? this.state.tempValue : this.state.value;

            let downPayment =
              this.convertDollar(this.props.fields.PurchasePrice) - this.convertDollar(updatedLoanAmountValue.toString());

            this.props.onChange(downPayment.toString(), {inputKey: 'DownPayment'});
          }
        }
      } else if (
        this.props.active !== '' && (this.props.active === this.props.fields.LoanAmount) && (this.state.value === '' && this.state.tempValue === '')
      )
      { // addresses odd behavior only in production where component's state gets cleared
        this.changeValue(this.convertDollar(this.props.active));
      }
    }
  }

  dollarToPercent = (dollar: number) => {
    return ((dollar * 100) / parseInt(this.props.fields.PurchasePrice)).toFixed(2).toString()
  }

  convertDollar = (value: string) => Number(value.replace(/[^0-9-]+/g, ''));

  findMinMaxAmounts = (fields: any) => {
    const { loanMinLimit, loanMaxLimit, showLtvMaxLimitInsteadOfLoanMaxLimit } = FindLoanLimits(
      fields,
      this.props.availability.ProductCombinations,
      this.props.exceptions,
      this.props.availability.LoanAmountLimits,
    );

    const minAmount = loanMinLimit;
    const maxAmount = loanMaxLimit;

    return { minAmount, maxAmount, showLtvMaxLimitInsteadOfLoanMaxLimit };
  };

  onChange = (value: number) => {
    this.changeValue(value);
  };

  changeValue = (value: number) => {
    let loanAmountType = FindLoanAmountType(value.toString(), this.props.availability.LoanAmountLimits);
    let fields = JSON.parse(JSON.stringify(this.props.fields));
    fields.LoanAmount = value;

    const { minAmount, maxAmount, showLtvMaxLimitInsteadOfLoanMaxLimit } = this.findMinMaxAmounts(fields);


    let percent = '%';
    let minPercent : any = '';
    let maxPercent : any = '';

    if (this.props.fields.PurchasePrice) {
      percent = this.dollarToPercent(value) + '%';
      minPercent = (minAmount * 100) / parseInt(this.props.fields.PurchasePrice);
      maxPercent = (maxAmount * 100) / parseInt(this.props.fields.PurchasePrice);
      if (maxPercent > 100) {
        maxPercent = 100;
      }
    }

    const highestLTV = FindHighestLTV(this.props.availability.ProductCombinations, fields, false);

    // Create current state object for comparison
    const currentState = {
      tempValue: this.state.tempValue,
      invalidHigh: this.state.invalidHigh,
      invalidLow: this.state.invalidLow,
      maxLoanAmount: this.state.maxLoanAmount,
      minLoanAmount: this.state.minLoanAmount,
      value: this.state.value,
      percent: this.state.percent,
      minPercent: this.state.minPercent,
      minPercentString: this.state.minPercentString,
      maxPercent: this.state.maxPercent,
      maxPercentString: this.state.maxPercentString,
      loanAmountType: this.state.loanAmountType,
      highestLTV: this.state.highestLTV,
      showLtvMaxLimitInsteadOfLoanMaxLimit: this.state.showLtvMaxLimitInsteadOfLoanMaxLimit
    };

    if (value <= maxAmount && value >= minAmount) { // if value is within valid range
      const newState = {
        tempValue: '',
        invalidHigh: false,
        invalidLow: false,
        value: value.toString(),
        maxLoanAmount: maxAmount,
        minLoanAmount: minAmount,
        percent: percent,
        minPercent: minPercent,
        minPercentString: minPercent ? minPercent.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : '',
        maxPercent: maxPercent,
        maxPercentString: maxPercent ? maxPercent.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : '',
        loanAmountType: loanAmountType,
        highestLTV: highestLTV,
        showLtvMaxLimitInsteadOfLoanMaxLimit: showLtvMaxLimitInsteadOfLoanMaxLimit
      };

      if (JSON.stringify(newState) !== JSON.stringify(currentState)) { // if change occurred, update state & quotestate with value
        this.setState(newState, this.props.onChange(value.toString(), this.props));
        this.changePercent(percent);
      }
    } else {
      if (value > maxAmount) {
        const newState = {
          tempValue: value,
          invalidHigh: true,
          invalidLow: false,
          value: '',
          maxLoanAmount: maxAmount,
          minLoanAmount: minAmount,
          percent: percent,
          minPercent: minPercent,
          minPercentString: minPercent ? minPercent.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : '',
          maxPercent: maxPercent,
          maxPercentString: maxPercent ? maxPercent.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : '',
          loanAmountType: loanAmountType,
          highestLTV: highestLTV,
          showLtvMaxLimitInsteadOfLoanMaxLimit: showLtvMaxLimitInsteadOfLoanMaxLimit
        };
        if (JSON.stringify(newState) !== JSON.stringify(currentState)) { // if change occurred, update state & quotestate with empty string
          this.setState(newState, this.props.onChange('', this.props));
          this.changePercent(percent);
        }
      } else {
        const newState = {
          tempValue: value,
          invalidHigh: false,
          invalidLow: true,
          value: '',
          maxLoanAmount: maxAmount,
          minLoanAmount: minAmount,
          percent: percent,
          minPercent: minPercent,
          minPercentString: minPercent ? minPercent.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : '',
          maxPercent: maxPercent,
          maxPercentString: maxPercent ? maxPercent.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : '',
          loanAmountType: loanAmountType,
          highestLTV: highestLTV,
          showLtvMaxLimitInsteadOfLoanMaxLimit: showLtvMaxLimitInsteadOfLoanMaxLimit
        };
        if (JSON.stringify(newState) !== JSON.stringify(currentState)) { // if change occurred, update state & quotestate with empty string
          this.setState(newState, this.props.onChange('', this.props));
          this.changePercent(percent);
        }
      }
    }
  };

  convertPercent = (value: string) => {
    let valInt;

    valInt = value.replace(/[^\d.-]/g, '');

    return parseFloat(valInt);
  };

  onChangePercent = (value: string) => {
    this.changePercent(value);
    const percent: any = this.convertPercent(value);
    if (!Number.isNaN(percent)) {
      if (value === '') {
        this.changeValue(0);
        this.changePercent('');
      } else {
        const dollarAmount = Math.round((percent / 100) * parseInt(this.props.fields.PurchasePrice));
        this.changeValue(dollarAmount);
      }
    }
  };

  changePercent = (percent: string) => {
    if (percent !== this.state.percent) {
      this.setState({
        percent: percent
      })
    }
  };

  render() {
    if (Object.keys(this.props.availability).length !== 0 && this.props.disabled !== true) {
      return (
        <>
          <div className="form-row">
            <div className="col-12">
              <label className="mb-1 bold" htmlFor={'quote--LoanAmount'}>{
                <>
                  {this.props.label}
                  {isDebug &&
                  ' - ( $' +
                  numeral(this.state.minLoanAmount).format('0a') +
                  ' - $' +
                  numeral(this.state.maxLoanAmount).format('0a') +
                  ' or ' +
                  (this.state.minPercent && this.state.minPercentString.substr(0, 8)) +
                  '% - ' +
                  (this.state.maxPercent && this.state.maxPercentString.substr(0, 8)) +
                  '% ) '}
                </>
              }</label>
            </div>
          </div>
          <div className="form-row">
            <div className="col-8">
              <Tooltip
                html={<div />}
                error={true}
                distance={10}
                errorHtml={
                  <span>
                    {this.state.invalidLow ? (
                      <>
                        Amount is lower than ${numeral(this.state.minLoanAmount).format('0,0')}
                        <br />
                        For lower amounts, Call Us
                      </>
                    ) : (
                      <></>
                    )}

                    {(this.state.invalidHigh && this.state.loanAmountType === 'Jumbo' && this.state.showLtvMaxLimitInsteadOfLoanMaxLimit === true) ?
                      (
                        <>
                          Amount cannot be higher than {this.state.maxPercentString}% of home value
                        </>
                      ) : (this.state.invalidHigh && this.state.loanAmountType === 'Jumbo' && this.state.showLtvMaxLimitInsteadOfLoanMaxLimit === false) ?
                        (
                          <>
                            Amount cannot be higher than ${numeral(this.state.maxLoanAmount).format('0,0')}
                          </>
                        ) : this.state.invalidHigh ?
                          (
                            <>
                              Amount cannot be higher than ${numeral(this.state.maxLoanAmount).format('0,0')} or {this.state.maxPercentString}%
                            </>
                          ) : (
                            <></>
                          )
                    }
                  </span>
                }
                open={this.state.invalidHigh || this.state.invalidLow}
              >
                <div>
                  <Input
                    hasexternallabel
                    id='quote--LoanAmount'
                    disabled={this.props.disabled}
                    value={
                      this.state.tempValue === ''
                        ? this.state.value === ''
                          ? ''
                          : parseInt(this.state.value)
                        : parseInt(this.state.tempValue)
                    }
                    mask="dollar"
                    invalid={this.state.invalidHigh || this.state.invalidLow || this.props.invalid}
                    invalidCheck={this.state.invalidHigh || this.state.invalidLow}
                    onChange={(e: any) => this.onChange(this.convertDollar(e.target.value))}
                  />
                </div>
              </Tooltip>
            </div>
            <div className="col-4">
              <Tooltip
                html={<div />}
                error={true}
                distance={10}
                errorHtml={this.state.invalidLow ? Math.trunc(this.state.minPercent * 100) / 100 + '% Minimum' : ''}
                open={this.state.invalidLow && this.state.highestLTV !== 0}
              >
                <div>
                  <Input
                    id='quote--LoanAmountPercent'
                    hasexternallabel
                    value={ this.state.percent }
                    disabled={this.props.fields.PurchasePrice === ''}
                    mask="percent"
                    invalid={this.state.invalidHigh || this.state.invalidLow || this.props.invalid}
                    invalidCheck={this.state.invalidHigh || this.state.invalidLow}
                    onChange={(e: any) => this.onChangePercent(e.target.value)}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="form-row">
          <div className="col-8">
            <Input mask="dollar" disabled={true} label={this.props.label} value={''} id='quote--LoanAmount' />
          </div>
          <div className="col-4">
            <Input mask="percent" disabled={true} label={<>&nbsp;</>} value={''} id='quote--LoanAmountPercent' />
          </div>
        </div>
      );
    }
  }
}
