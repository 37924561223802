import { Tooltip, getUrlWithUtmQueryString } from '@capcenter/shared-legacy';
import { baseUrl } from '@capcenter/shared';
import React from 'react';
import '../Realty.scss';

class DownPaymentSection extends React.Component {
  render() {
    return (
      <section className="section-down-payment rtb-flex">
        <div className="box-container-wrapper">
          <h3 className="text-center">Low down payment options</h3>
          <div className="box-container">
            <div className="d-flex">
              <div
                className="box-card"
                data-aos="zoom-in"
                data-aos-once="true"
                data-aos-duration="2000"
                data-aos-anchor-placement="bottom-bottom"
              >
                <p className="audience">Veterans</p>
                <div>
                  <span className="number">0%</span>{' '}
                  <Tooltip
                    arrow
                    html={
                      <div>
                        <p>VA loan products for veterans have 0% down payment options.</p>
                      </div>
                    }
                  >
                    <i className="fas fa-info-circle"></i>
                  </Tooltip>
                </div>
              </div>
              <div
                className="box-card"
                data-aos="zoom-in"
                data-aos-once="true"
                data-aos-duration="2000"
                data-aos-anchor-placement="bottom-bottom"
              >
                <p className="audience">First-timers</p>
                <div>
                  <span className="number">3%</span>{' '}
                  <Tooltip
                    arrow
                    html={
                      <div>
                        <p>
                          Conventional loan products have 3% down payment options for first-time home buyers. If you
                          haven't owned a home in the past three years, you may qualify as a first-time home buyer.
                        </p>
                      </div>
                    }
                  >
                    <i className="fas fa-info-circle"></i>
                  </Tooltip>
                </div>
              </div>
              <div
                className="box-card"
                data-aos="zoom-in"
                data-aos-once="true"
                data-aos-duration="2000"
                data-aos-anchor-placement="bottom-bottom"
              >
                <p className="audience">Everyone</p>
                <div>
                  <span className="number">3.5%</span>{' '}
                  <Tooltip
                    arrow
                    html={
                      <div>
                        <p>
                          FHA loan products have 3.5% down payment options. Conventional loan products have 5% down
                          payment options. All loan product offerings are subject to underwriting approval.
                        </p>
                      </div>
                    }
                  >
                    <i className="fas fa-info-circle"></i>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rtb-text">
          <h2>
            <i className="fad fa-chevron-double-down"></i> Low upfront costs
          </h2>
          <p>At CapCenter, we're all about providing low upfront cost options while providing a great rate.</p>

          <div className="mb-3">
            Are you ready to purchase your first home? Make sure you look at closing costs and escrows in addition to
            the down payment requirements.{' '}
            <Tooltip
              arrow
              html={
                <div>
                  <p>Upfront costs may include</p>
                  <ol>
                    <li>Minimum down payment (depends on loan product)</li>
                    <li>Lender fees</li>
                    <li>Non-lender fees</li>
                    <li>Government fees such as taxes</li>
                    <li>Homeowner's insurance premium</li>
                    <li>Mortgage insurance premiums</li>
                    <li>Terms such as negotiated credits and chattel</li>
                  </ol>
                  <p>Our online mortgage calculator shows and helps compare typical rates and fees.</p>
                </div>
              }
            >
              <i className="fas fa-info-circle"></i>
            </Tooltip>
          </div>

          <div>
            <a href={getUrlWithUtmQueryString(`${baseUrl}/mortgage-calculator/purchase`)} className="mr-4">
              <button className="btn-primary" title="See upfront costs on our online mortgage calculator">
                See upfront costs
              </button>
            </a>
            <a href={getUrlWithUtmQueryString(`${baseUrl}/mortgage-products`)} className="mr-4">
              <button className="btn-secondary" title="Learn more about our wide selection of mortgage products">
                See loan options
              </button>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default DownPaymentSection;
