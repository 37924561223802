import { LoginTimeoutModal } from '../LoginTimeoutModal/LoginTimeoutModal';
import React, {useState} from 'react';
import ChatWrapper from '../ChatWrapper';
import AppInt from '../../constants/App.interface';
import AppHelmet from '../AppHelmet';
import AppHeader from '../AppHeader';
import Banner from '../Banner';
import MegaMenu from '../MegaMenu';
import Footer from '../Footer';
import './AppWrapper.scss'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { readLocalFirebaseConfig } from '@capcenter/firebase-service';
import { GoogleLoginModal } from '../GoogleLoginModal/GoogleLoginModal';

const AppWrapper: React.SFC<{
  children: React.ReactElement<any>;
  navHidden?: boolean;
  app?: string;
  appItem?: AppInt;
  industry?: string;
  height100?: boolean;
  noFooter?: boolean;
  noHelmet?: boolean;
  noHeader?: boolean;
  retryLoginCheck?: boolean;
}> = props => {
  const [showBanner, setShowBanner] = useState(false);
  const height100Class = props.height100 ? 'h-100 ' : '';
  const showBannerClass = showBanner ? 'show-banner ' : '';
  const noFooterClass = !props.noFooter ? 'cc--content-wrap-with-footer' : ''
  const isNotIframe: boolean = (window.self === window.top);

  let clearBuyDownBannerDataInLocalStorage = function () {
    if (window.localStorage) {
      window.localStorage.removeItem("hideBuyDownBanner");
      window.localStorage.removeItem("hideBuyDownBannerDate");
    }
  }

  if (showBanner) {
    let isExclusion = false;

    if (window.location.pathname) {
      const exclusions = [
        "/application",
        "/account",
        "refinance",
        "Refinance"
      ];

      for (let exclusion of exclusions) {
        if (window.location.pathname.indexOf(exclusion) !== -1) {
          setShowBanner(false);
          isExclusion = true;
          break;
        }
      }
    }

    if (window.localStorage && !isExclusion) {
      if (window.localStorage.getItem("hideBuyDownBanner") === "true") {
        let clearBuyDownBannerData = true;
        let hideBuyDownBannerDate = window.localStorage.getItem("hideBuyDownBannerDate");
        let hideBuyDownBannerParsedDate = hideBuyDownBannerDate ? Date.parse(hideBuyDownBannerDate) : null;

        if (hideBuyDownBannerParsedDate) {
          let now = new Date();
          let isInclusion = false;

          if (window.location.pathname) {
            const inclusions = [
              "/",
              "/buy-a-home",
              "/sell-a-home",
              "/mortgage-rates",
              "/mortgage-rates/",
              "/mortgage-calculator/purchase",
              "/mortgage-calculator/purchase/",
              "/apply-now/",
              "/apply-now/purchase"
            ];

            for (let inclusion of inclusions) {
              if (window.location.pathname === inclusion) {
                isInclusion = true;
              }
            }
          }

          if (isInclusion) {
            const fifteenMinutesInMilliseconds = 900000; //1000 * 60 * 15;
            if ((now.getTime() - hideBuyDownBannerParsedDate) < fifteenMinutesInMilliseconds) {
              setShowBanner(false);
            }
            clearBuyDownBannerData = false;
          } else {
            const fourHoursInMilliseconds = 14400000; //1000 * 60 * 60 * 4;
            if ((now.getTime() - hideBuyDownBannerParsedDate) < fourHoursInMilliseconds) {
              setShowBanner(false);
              clearBuyDownBannerData = false;
            }
          }
        }

        if (clearBuyDownBannerData) {
          clearBuyDownBannerDataInLocalStorage();
        }
      }
    }
  }

  let closeBanner = function () {
    if (window.localStorage) {
      window.localStorage.setItem("hideBuyDownBanner", "true");
      window.localStorage.setItem("hideBuyDownBannerDate", new Date().toISOString());
    }
    setShowBanner(false);
  }

  setTimeout(function () {
    if (showBanner) {
      setShowBanner(false);
    }
  }, 30000);

  return (
    <ChatWrapper>
      <GoogleOAuthProvider clientId={readLocalFirebaseConfig(window.location.hostname).clientId}>
        <>
        <GoogleLoginModal/>
        <LoginTimeoutModal retryLoginCheck={props.retryLoginCheck}/>
                {isNotIframe && !props.navHidden && <MegaMenu showBanner={showBanner} app={props.app} appItem={props.appItem} industry={props.industry} />}

                <Banner showBanner={showBanner} closeBanner={closeBanner} />

                <div
                  id='app-wrapper-content-wrapper'
                  className={ `cc--content-wrap ${height100Class} ${showBannerClass} ${noFooterClass}`}
                >
                  <>
                    {!props.noHelmet && <AppHelmet app={props.app} appItem={props.appItem} />}
                    {!props.noHeader && <AppHeader app={props.app} appItem={props.appItem} />}
                    {props.children}
                  </>
                </div>
                {isNotIframe && !props.noFooter  && <Footer {...props} />}
        </>
      </GoogleOAuthProvider>
    </ChatWrapper>
  );
};

export default AppWrapper;
