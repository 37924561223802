import React, { Component } from 'react';
import { Colors, Weights } from '@capcenter/shared';
import { getUrlWithUtmQueryString } from '@capcenter/shared-legacy';
import './HomeHero.scss';

export class HomeHero extends Component {
  render() {
    return (
      <div className='home-hero'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='home-hero-left col-lg'>

             </div>
            <div className='home-hero-right col-lg home-hero-image'>

            </div>
          </div>
        </div>
        <div className='container text-overlap'>
          <div className='row'>
          <div className='home-hero-left col-lg home-hero-main'>
              <h1>Buy or Refinance with a <br /> <span style={{ color: Colors.primary, fontWeight: Weights.bold }}>ZERO closing cost</span> mortgage</h1>
              <h2 style={{ marginBottom: 20}}>Get a great rate and save thousands with CapCenter</h2>

              <div className='button-group'>
                <a href='/apply-now/purchase' title="Fill out our quick and easy online application to get started"><button className='btn-primary'>Apply for purchase</button></a>
                <a href='/apply-now/refinance' title="Fill out our quick and easy online application to get started"><button className='btn-primary'>Apply for refinance</button></a>
                <a href='/mortgage-calculator/purchase' title="Mortgage calculator that shows estimated savings and any fees"><button>Buy savings details</button></a>
                <a href='/mortgage-calculator/refinance' title="Refinance calculator that shows estimated savings and any fees"><button>Refi savings details</button></a>
              </div>
            </div>
            <div className='home-hero-right col-lg'>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeHero;