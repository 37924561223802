const mapQuoteWidgetFromFields = (fields : any) => {

  const NeedRealtyTeam = fields.NeedRealtyTeam ? fields.NeedRealtyTeam : (
    !fields.savings ? "" : (fields.savings === "Realty" ? "Yes" : "No"));

  const savings = fields.savings ? fields.savings : (
    NeedRealtyTeam === "" ? "" : (NeedRealtyTeam === "Yes" ? "Realty" : "Mortgage"));

  const quoteWidget = {
    price: fields.PurchasePrice,
    downDollar: fields.DownPayment,
    loanDollar: fields.LoanAmount,
    FirstTimeHomeBuyer: fields.FirstTimeHomeBuyer,
    existingLoanBalance: fields.ExistingLoanBalance,
    originalLoanAmount: fields.OriginalLoanAmount,
    purchasedWithinLastYear: fields.PurchasedWithinLastYear,
    rate: fields.rateKey,
    LoanProgram: fields.LoanProgram,
    LoanPurpose: fields.LoanPurpose,
    LoanTerm: fields.LoanTerm,
    LoanType: fields.LoanType,
    PropertyType: fields.PropertyType,
    PropertyUse: fields.PropertyUse,
    address: fields.PropertyLocation,
    PropertyLocation: fields.PropertyLocation,
    CountyId: fields.CountyId,
    CountyRealty: fields.CountyRealty,
    NeedRealtyTeam: NeedRealtyTeam,
    savings: savings,
    BaseRate: fields.BaseRate,
    Points: fields.Points,
    ProductCombinationId: fields.ProductCombinationId,
    StateId: fields.StateId,
    region: fields.region,
    LoanAmountType: fields.LoanAmountType,
    refiOff: fields.refiOff,
    isCashOutRefinanceDisabled: fields.isCashOutRefinanceDisabled,
    HomeownersInsuranceEscrow: fields.HomeownersInsuranceEscrow,
    PropertyTax: fields.PropertyTax,
    AnnualIncome: fields.AnnualIncome,
    ZipCode: fields.ZipCode,
    State: fields.State,
  };

  return quoteWidget;

}

export default mapQuoteWidgetFromFields;
