const getUtmCookieAsUrlQueryString = () => {
  let returnVal = "";

  const cookiesArray = document.cookie.split(';');  // cookies are separated by ';' or '; ' so trim the leading space
  const utmCookieString = cookiesArray.find(cookieString => cookieString.trim().startsWith('ccUtmData='));
  if(!utmCookieString) return returnVal;

  const utmSplit = utmCookieString.split('=');
  const utmCookie = utmSplit[1];

  try {
    const cookieData = JSON.parse(utmCookie);


    returnVal = '?';
    returnVal += 'utm_source=' + cookieData.utm_source;
    returnVal += '&utm_medium=' + cookieData.utm_medium;
    returnVal += '&utm_campaign=' + cookieData.utm_campaign;
    returnVal += '&utm_content=' + cookieData.utm_content;
    returnVal += '&utm_term=' + cookieData.utm_term;
    returnVal += '&gclid=' + cookieData.utm_gclid;
    returnVal += '&msclkid=' + cookieData.msclkid;
    returnVal += '&fbclid=' + cookieData.fbclid;

  } catch(e) {}

  return returnVal;
}

export default getUtmCookieAsUrlQueryString;
