import React from 'react';
import moment from 'moment';
import Icon from '../NavFooterIcon';
import './Footer.scss';
import { baseUrl, baseRealEstateUrl, baseRealtyUrl, baseInsuranceUrl, getUrlWithUtmQueryString } from '@capcenter/shared';
import AppInt from "../../constants/App.interface";

const { useEffect, useState } = React;
const AppList = require('../../constants/AppList.json');

function Footer(props: { app?: string; appItem?: AppInt; light?: boolean }) {

  const [showLocations, setShowLocations] = useState<boolean>(false);

  let item = props.appItem ? props.appItem : props.app ? AppList[props.app] : AppList["capcenter"];

  return (
    <>
    <nav className={props.light ? "cc-text-nav light" : "cc-text-nav"}>
      <div className="container">
        <div className="row cc--text-nav mb-3">
          <div className="col-12 mb-1">
            <div className="brand mb-1">
              <svg className="h-5" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="#EA701B" fillRule="evenodd" clipRule="evenodd"
                      d="M13.564 17.82H11.153V14.981L21.825 8.716V0L6 9.086V32.984H21.825V27.068H13.564V17.82Z"></path>
                <path fill="#EA701B" fillRule="evenodd" clipRule="evenodd"
                      d="M25.104 0V8.716L35.775 14.981V17.82H33.362V27.068H25.104V32.984H40.926V9.086L25.104 0Z"></path>
              </svg>
              CapCenter
            </div>
            <h3>Capping closing costs</h3>
            <div className="subtitle-sm d-none d-sm-inline-block">since 1997</div>
            <div className="subtitle-xs d-block d-sm-none">Since 1997</div>
          </div>
        </div>
        <div className="row cc--text-nav mb-4">
          <div className={'col-12 col-sm-5 col-lg-4 col-xl-3'}>
            <a href={'tel:18009685844'} className={'contact-link'}>
              <i className="fas fa-mobile-alt"></i> +1 (800) 968-5844
            </a>
          </div>
          <div className={'col-12 col-sm-7'}>
            <a href={'mailto:customerservice@capcenter.com'} className={'contact-link'}>
              <i className="fas fa-envelope"></i> customerservice@capcenter.com
            </a>
          </div>
        </div>
        <div className="row cc--text-nav mb-3">
          <div className="col-6 col-sm-3 mb-3 mt-2">
            <h4><a href={getUrlWithUtmQueryString(`${baseRealtyUrl}/best-realtor-agency-buy-home`)}>Buy a home</a></h4>
            <a href={getUrlWithUtmQueryString(`${baseRealtyUrl}/forms/request-buyers-agent`)}>Find a realtor</a><br />
            <a href={getUrlWithUtmQueryString(`${baseRealtyUrl}/get-preapproved-for-a-home-loan`)}>Get pre-approved</a><br />
            <a href={getUrlWithUtmQueryString(`${baseUrl}/mortgage-calculator/purchase`)}>Free quote</a><br />
            <a href={getUrlWithUtmQueryString(`${baseUrl}/mortgage-rates/virginia/richmond/purchase/all`)}>Today's mortgage rates</a><br />
            <a href={getUrlWithUtmQueryString(`${baseUrl}/forms/rate-alerts`)}>Rate alerts</a><br />
            <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/`)}>Homes for sale</a><br />
            <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/richmond-va/filters/propertyType=CapCenterRealty`)}>CapCenter homes</a><br />
            <a href={getUrlWithUtmQueryString(`${baseRealtyUrl}/guides/home-buyers-guide`)}>How to buy a home</a><br />
          </div>
          <div className="col-6 col-sm-3 mb-3 mt-2">
            <h4><a href={getUrlWithUtmQueryString(`${baseRealtyUrl}/best-realtor-agency-sell-home`)}>Sell your home</a></h4>
            <a href={getUrlWithUtmQueryString(`${baseRealtyUrl}/forms/request-listing-agent`)}>Find a realtor</a><br />
            <a href={getUrlWithUtmQueryString(`${baseRealtyUrl}/home-sale-estimate/valuation`)}>Home valuation</a><br />
            <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/richmond-va/filters/propertyType=CapCenterRealty`)}>CapCenter homes</a><br />
            <a href={getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/location/city/richmond-va/filters/propertyType=RecentlySold`)}>Recently sold homes</a><br />
            <a href={getUrlWithUtmQueryString(`${baseRealtyUrl}/home-sale-estimate/valuation`)}>Sale calculator</a><br />
            <a href='https://www.zillow.com/profile/CapCenterLLC' target="_blank" rel="noopener noreferrer">Reviews</a><br />
            <a href={getUrlWithUtmQueryString(`${baseRealtyUrl}/guides/home-sellers-guide`)}>How to sell your home</a><br />
          </div>
          <div className="col-6 col-sm-3 mb-3 mt-2">
            <h4><a href={getUrlWithUtmQueryString(`${baseUrl}/refinance`)}>Refinance</a></h4>
            <a href={getUrlWithUtmQueryString(`${baseUrl}/apply-now/refinance`)}>Apply now</a><br />
            <a href={getUrlWithUtmQueryString(`${baseUrl}/mortgage-rates/virginia/richmond/refinance/all`)}>Today's refinance rates</a><br />
            <a href={getUrlWithUtmQueryString(`${baseUrl}/mortgage-calculator/refinance`)}>Free quote</a><br />
            <a href={getUrlWithUtmQueryString(`${baseUrl}/forms/rate-alerts`)}>Rate alerts</a><br />
            <a href={getUrlWithUtmQueryString(`${baseUrl}/guides/refinance-guide`)}>How to refinance a mortgage</a><br />
          </div>
          <div className="col-6 col-sm-3 mb-3 mt-2">
            <h4><a href={getUrlWithUtmQueryString(`${baseInsuranceUrl}/insurance-quote/savings`)}>Insure</a></h4>
            <a href={getUrlWithUtmQueryString(`${baseInsuranceUrl}/insurance-quote/savings`)}>Insurance savings</a><br />
            <a href={getUrlWithUtmQueryString(`${baseInsuranceUrl}/insurance-quote/buy`)}>Free quote</a><br />
            <a href="https://clientportal.vertafore.com/Login/3206701-1" target='_blank' rel="noopener noreferrer">Insurance portal</a><br />
          </div>
        </div>

        <hr />

        <div className="row cc--text-nav mt-3">

          <div className="d-none d-sm-block col-2">
          </div>

          <div className="col-4 col-sm-3 mb-3">
            <h4><a href={getUrlWithUtmQueryString(`${baseUrl}/content/more/about_us`)}>CapCenter</a></h4>
            <a href="https://faq.capcenter.com/article/14-is-there-a-catch" target="_blank" rel="noopener noreferrer">Is there a catch?</a><br />
            <a href='/service-area/'>Service areas</a><br/>
            <a href="/contact/">Contact us</a><br />
            <a href='https://faq.capcenter.com/article/79-where-can-i-find-client-reviews-of-capcenter' target="_blank" rel="noopener noreferrer">Reviews</a><br />
            <a href="https://faq.capcenter.com" target="_blank" rel="noopener noreferrer">FAQ</a><br />
          </div>

          <div className="col-4 col-sm-3 mb-3">
            <h4><a href='https://workforcenow.adp.com/jobs/apply/posting.html?client=capcenter&amp;ccId=19000101_000001&amp;type=MP&amp;lang=en_US' target="_blank" rel="noopener noreferrer">Join us!</a></h4>

            <a href='https://workforcenow.adp.com/jobs/apply/posting.html?client=capcenter&amp;ccId=19000101_000001&amp;type=MP&amp;lang=en_US' target="_blank" rel="noopener noreferrer">Careers</a><br />
            <a href={getUrlWithUtmQueryString(`${baseUrl}/partner-programs/`)} target="_blank" rel="noopener noreferrer">Partners</a><br />

            <div className={'d-none'}>
              CapCenter provides home loan lending, REALTOR®, and/or insurance services in&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage best">VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-washington-dc`} title="mortgage best">DC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-maryland`} title="mortgage best">MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">GA</a>,&nbsp;
              and
              &nbsp;<a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">FL</a>,&nbsp;
              serving&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-richmond-va`} title="mortgage best">Richmond, VA (RVA)</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-glen-allen-va`} title="mortgage best">Glen Allen, VA</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-glen-allen-va`} title="realtor rva best">Innsbrook, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-glen-allen-va`} title="mortgage best">Henrico county, VA</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-richmond-va`} title="realtor rva best">Wyndham</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-richmond-va`} title="realtor rva best">Windsor</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-richmond-va`} title="realtor rva best">The Fan</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-richmond-va`} title="realtor rva best">Carytown</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-richmond-va`} title="realtor rva best">Scott's Addition</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia-beach-va`} title="mortgage best">Hampton Roads, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">McLean, VA</a>, &nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-mclean-va`} title="realtor nova best">Great Falls, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Fairfax, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Oakton, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Clifton, VA</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-mclean-va`} title="realtor nova best">Centreville, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Chantilly, VA</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-mclean-va`} title="mortgage best">Arlington, VA</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-mclean-va`} title="mortgage best">Sterling, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Leesburg, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Haymarket, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Warrenton, VA</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-mclean-va`} title="realtor nova best">Fairfax County, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Loudon County, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Woodbridge, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Manassas, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Alexandria, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Springfield, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-richmond-va`} title="mortgage best">Midlothian, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-richmond-va`} title="mortgage best">Chester, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-richmond-va`} title="mortgage best">Chesterfield, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-richmond-va`} title="mortgage best">Mechanicsville, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-richmond-va`} title="mortgage best">Goochland, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage best">Charlottesville, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage best">Fredericksburg, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage best">Stafford, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage best">Bumpass, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage best">Mineral, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage best">Louisa, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage best">Williamsburg, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage best">Staunton, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage best">Blacksburg, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage best">Roanoke, VA,</a>&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage best">Jamestown, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage best">Lynchburg, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia-beach-va`} title="mortgage best">Norfolk, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia-beach-va`} title="mortgage best">Virginia Beach, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia-beach-va`} title="mortgage best">Yorktown, VA,</a>&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia-beach-va`} title="mortgage best">Chesapeake, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia-beach-va`} title="mortgage best">Newport News, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia-beach-va`} title="mortgage best">Hampton, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia-beach-va`} title="mortgage best">Suffolk, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-virginia-beach-va`} title="mortgage best">Portsmouth, VA</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-mclean-va`} title="mortgage best">Ashburn, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Aldie, VA</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-mclean-va`} title="mortgage best">Reston, VA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">Northern Virginia</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-mclean-va`} title="mortgage best">NoVA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Glen Burnie, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Severna Park, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Arnold, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Edgewater, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Pasadena, MD</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-columbia-md`} title="mortgage best">Laurel, MD</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-columbia-md`} title="mortgage best">Baltimore, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Bethesda, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Chevy Chase, MD</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-columbia-md`} title="mortgage best">Potomac, MD</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-columbia-md`} title="mortgage best">Annapolis, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Ocean City, MD,</a>&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Frederick, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Silver Spring, MD</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-columbia-md`} title="mortgage best">Rockville, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Towson, MD</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-columbia-md`} title="mortgage best">Gaithersburg, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Clarksburg, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Ellicott City, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Owings Mill, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Oxon Hill, MD</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-columbia-md`} title="mortgage best">Hagerstown, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Hyattsville, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Eldersburg, MD</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-columbia-md`} title="mortgage best">Timonium, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Salisbury, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Bowie, MD</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-columbia-md`} title="mortgage best">College Park, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Bel Air, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Glen Burnie, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Elkton, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Maryland City, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Upper Marlboro, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Chestertown, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Crofton, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Capitol Heights, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Temple Hills, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-columbia-md`} title="mortgage best">Randallstown, MD</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-washington-dc`} title="mortgage best">Washington, DC</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-washington-dc`} title="mortgage best">Georgetown, DC</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-washington-dc`} title="mortgage best">Adams Morgan, DC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-washington-dc`} title="mortgage best">Anacostia, DC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-washington-dc`} title="mortgage best">Brookland, DC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-washington-dc`} title="mortgage best">Capitol Hill, DC</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-washington-dc`} title="mortgage best">Columbia Heights, DC</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-washington-dc`} title="mortgage best">Dupont Circle, DC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-washington-dc`} title="mortgage best">Foggy Bottom, DC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-washington-dc`} title="mortgage best">Ivy City, DC</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-washington-dc`} title="mortgage best">Logan Circle, DC</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-washington-dc`} title="mortgage best">NoMa, DC</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-washington-dc`} title="mortgage best">Shaw, DC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-washington-dc`} title="mortgage best">Woodley Park, DC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-washington-dc`} title="mortgage best">Northwest DC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-washington-dc`} title="mortgage best">Glover Park, DC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-washington-dc`} title="mortgage best">Cleveland Park, DC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-washington-dc`} title="mortgage best">DMV</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-cary-nc`} title="mortgage best">Durham, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-greensboro-nc`} title="mortgage best">Greensboro, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-charlotte-nc`} title="mortgage best">Charlotte, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Greenville, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Wilmington, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Asheville, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Fayetteville, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Rocky Mount, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Boone, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Winston-Salem, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Chapel Hill, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Millbrook, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Garner, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Morrisville, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Wilsonville, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-cary-nc`} title="mortgage best">Cary, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Carolina Beach, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Concord, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Gastonia, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">High Point, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Mooresville, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">New Bern, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Statesville, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Lumberton, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Asheboro, NC</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-cary-nc`} title="mortgage best">Raleigh, NC</a>,
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Morrisville, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Morganton, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Pinehurst, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Oak Island, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Mebane, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage best">Wilkesboro, NC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-greenville-sc`} title="mortgage best">Greenville, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-charleston-sc`} title="mortgage best">Charleston, SC</a>,&nbsp;
              <a href={`${baseRealtyUrl}/contact/best-realty-columbia-sc`} title="mortgage best">Columbia, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Myrtle Beach, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Hilton Head Island, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Florence, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Clemson, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Rock Hill, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Sumter, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Beaufort, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Aiken, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Orangeburg, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Easley, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Lancaster, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Georgetown, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Pawleys Island, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Spartanburg, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Simpsonville, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Summerville, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Fort Mill, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Lexington, SC</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage best">Irmo, SC</a>,&nbsp;

              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Atlanta, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Savannah, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Augusta, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Athens, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Macon, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Helen, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Valdosta, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Marietta, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Alpharetta, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Rome, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Lawrenceville, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">McDonough, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Newnan, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Warner Robins, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Tybee Island, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Decatur, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Cumming, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Douglasville, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Stone Mountain, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Kennesaw, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Norcross, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Cartersville, GA</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage best">Statesboro, GA</a>,&nbsp;

              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Miami, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Orlando, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Tampa, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Tallahassee, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Jacksonville, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Naples, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Fort Myers, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Florida City, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Fort Lauderdale, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Sarasota, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Destin, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">St. Petersburg, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Pensacola, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">St. Augustine, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Gainesville, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Cape Coral, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">West Palm Beach FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Clearwater, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Marco Island, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Boca Raton, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Miami Beach, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Daytona Beach, FL</a>,&nbsp;
              <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage best">Kissimmee, FL</a>&nbsp;
              markets. Learn about &nbsp;
              <a href="https://zeroclosingcost.com/" title="Zero Closing Cost" target="_blank" rel="noopener noreferrer">Zero Closing Cost</a>,&nbsp;
              <a href="https://zeroclosingcostmortgage.com/" title="Zero Closing Cost Mortgage" target="_blank" rel="noopener noreferrer">Zero Closing Cost Mortgage</a>,&nbsp;
              <a href="https://zeroclosingcostsmortgage.com/" title="Zero Closing Costs Mortgage" target="_blank" rel="noopener noreferrer">Zero Closing Costs Mortgage</a>,&nbsp;
              <a href="https://noclosingcosts.net/" title="No Closing Costs" target="_blank" rel="noopener noreferrer">No Closing Costs</a>,&nbsp;
              <a href="https://noclosingcostrealty.com/" title="No Closing Cost Realty" target="_blank" rel="noopener noreferrer">No Closing Cost Realty</a>,&nbsp;
              <a href="https://noclosingcostsrealty.com/" title="No Closing Costs Realty" target="_blank" rel="noopener noreferrer">No Closing Costs Realty</a>,&nbsp;
              <a href="https://zeroclosingcostrealty.com/" title="Zero Closing Cost Realty" target="_blank" rel="noopener noreferrer">Zero Closing Cost Realty</a>,&nbsp;
              <a href="https://zeroclosingcostsrealty.com/" title="Zero Closing Costs Realty" target="_blank" rel="noopener noreferrer">Zero Closing Costs Realty</a>,&nbsp;
              <a href="https://zeroclosingcosthomes.com/" title="Zero Closing Cost Homes For Sale" target="_blank" rel="noopener noreferrer">Zero Closing Cost Homes For Sale</a>,&nbsp;
              <a href="https://zero-closing-costs.com/" title="Zero Closing Costs" target="_blank" rel="noopener noreferrer">Zero Closing Costs</a>,&nbsp;
              <a href="https://bestmortgagevirginia.com/" title="Best Mortgage Virginia" target="_blank" rel="noopener noreferrer">Best Mortgage Virginia</a>.
            </div>
          </div>

          <div className="col-4 col-sm-3 mb-3">
            <h4><a href={getUrlWithUtmQueryString(`${baseUrl}/legal`)}>Legal</a></h4>
            <a href={getUrlWithUtmQueryString(`${baseUrl}/legal/terms-of-use`)} target="_blank" rel="noopener noreferrer">Terms of Use</a><br />
            <a href={getUrlWithUtmQueryString(`${baseUrl}/legal/privacy`)} target="_blank" rel="noopener noreferrer">Privacy Policy</a><br />
            <a href={getUrlWithUtmQueryString(`${baseUrl}/legal/product-offer-details`)} target="_blank" rel="noopener noreferrer">Product Offer Details</a><br />
            <a href={getUrlWithUtmQueryString(`${baseUrl}/legal/licenses-and-disclosures`)} target="_blank" rel="noopener noreferrer">Licenses & Disclosures</a><br />
            <a href={getUrlWithUtmQueryString(`${baseUrl}/legal/process-and-terms`)} target="_blank" rel="noopener noreferrer">Process & Terms</a><br />
          </div>
          <div className="d-none d-sm-block col-2">
          </div>
        </div>
      </div>
    </nav>
    <footer className={props.light ? "footer light" : "footer"}>
      <div className="container">
        <div className="row">
          <div className="col-12 footer--social">
            <a className="footer--icon" href="https://www.facebook.com/CapCenterLLC/" target="_blank" title="Follow CapCenter on Facebook" rel="noopener noreferrer">
              <i className="fab fa-facebook-square" aria-label="Facebook Icon"/>
            </a>
            <a className="footer--icon" href="https://www.linkedin.com/company/capcenter" target="_blank" title="Follow CapCenter on LinkedIn" rel="noopener noreferrer">
              <i className="fab fa-linkedin"  aria-label="LinkedIn Icon"/>
            </a>
            <a className="footer--icon" href="https://twitter.com/capcenterllc" target="_blank" title="Follow CapCenter on Twitter" rel="noopener noreferrer">
              <i className="fab fa-twitter-square"  aria-label="Twitter Icon"/>
            </a>
            <a className="footer--icon" href="https://www.instagram.com/capcenter" target="_blank" title="Follow CapCenter on Instagram" rel="noopener noreferrer">
              <i className="fab fa-instagram-square" aria-label="Instagram Icon"/>
            </a>
            <a className="footer--icon" href="https://www.youtube.com/channel/UCoFRaNzAh-9NkVRxe2eTNwA" target="_blank" title="Follow CapCenter on YouTube" rel="noopener noreferrer">
              <i className="fab fa-youtube-square" aria-label="YouTube Icon"/>
            </a>
          </div>
          <div className="col-12 footer--cred">
            <span className="footer--icon">
              <Icon icon="eho" alt="Equal Housing Opportunity Icon" aria-label="Equal Housing Opportunity Icon" />
            </span>
            <span className="footer--icon">
              <Icon icon="bbb" alt="Better Business Bureau A+ Icon" aria-label="Better Business Bureau A+ Icon" />
            </span>
          </div>
        </div>

        <div className="footer--legal">
          <small>
            {item.title} - {item.metaDescription}
            <br />
            <br />
            Capital Center, L.L.C.<br />
            Licensed mortgage lender in &nbsp;
            <a href={`${baseUrl}/contact/best-mortgage-virginia`} title="mortgage va best">Virginia</a>,&nbsp;
            <a href={`${baseUrl}/contact/best-mortgage-north-carolina`} title="mortgage nc best">North Carolina</a>,&nbsp;
            <a href={`${baseUrl}/contact/best-mortgage-south-carolina`} title="mortgage sc best">South Carolina</a>,&nbsp;
            <a href={`${baseUrl}/contact/best-mortgage-maryland`} title="mortgage md best">Maryland</a>,&nbsp;
            <a href={`${baseUrl}/contact/best-mortgage-georgia`} title="mortgage ga best">Georgia</a>,&nbsp;
            <a href={`${baseUrl}/contact/best-mortgage-florida`} title="mortgage fl best">Florida</a>,&nbsp;
            and the &nbsp;
            <a href={`${baseUrl}/contact/best-mortgage-washington-dc`} title="mortgage dc best">District of Columbia</a>,&nbsp;
            NMLS ID#67717 (www.nmlsconsumeraccess.org) and a licensed real estate broker in &nbsp;
            <a href={`${baseRealtyUrl}/contact/best-realty-mclean-va`} title="realtor va best">Virginia</a>,&nbsp;
            <a href={`${baseRealtyUrl}/contact/best-realty-cary-nc`} title="realtor nc best">North Carolina</a>,&nbsp;
            <a href={`${baseRealtyUrl}/contact/best-realty-charleston-sc`} title="realtor sc best">South Carolina</a>,&nbsp;
            <a href={`${baseRealtyUrl}/contact/best-realty-columbia-maryland`} title="realtor md best">Maryland</a>, and the&nbsp;
            <a href={`${baseRealtyUrl}/contact/best-realty-washington-dc`} title="realtor dc best">District of Columbia</a>,&nbsp;
            Our primary office is located in&nbsp;
            <a href={`${baseUrl}/contact/best-mortgage-glen-allen-va`} title="mortgage glen allen best">Glen Allen, Virginia</a>&nbsp;
            near&nbsp;
            <a href={`${baseUrl}/contact/best-mortgage-richmond-va`} title="mortgage rva best">Richmond, Virginia</a>.
            <br />
            <br />
            Copyright ©{moment().format('YYYY')} Capital Center, L.L.C. dba CapCenter
          </small>
        </div>
      </div>
    </footer>
    </>
  );
}

export default Footer;
