import React, {Component} from 'react'

export class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
	    imgLoad: false,
	  }  
	  
	  if(this.props.preLoad){ this.state.imgLoad = true }
	} 
	
  render() {

    return (
			<img style={{height: "100%", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", transition: ".5s", opacity: (this.state.imgLoad ? 1 : 0)}} src={this.props.image} alt={this.props.alt ?? "Best realtor and Best home mortgage lender image"} onLoad={()=>this.setState({imgLoad:true})} />
    );
  }
}

export default Image