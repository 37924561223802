import React from 'react';
import './NavFooterIcon.scss';
const Icons = require('../../constants/NavFooterIcons.json');

function Icon(props: { icon: any, alt?: string, color?: string}) {
  let icon: any = '';

  Object.keys(Icons).map((key: string, i: number) => {
    if (props.icon === key) {
      icon = Icons[key];
    }
    return null;
  });

  return (
    <svg className="d-inline-block svg--icon" viewBox="0 0 32 32" aria-label={props.alt}>
      <path fill={props.color !== undefined ? props.color :  "currentColor"} d={icon}/>
    </svg>
  );
}

export default Icon;
