import React from 'react';
import BtnGroup from '../BtnGroup';

class InputBtnGroup extends React.Component {
	
	onClick = (btnProps) => {
		
		this.props.onClick(btnProps, this.props)
		
	}
	
	render(){
		
		return (
			<div className="form-group">
		    <label htmlFor={this.props.name} className={"mb-1 " + this.props.label === undefined ? "alert-danger bold":"bold"}>{this.props.label===undefined ? "Label Missing":this.props.label}</label>
				
			    <BtnGroup {...this.props} onClick={this.onClick} />
			</div>
		)
	}
}

export default InputBtnGroup