import React, { useEffect, useState } from 'react';
import FieldsInt from '../Fields.interface';
import usePrevious from '../../usePrevious';
import { getProductAvailability } from '@capcenter/shared';
import findAllExceptions from '../../functions/findAllExceptions';

function AvailabilityCall(props: { fields: FieldsInt; children: (props: any) => React.ReactElement<any> }) {
  const prevProps = usePrevious(props);
  const [availability, setAvailability] = useState<any>({});
  const [exceptions, setExceptions] = useState<any>({});

  useEffect(() => {
    // Get needed data
    if (
      (prevProps?.fields.CountyId !== props.fields.CountyId ||
        (props.fields.CountyId !== '' && exceptions.length === 0)) &&
      props.fields.CountyId !== ''
    ) {
      getProductAvailability(props.fields.CountyId).then((pa: any) => {
        setExceptions(findAllExceptions(pa.ProductCombinations));
        setAvailability(pa);
      });
    }
  }, [prevProps, props, exceptions]);

  return <>{props.children({ exceptions, availability })}</>;
}
export default AvailabilityCall;
