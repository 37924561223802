import { quoteUrl } from '@capcenter/shared';
import ProductCombination from './interfaces/ProductCombination';
import checkSessionStorage from './checkSessionStorage';

export default async function getProductCombinations(): Promise<Array<ProductCombination> | string> {
  const url: string = quoteUrl + 'Quote/ProductCombinations';
  const dataId = 'ccProductCombinations';
  const sessionData = checkSessionStorage(dataId);

  if (sessionData) {
    return sessionData;
  } else {
    try {
      let response = await fetch(url);
      let parsedResponse = await response.json();

      let productCombinations: Array<ProductCombination> = parsedResponse;

      window.sessionStorage.setItem(dataId, JSON.stringify(productCombinations));
      window.sessionStorage.setItem(dataId + 'Date', JSON.stringify(new Date()));

      return productCombinations;
    } catch (err) {
      console.log(err);

      return 'Unexpected Product Combinations Database Error. Please try again later.';
    }
  }
}
