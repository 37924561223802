import React from 'react';
import '../Realty.scss';
import { ReactComponent as TopWorkPlacesLogo } from '../../assets/top-work-places.svg';

class ReviewSection extends React.Component {
  render() {
    return (
      <section className="section-reviews rtb-flex">
        <div data-aos="zoom-in" data-aos-once="true" data-aos-delay="300" data-aos-duration="3000">
          <a
            href="https://www.zillow.com/profile/CapCenterLLC"
            title={'See CapCenter Realty reviews on Zillow'}
            target={'_blank'}
            rel={'noreferrer noopener'}
          >
            <img src={require('../../assets/zillow-five-star-rating-capcenter.jpg')} alt="Five-Star Rating on Zillow" />
          </a>
          <p className="caption-title">5-star rating</p>
          <p className="caption-text">500+ reviews</p>
        </div>
        <div data-aos="zoom-in" data-aos-once="true" data-aos-delay="500" data-aos-duration="3000">
          <a
            href="https://www.trustpilot.com/review/www.capcenter.com"
            title={'See CapCenter reviews on TrustPilot'}
            target={'_blank'}
            rel={'noreferrer noopener'}
          >
            <img
              src={require('../../assets/trustpilot-top-ratings-reviews-capcenter.jpg')}
              alt="Highly Rated on TrustPilot"
            />
          </a>
          <p className="caption-title">4.8-star rating</p>
          <p className="caption-text">800+ reviews</p>
        </div>
        <div data-aos="zoom-in" data-aos-once="true" data-aos-delay="700" data-aos-duration="3000">
          <a
            href="https://www.bbb.org/us/va/glen-allen/profile/mortgage-broker/capcenter-0603-6000171"
            title={"See CapCenter's A+ rating on BBB"}
            target={'_blank'}
            rel={'noreferrer noopener'}
          >
            <img src={require('../../assets/bbb-a-plus-rating-capcenter.jpg')} alt="A+ Rating on BBB" />
          </a>
          <p className="caption-title">A+ rating</p>
          <p className="caption-text">Accredited since 2005</p>
        </div>
        <div data-aos="zoom-in" data-aos-once="true" data-aos-delay="800" data-aos-duration="3000">
          <a
            href="https://topworkplaces.com/company/capcenter/"
            title={"See CapCenter's Top workplaces awards in 2021 and 2022"}
            target={'_blank'}
            rel={'noreferrer noopener'}
          >
            <TopWorkPlacesLogo />
          </a>
          <p className="caption-title">Top workplaces</p>
          <p className="caption-text">2021 & 2022</p>
        </div>
        <div data-aos="zoom-in" data-aos-once="true" data-aos-delay="1000" data-aos-duration="3000">
          <a
            href="https://richmondmagazine.com/best-of-richmond/best-and-worst/best-worst-2023-services/"
            title={
              "See CapCenter's number one best mortgage lender ranking on Richmond Magazine's Best and Worst Services"
            }
            target={'_blank'}
            rel={'noreferrer noopener'}
          >
            <img
              src={require('../../assets/richmondmagazine-best-mortgage-lender-capcenter.jpg')}
              alt="Best Mortgage Lender on Richmond Magazine"
            />
          </a>
          <p className="caption-title">Best mortgage lender</p>
          <p className="caption-text">2021 & 2023</p>
        </div>
      </section>
    );
  }
}

export default ReviewSection;
