import React, { Component } from 'react';
import queryString from 'qs';
import HomeHero from './home/HomeHero';
import BuyHero from './buy/BuyHero';
import BuyRealtyHero from './buy-realty/BuyRealtyHero';
import SellHero from './sell/SellHero';
import RefiHero from './refi/RefiHero';

import { AppHelmet, AppWrapper, Colors, Weights } from '@capcenter/shared';
import {
  RTB,
  RTBImage,
  RTBImages,
  OurTeam,
  TrustPilot,
  RatesCarouselV3,
  LoanOptions,
  NumberOne,
  Hero,
  Testimonials,
  TextBlocks,
  Tooltip,
  Tracking,
  WhyCapCenter,
  WhyCapCenterPurchase,
  WhyCapCenterList,
  GlobalIcons,
  ServiceAreaStaticImg
} from '@capcenter/shared-legacy';

import TabNav from '../navs/TabNav';
import Actions from '../Actions';
import ReactGA from 'react-ga';
import SellSavings from './savings/SellSavings';
import BuySavings from './savings/BuySavings';
import RefiSavings from './savings/RefiSavings';

import '@capcenter/shared-legacy/src/constants/GlobalStyles.scss';
import '../Page.scss';
import { mapQuoteWidgetFromFields } from "@capcenter/quote";

GlobalIcons();

ReactGA.initialize(Tracking.google, { debug: false });

class Page extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    this.props.confirmRoute();
    window.scrollTo(0, 0);
  }

  render() {
    let isHome = false;
    let isBuy = false;
    let isBuyRealty = false;
    let isSell = false;
    let isRefi = false;
    let query;

    switch (this.props.page.app.url) {
      case '/buy-a-home':
        query = this.props.buySavings.query
          ? '?' + queryString.stringify({ quoteWidget: mapQuoteWidgetFromFields(this.props.buySavings.query) }) + '#savings'
          : '';
        isBuy = true;
        break;
      case '/best-realtor-buy-home':
        query = this.props.buySavings.query
          ? '?' + queryString.stringify({ quoteWidget: mapQuoteWidgetFromFields(this.props.buySavings.query) }) + '#savings'
          : '';
        isBuyRealty = true;
        break;
      case '/sell-a-home':
        query = '?price=' + this.props.sellSavings.value;
        isSell = true;
        break;
      case '/refinance':
        query = this.props.refiSavings.query
          ? '?' + queryString.stringify({ quoteWidget: mapQuoteWidgetFromFields(this.props.refiSavings.query) }) + '#savings'
          : '';
        isRefi = true;
        break;
      default:
        query = '';
        isHome = true;
    }

    const helmetItem = JSON.parse(JSON.stringify(this.props.page.app));

    return (
      <>
      <AppWrapper>
        {/*<Pusher color={Colors.primary}>We&apos;re gonna screw your experience up!</Pusher>*/}

        <AppHelmet
					appItem={helmetItem}
				/>

        <RTB hero={true}>

          {(isHome ? <HomeHero></HomeHero> : '')}
          {(isBuy ? <BuyHero></BuyHero> : '')}
          {(isBuyRealty ? <BuyRealtyHero></BuyRealtyHero> : '')}
          {(isSell ? <SellHero></SellHero> : '')}
          {(isRefi ? <RefiHero></RefiHero> : '')}
        </RTB>

        {this.props.page.compareSavings ? (
          <Tooltip
            arrow
            position="top"
            distance={-4}
            open={true}
            html={
              <div style={{ color: Colors.white100, fontWeight: Weights.bolder, fontSize: '120%' }}>
                Select a Service to Get Started
              </div>
            }
          >
            <div style={{ backgroundColor: Colors.white100 }}>
              <TabNav pages={this.props.pages} active={this.props.active} tabClick={this.props.tabClick} />
            </div>
          </Tooltip>
        ) : (
          <TabNav pages={this.props.pages} active={this.props.active} tabClick={this.props.tabClick} />
        )}

        {this.props.page.actions && (
          <Actions actions={this.props.page.actions} query={query}>
            {this.props.page.savings && (
              <>
                {this.props.page.savings === 'buy' && <BuySavings {...this.props} />}
                {this.props.page.savings === 'sell' && <SellSavings {...this.props} />}
                {this.props.page.savings === 'refi' && <RefiSavings {...this.props} />}
              </>
            )}
          </Actions>
        )}

        {this.props.page.compareSavings && (
          <div
            style={{
              backgroundColor: Colors.white100,
              fontWeight: Weights.normal,
              fontSize: '110%',
              fontStyle: 'italic',
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            <div className="container d-flex text-center" style={{ padding: 0, backgroundColor: Colors.white100 }}>
              {this.props.pages.map((page, i) => (
                <React.Fragment key={i}>
                  {page.savings === 'buy' && page.path !== '/best-realtor-buy-home' && <BuySavings {...this.props} key={i} thisPage={page} tooltip={true} />}
                  {page.savings === 'sell' && <SellSavings {...this.props} key={i} thisPage={page} tooltip={true} />}
                  {page.savings === 'refi' && <RefiSavings {...this.props} key={i} thisPage={page} tooltip={true} />}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}

        {this.props.page.rtbs.map((rtb, i) => (
          <RTB {...rtb} key={i}>
            <>
              {rtb.hero && <Hero hero={rtb.hero} />}

              {rtb.image && rtb.type !== 'servicearea' && <RTBImage image={rtb.image} />}
              {rtb.type === 'servicearea' && (
                <ServiceAreaStaticImg image={rtb.image} />
              )}

              {rtb.images && <RTBImages images={rtb.images} />}

              {rtb.textBlocks && <TextBlocks textBlocks={rtb.textBlocks} />}

              {rtb.type === 'ourteam' && <OurTeam team={rtb.team} />}

              {/* {rtb.type === 'trustpilot' && <TrustPilot />} */}

              {rtb.type === 'rates' && <RatesCarouselV3 className={'rates'} {...rtb} />}

              {rtb.type === 'loanoptions' && <LoanOptions />}

              {rtb.type === 'testimonials' && <Testimonials />}

              {rtb.type === 'whycapcenter' && <WhyCapCenter />}

              {rtb.type === 'whycapcenterpurchase' && <WhyCapCenterPurchase />}

              {rtb.type === 'whycapcenterlist' && <WhyCapCenterList />}

              {rtb.type === 'numberone' && <NumberOne />}
            </>
          </RTB>
        ))}
      </AppWrapper>
      </>
    );
  }
}

export default Page;
