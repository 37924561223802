import React, { Component } from 'react';
import './SellHero.scss';

export class SellHero extends Component {
  render() {
    return (
      <div className='sell-hero'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='sell-hero-left col-lg'>

             </div>
            <div className='sell-hero-right col-lg sell-hero-image'>

            </div>
          </div>
        </div>
        <div className='container text-overlap'>
          <div className='row'>
            <div className='sell-hero-left col-lg sell-hero-main'>
              <h1>ZERO Listing Fees,<br/>
              ZERO Closing Cost Purchase</h1>
              <h2>Sell for 0% Listing Fees, Buy with <div className='d-inline-block'>Zero Closing Costs.</div></h2>

              <div className='row'>
                <div className='col-sm'>
                  <a href='/forms/request-listing-agent' title="Consult with a listing agent"><button className='btn-primary'>Request consultation</button></a>
                </div>
                <div className='col-sm'>
                  <a href='/home-sale-estimate/valuation' title="Estimate net proceeds from sale"><button>See savings details</button></a>
                </div>
              </div>
            </div>
            <div className='sell-hero-right col-lg'>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SellHero;