import React from 'react';
import AppInt from '../../constants/App.interface';
import EmployeeIcon from '../EmployeeIcon';
import SVGIcon from '../SVGIcon';

const AppIcon: React.SFC<{ app: AppInt }> = props => {
  if (props.app.icon.includes('employee-')) {
    const team: string = props.app.icon.replace('employee-', '');

    return <EmployeeIcon team={team} />;
  } else {
    return <SVGIcon icon={props.app.icon} />;
  }
};

export default AppIcon;
