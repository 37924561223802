import { StateCode } from '../../Enums';

const convertDollar = (value: string) => Number(value.replace(/[^0-9-]+/g, ''));

const mapQuote = (fields: any) => {
  let loanType = '';
  let loanPurpose: string[] = [];
  let varLoanType: string[] = [];

  if (fields.LoanType === 'Fixed') {
    loanType = 'Fixed';
  } else {
    loanType = 'Variable';
  }

  if (fields.LoanPurpose === 'Purchase') {
    loanPurpose = ['Purchase'];
  } else if (fields.LoanPurpose === 'Refinance') {
    loanPurpose = ['Refinance'];
    if (fields.LoanProgram === 'FHA' || fields.LoanProgram === 'VA') {
      loanPurpose = ['StreamLineRefinance'];
    } else if (fields.LoanProgram === 'All') {
      loanPurpose = ['Refinance', 'StreamLineRefinance'];
    }
  } else if (fields.LoanPurpose === 'Cash Out Refinance') {
    loanPurpose = ['CashoutRefinance'];
  }

  if (fields.LoanType === '5/6 ARM'){
    varLoanType = ['_5_6']
  }

  if (fields.LoanType === '7/6 ARM') {
    varLoanType = ['_7_6'];
  }

  if (fields.LoanType === '10/6 ARM') {
    varLoanType = ['_10_6'];
  }

  const request = {
    AnnualIncome: fields.AnnualIncome,
    CountyId: fields.CountyId,
    DownPayment: fields.DownPayment,
    LoanAmount: fields.LoanAmount,
    LoanProgram: fields.LoanProgram,
    LoanPurpose: fields.LoanPurpose,
    LoanTerm: parseInt(fields.LoanTerm.substring(0, 2)),
    LoanType: fields.LoanType,
    NeedRealtyTeam: fields.NeedRealtyTeam === '' ? false : fields.NeedRealtyTeam === 'Yes' ? true : false,
    PropertyType: fields.PropertyType,
    PropertyUse: fields.PropertyUse,
    PropertyValue: fields.PurchasePrice,
    ExistingLoanBalance:
      fields.ExistingLoanBalance && fields.LoanPurpose === 'Cash Out Refinance'
        ? convertDollar(fields.ExistingLoanBalance)
        : 0,
    OriginalLoanAmount:
      fields.OriginalLoanAmount && fields.LoanPurpose !== 'Purchase' ? convertDollar(fields.OriginalLoanAmount) : 0,
    PurchasePrice: fields.PurchasePrice,
    FirstTimeHomeBuyer:
      fields.FirstTimeHomeBuyer === ''
        ? false
        : fields.FirstTimeHomeBuyer === 'true' || fields.FirstTimeHomeBuyer === true
          ? true
          : false,
    RegularMilitary: fields.RegularMilitary === '' ? false : fields.RegularMilitary,
    PreviousVALoan: fields.PreviousVALoan === '' ? false : fields.PreviousVALoan,
    HomeownersInsuranceEscrow: fields.HomeownersInsuranceEscrow ? convertDollar(fields.HomeownersInsuranceEscrow) : 0,
    PropertyTax: fields.PropertyTax ? convertDollar(fields.PropertyTax) : 0,
    PropertyStateLocation: StateCode[fields.State],
    PurchasedWithinLastYear: !!fields.PurchasedWithinLastYear,
    ZipCode: fields.ZipCode,
  };

  return JSON.stringify(request);
};

export default mapQuote;
