import React from 'react';
import { Link } from 'react-router-dom'
import { Colors, Weights, getUrlWithUtmQueryString } from '@capcenter/shared'
import { Icon } from '@capcenter/shared-legacy'
import './BigBtns.scss'

const BigBtn = ({text, icon, active, onClick, link, image, disabled, first, last}) => (
	<Link className="col" to={getUrlWithUtmQueryString(link)} style={{padding:0, textDecoration: "none", }}>
		<div 
			className={(disabled ? "cc--big-btn disabled" : "cc--big-btn") + (active ? " active" : "")}
			onClick={()=>onClick(getUrlWithUtmQueryString(link))}
			style={{
				color: Colors.black500,
				fontWeight: Weights.normal,
				display: "inline-block",
				textAlign: "center",
				whiteSpace: "nowrap",
				verticalAlign: "middle",
				borderRight: "1px solid " + Colors.gray350,
				borderLeft: "1px solid " + Colors.gray350,
			}}
			text="">
			<span className="d-sm-none" >
				{icon && <Icon icon={["icon", icon]} style={{fontSize: "2.5rem"}}/>}
				{image && <div style={{width: "3rem", height: "3rem", margin: "0 auto 7.5px auto"}}>{image}</div>}
				<span style={{fontFamily: 'omnes-pro, arial, sans-serif', fontWeight: 700, color: '#0C2C48' }}>{text}</span>
			</span>
			<span className="d-none d-sm-flex justify-content-center align-items-center" style={{fontSize: (icon || image) ? "1.2rem" : "inherit"}}>
				<span style={{paddingRight: 15}}>
					{icon && <Icon icon={["icon", icon]} style={{fontSize: "1.5rem"}}/>}
					{image && <div style={{width: "3rem", height: "3rem"}}>{image}</div>}
				</span>
				<span style={{fontSize: "1.5rem", fontFamily: 'omnes-pro, arial, sans-serif', fontWeight: 700, color: '#0C2C48' }}>{text}</span>
			</span>
		</div>	
	</Link>

)

const BigBtns = ({btns, active, onClick}) => (
	
	<div className="row" style={{margin: 0}}>
	
		{btns.map( (btn, i) => (
			
			<BigBtn 
				key={i} 
				text={btn.text}
				icon={btn.icon}
				active={active === btn.linkConst}
				onClick={onClick !== undefined ? (onClick) : btn.onClick}
				link={btn.link}
				image={btn.image}
				disabled={btn.disabled}
				first={i===0}
				last={i===btns.length -1}/>
			
		))}
	
	</div>
	
)


export default BigBtns