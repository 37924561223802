import React, {Component} from 'react'
import Button from '../components/Button';
 
const CarouselBtn = (props) => <Button {...props} icon={props.left ? "chevron-left":"chevron-right" } className="btn-link" text="" color="transparent" style={{fontSize: "2rem", position: "absolute", top: "50%", marginTop:"-2rem", left: (props.left ? 0:"auto"), right: (props.left ? "auto":0), zIndex: 100}}/>

const Video = ({id, onClick}) => (
	<div style={{height: "300px", width: "100%", position: "relative"}}>
		<div style={{position: "absolute", zIndex: 100, top:0, right:0, bottom:0, left:0}}>
			<div className="container" style={{position: "relative", height: "100%", padding:0, }}>
		
				<iframe title="testimonial" 
					style={{display: "block", height: "100%", width: "100%", maxWidth: 711, margin: "0 auto"}} 
					src={"https://www.youtube.com/embed/"+id+"?controls=0&rel=0"} 
					frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
					allowFullScreen></iframe>
	
	    	<CarouselBtn left onClick={onClick}/>
	    	<CarouselBtn onClick={onClick}/>
	    </div>
    </div>
    
  	<div style={{
    	position: "absolute", 
    	top:0,right:0,bottom:0,left:0, 
    	backgroundImage: "url(https://img.youtube.com/vi/"+id+"/0.jpg)", 
    	backgroundSize: "cover",
    	backgroundPosition: "center center",
    	filter: "blur(8px)",
    	transform: "scale(1.1)",
    	zIndex: 0}}/>
    	
	</div>
)

export class Testimonials extends Component {

  constructor(props) {
    super(props);  
    this.state = {
	    id:0
    }
  }
  
  toggle = () => this.setState({id: this.state.id === 0 ? 1 : 0})

  render() {

    return (
	    
			<>
					<Video id={this.state.id === 1 ? "9lNLfBeT85o": "b3ODHX8P9QQ"} onClick={this.toggle} />
			</>    
	    

    );
  }
}

export default Testimonials

