import { getContent } from '@capcenter/shared';

const processProfiles = (profiles, random) => {
	// Remove Duplicates
	let seenNames = {};
	const profilesFiltered = profiles.filter(currentObject => {
		if (currentObject.name in seenNames) {
			return false;
		} else {
			seenNames[currentObject.name] = true;
			return true;
		}
	});
	
	// sort or randomize
	if (random) {
		profilesFiltered.sort(() => Math.random() - 0.5);
	} else {
		profilesFiltered.sort((a, b) => a.name.localeCompare(b.name))
	}
	
	return profilesFiltered
}

const getTeams = (teamName, random) => {
	
		let allProfiles = []
		
		// no match, realty, loan, about
		switch(teamName) { 
			case "realtyAndLoan": {

				return getContent("OurTeam").then(ourTeam => {
					
					if(ourTeam.status !== undefined) return ourTeam.statusText;
					if(ourTeam.length < 5) return [];
					
					let relevantTeams = [ourTeam[0], ourTeam[1], ourTeam[4]];
					
					relevantTeams.forEach(team => {
						team.regions.forEach((region) => {
							region.profiles.forEach((profile) => {
								let thisProfile = profile
								thisProfile.team = {name: team.name, hash: team.hash}									
								allProfiles.push(thisProfile)
							})
						})
					});						
					return processProfiles(allProfiles, random);
				})

			}
			case "realty": { 

				return getContent("OurTeam").then(ourTeam => {
					
					if(ourTeam.status === undefined){
				
						ourTeam.length !== 0 && (
							ourTeam[0].regions.forEach((region) => {
								
								region.profiles.forEach((profile) => {
									
									let thisProfile = profile
									thisProfile.team = ourTeam[0]
									delete thisProfile.team.regions
									
									allProfiles.push(thisProfile)
								})
							})
						)
						
						return processProfiles(allProfiles, random)
						
					}else{
						
						return ourTeam.statusText
						
					}					
					
				})

			} 
			case "loan": { 
				
				return getContent("OurTeam").then(ourTeam => {
					
					if(ourTeam.status === undefined){
				
						ourTeam.length !== 0 && (
							ourTeam[1].regions.forEach((region) => {
								
								region.profiles.forEach((profile) => {
									
									let thisProfile = profile
									thisProfile.team = ourTeam[1]
									delete thisProfile.team.regions
									
									allProfiles.push(thisProfile)
								})
							})
						)
						
						return processProfiles(allProfiles, random)
						
					}else{
						
						return ourTeam.statusText
						
					}					
					
				})

			}

			case "web": {

				return getContent("OurTeam").then(ourTeam => {

					if(ourTeam.status !== undefined) return ourTeam.statusText;
					if(ourTeam.length < 6) return [];

					let relevantTeams = [ourTeam[5]];

					relevantTeams.forEach(team => {
						team.regions.forEach((region) => {
							region.profiles.forEach((profile) => {
								let thisProfile = profile
								thisProfile.team = {name: team.name, hash: team.hash}
								allProfiles.push(thisProfile)
							})
						})
					});
					return processProfiles(allProfiles, random);
				})

			}

			case "about": {
				return getContent("AboutUs").then(ourTeam => {
					
					if(ourTeam.status === undefined){
				
						ourTeam.length !== 0 && (
								
								ourTeam.profiles.forEach((profile) => {
									
									let thisProfile = profile
									thisProfile.team = "about"
									
									allProfiles.push(thisProfile)
								})
						)
						
						return processProfiles(allProfiles, random)
						
					}else{
						
						return ourTeam.statusText
						
					}					
					
				})

			}

			default: { 

				return getContent("OurTeam").then(ourTeam => {

					if(ourTeam.status === undefined){
				
						ourTeam.length !== 0 && (
							ourTeam.forEach((team) => {
								
								team.regions.forEach((region) => {
									
									region.profiles.forEach((profile) => {
										
										let thisProfile = profile
										thisProfile.team = team
										delete thisProfile.team.regions
										
										allProfiles.push(thisProfile)
									})
								})
							})
						)
						
						return getContent("AboutUs").then(ourTeam => {
							
							if(ourTeam.status === undefined){
						
								ourTeam.length !== 0 && (
										
										ourTeam.profiles.forEach((profile) => {
											
											let thisProfile = profile
											thisProfile.team = "about"
											delete thisProfile.team.regions
											
											allProfiles.push(thisProfile)
										})
								)
								
								return processProfiles(allProfiles, random)
								
							}else{
								
								return ourTeam.statusText
								
							}					
							
						})
						
					}else{
						
						return ourTeam.statusText
						
					}					
					
				})

			} 
		}

		
}

export default getTeams;