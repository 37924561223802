import React from "react";
import Password from "./svgs/Password.svg";
import './Password.scss'
import '../Animations.scss'

function Intro() {

  return (
    <div className="SVGAnimation">
      <img className="SVGPassword" src={Password} alt="logo" />
    </div>
  );
}

export default Intro;
