import React from 'react';
import FindLoanAmountType from './LoanAmount/FindLoanAmountType';

const initialState = {
  initialLoad: true,
  limits: [],
  loading: false,
  loanAmountType: '',
};
type State = Readonly<typeof initialState>;

export default class LoanAmountType extends React.Component<{
  fields?: any;
  onChange: Function;
  inputKey: string;
  availability: any;
}> {
  readonly state: State = initialState;

  componentDidUpdate(prevProps: any) {
    const previousCountyId = prevProps.availability?.CountyId;
    const countyId = this.props.availability?.CountyId;

    if (countyId
      && (previousCountyId !== countyId
        || this.state.initialLoad
        || prevProps.fields.LoanAmount !== this.props.fields.LoanAmount)) {

      this.setState({initialLoad: false});
      this.handleSelect();
      this.setState({
        loanAmountType: FindLoanAmountType(this.props.fields.LoanAmount, this.props.availability.LoanAmountLimits)
      }) 
    }

    if (countyId
      && this.props.fields.LoanAmount
      && (this.state.loanAmountType !== '' && this.state.loanAmountType !== this.props.fields.LoanAmountType)) {
        this.handleSelect();
    }
  }

  handleSelect = () => {
    const LoanAmountType = FindLoanAmountType(this.props.fields.LoanAmount, this.props.availability.LoanAmountLimits);

    if (LoanAmountType !== '' && LoanAmountType !== this.props.fields.LoanAmountType) {
      this.props.onChange(LoanAmountType, this.props);
    }
  };

  render() {
    return (
      <>
        {this.props.fields.LoanAmountType &&
          ' - ' + this.props.fields.LoanAmountType}
      </>
    );
  }
}
