import React from 'react';

import Modal from './Modal';
import {Button} from '@capcenter/shared-legacy';
import {Colors} from '@capcenter/shared';

class ARMDisclosure extends React.Component {

	close = () => {
		
		this.props.close()
		
	}
	
	agree = () => {
		
		this.props.setAppState({LoanType: this.props.option})
		
		this.props.close()
		
	}

  render() {
	  
	  const years = parseInt(this.props.option.split("/")[0])

		if (this.props.open) {
			return (

				<Modal
					title={"Adjustable Rate Mortgage Disclosure"}
					visible={this.props.open}
					close={this.close}
					footer={<div><Button color={Colors.green500} className="float-right" text="Agree & Continue"
															 onClick={this.agree} /></div>}>
					<>

						<div style={{maxHeight: 300, overflow: "auto"}}>
							<p class={"text-center"}>
								IMPORTANT LOAN INFORMATION
								<br />
								PLEASE READ CAREFULLY AND ACKNOWLEDGE BELOW TO CONTINUE THE LOAN APPLICATION.
							</p>
							<p>
								<strong>PROGRAM NAME:</strong> Conventional {years}/6 Adjustable Rate Mortgage
							</p>
							<p>
								<strong>
									ADJUSTABLE RATE MORTGAGE MEANS YOUR PAYMENT MAY CHANGE IN
									THE FUTURE.
								</strong>{" "}
								You are applying for an Adjustable Rate Mortgage (ARM) loan.
								This means that your interest rate and monthly payments may change during the
								life of your loan. Your monthly payments will increase if the interest rate
								rises and decrease if it falls. The date or dates on which changes can occur (referred
								to in this disclosure as "Change Date") will be specified in the ARM loan
								documents. This ARM is based on the terms and conditions set forth in this
								disclosure and in the loan documents. We have based this disclosure on interest
								rates, index and margin values, and fees as of
								January 2023.
								Ask us for our current interest rates and margins.
							</p>

							<p>
								An ARM is different from a fixed rate mortgage loan. For a
								fixed rate loan, the monthly payments of principal and interest do not change
								during the life of the loan. You should consider carefully which type of loan
								is best for you.
							</p>

							<p>
								<strong>HOW YOUR INTEREST RATE IS DETERMINED.</strong>{" "} Your interest
								rate will be determined by means of an index that may change from time to time.
							</p>

							<p className="MsoCommentText" style={{marginLeft: ".25in", textAlign: "left"}}>
								<span className="GramE">
									<strong>
										The Index.
									</strong>
								</span>
								The interest rate change on this ARM will be based on an
								interest rate index (referred to in this disclosure as the "Index.")
								<span>&nbsp; </span>
								The Index is the
								{" "}
								<strong>30-Day Average SOFR </strong>
								<span style={{color: "red"}}> </span>
								index.
								Information
								about the index is available via
								<span className="auto-style2"> </span>
								{" "}
								<a
									href="https://www.newyorkfed.org/markets/reference-rates/sofr-averages-and-index"
									target="_blank"
									rel="noopener noreferrer"
								>
									https://www.newyorkfed.org/markets/reference-rates/sofr-averages-and-index
								</a>
								.
							</p>

							<p style={{marginLeft: ".25in"}}>
								<span className="GramE"><strong>Interest Rate.</strong>{" "} </span>
								Your interest rate is based on the Index value plus a margin, rounded to the
								nearest
								{" "}
								<strong>0.125%</strong>
								{" "}
								percent. A change in the Index generally will result in a
								change in the interest rate. The amount that your interest rate may change also
								may be affected by the periodic interest rate change limitations and the
								lifetime interest rate limits as discussed below.
								Your initial interest rate will not be based on the index used for later adjustments.
								Please ask about our current discount or premium amount.
							</p>

							<p style={{marginLeft: ".25in", textAlign: "left"}}>
							<span className="GramE">
								<strong>
									Interest Rate Adjustments.
								</strong>{" "}
							</span> Your interest rate under this ARM can change after<strong>
								{" "}{years * 12}{" "}
								{" "}
							</strong> months and every <strong>6</strong> months thereafter. Your interest
								rate cannot increase or decrease more than
								{" "}
								{years === 5 ? <strong>2.00</strong> : <strong>5.00</strong>}
								{" "}
								percentage points at first
								adjustment and <strong>1.00</strong> percentage
								points per subsequent adjustment from the initial interest rate excluding any
								buy down. Your interest rate will never be greater than
								<strong> 5.00</strong> percentage points above the initial interest rate or lower
								<strong> than the margin, which is 2.75%.</strong>
							</p>

							<p>
								<strong>HOW YOUR PAYMENTS ARE DETERMINED.</strong>{" "} Your initial
								monthly payment of principal and interest will be determined based on the
								interest rate, loan term, and balance when your loan is closed. Your payment
								will be set to amortize the loan over a period of <strong> 360</strong> payments.
							</p>

							<p style={{marginLeft: ".25in", textAlign: "left"}}>
							<span className="GramE">
								<strong>Frequency of Payment Changes.</strong>{" "}

							</span>
								Based on increases or decreases in the Index, payment amounts under this ARM loan can change after
								{" "}<strong>{years * 12}</strong>{" "}
								months and every
								<strong> 6 </strong>
								months thereafter. However, your
								monthly payment amount could change more frequently if there is a change in the
								taxes, assessments, insurance premiums, or other charges required to be made
								into an escrow or impound account.
							</p>

							<p style={{marginLeft: ".25in", textAlign: "left"}}>
							<span className="GramE">
								<strong>Limitations on Interest Rate Payment Changes.</strong>{" "}
							</span>
								<span style={{fontWeight: "normal"}}>
								Your interest rate will not increase or decrease on the first change date by
								more than

							</span>
								{" "}
								{years === 5 ? <strong>2.00</strong> : <strong>5.00</strong>}
								{" "}
								<span style={{fontWeight: "normal"}}>
								percentage points from the initial interest rate
								excluding any buy down. After the initial adjustment period, your interest rate
								will not increase or decrease by more than
							</span>
								{" "}<strong> 1.00 </strong>{" "}

								<span style={{fontWeight: "normal"}}>
								percentage points per 6 months. Your interest rate cannot increase more than
									{" "}
							</span>
								<strong> 5.00</strong>{" "}
								<span style={{fontWeight: "normal"}}>
								percentage points above the initial interest rate (excluding any buydown) over
								the life of the loan.
							</span>
							</p>

							<p style={{marginLeft: ".25in", textAlign: "left"}}>
								<span className="GramE"><strong>Adjustment Notices.</strong>{" "} </span>
								Your monthly payment can increase or decrease substantially based on yearly changes in the interest
								rate.
								You will be notified at least 210,
								but no more than 240,
								days before the first payment at the adjusted level is due after the initial interest rate adjustment of
								the loan.
								This notice will contain information about the adjustment,
								including the interest rate,
								payment amount,
								and loan balance.
								You will be notified at least <strong>60</strong>,
								but no more than <strong>120</strong>,
								days before the first payment at the adjusted level is due after any interest rate adjustment resulting
								in a corresponding payment change.
								This notice will contain information about the adjustment,
								including the interest rate,
								payment amount,
								and loan balance.
							</p>

							<p>
							<span className="GramE">
								<strong>
									HOW YOUR PAYMENT CAN CHANGE ("Worst
									Case Scenario").
								</strong>{" "}
							</span>
								Your payment can change every <strong>6</strong> month(s) based on changes in the loan term,
								interest rate,
								or loan balance.
								{years === 5
									? <span>
										For example,
										on a $10,000 loan with a <strong>360</strong> month term and an initial rate of <strong>6.875%</strong>,
										the maximum amount that the interest rate can rise under this ARM program
										is <strong>5.000</strong> percentage points above the initial interest rate and the payment can rise from
										a first-year payment of <strong>$65.69</strong> to a maximum of <strong>$95.11</strong> in the <strong>79</strong>th month.
									</span>
									: (years === 7
										? <span>
										For example,
										on a $10,000 loan with a <strong>360</strong> month term and an initial rate of <strong>6.875%</strong>,
										the maximum amount that the interest rate can rise under this ARM program
										is <strong>5.000</strong> percentage points above the initial interest rate and the payment can rise from
										a first-year payment of <strong>$65.69</strong> to a maximum of <strong>$96.38</strong> in the <strong>85</strong>th month.
									</span>
								: <span>
										For example,
										on a $10,000 loan with a <strong>360</strong> month term and an initial rate of <strong>6.875%</strong>,
										the maximum amount that the interest rate can rise under this ARM program
										is <strong>5.000</strong> percentage points above the initial interest rate and the payment can rise from
										a first-year payment of <strong>$65.69</strong> to a maximum of <strong>$93.46</strong> in the <strong>121</strong>st month.
									</span>)}
							</p>

							<p>

								<strong>EXAMPLE OF HOW TO
									CALCULATE THE INITIAL MONTHLY PAYMENT OF PRINCIPAL AND INTEREST BASED ON THE
									INTEREST RATE SCENARIO ABOVE</strong><span>&nbsp; </span>

								{years === 5 ?
									(<span>
										To see
										what the monthly payment of principal and interest would be for any loan amount
										based on the interest rate in the paragraph above, use the following
										formula:
										{" "}
										(desired mortgage amount
										{" "}
										÷
										{" "}
										$10,000) x ($65.69).
										For example,
										the monthly principal and interest payment for a new loan amount of $60,000
										would be:
										($60,000 ÷ $10,000) x ($65.69) = $394.14 per month.{" "}
									</span>) :
									(years === 7
										? (<span>
										To see
										what the monthly payment of principal and interest would be for any loan amount
										based on the interest rate in the paragraph above, use the following
										formula:
											{" "}
											(desired mortgage amount
											{" "}
											÷
											{" "}
											$10,000) x ($65.69).
										For example,
										the monthly principal and interest payment for a new loan amount of $60,000
										would be:
										($60,000 ÷ $10,000) x ($65.69) = $394.14 per month.{" "}
									</span>) : (<span>
										To see
										what the monthly payment of principal and interest would be for any loan amount
										based on the interest rate in the paragraph above, use the following
										formula:
											{" "}
											(desired mortgage amount
											{" "}
											÷
											{" "}
											$10,000) x ($65.69).
										For example,
										the monthly principal and interest payment for a new loan amount of $60,000
										would be:
										($60,000 ÷ $10,000) x ($65.69) = $394.14 per month.{" "}
									</span>))}
									</p>

							<p>
								<span className="GramE"><strong>PREPAYMENT.</strong>{" "}</span> You may pay this
								ARM loan in whole or part without penalty at any time. If you are paying more
								than your regularly scheduled payment, you must notify us as to how you want
								the funds applied.
							</p>

							<p>
								<strong>DEMAND FEATURE.</strong> This loan <strong>does not</strong> include a demand feature.
							</p>

							<p>
								<strong>DISCLOSURES FOR OTHER ARM PROGRAMS</strong>{" "} are available on
								request.
							</p>

							<p /><p /><p />

							<p>
								Additional important information about Adjustable Rate Mortgages is
								available in the
								{" "}
								<a
									href="http://files.consumerfinance.gov/f/201401_cfpb_booklet_charm.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									Consumer Handbook on Adjustable Rate Mortgages
								</a>
								.
								<br />
								We encourage you to read this handbook
								carefully.
							</p>

							<div className="text-wrap">
								<p style={{textAlign: "center"}}><strong>BORROWER ACKNOWLEDGEMENT</strong></p>
								<p>
									I acknowledge that <span className="SpellE">CapCenter</span> has
									provided the Adjustable Rate Mortgage Disclosure and the Consumer Handbook on
									Adjustable Rate Mortgages to me prior to my submission of an application for an
									Adjustable Rate Mortgage. I further acknowledge that this disclosure was
									completed in full.
								</p>
							</div>


						</div>


					</>


				</Modal>
			);
		} else {
			return <></>
		}
  }
}

export default ARMDisclosure;
