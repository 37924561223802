import React from 'react';
import Modal from 'react-bootstrap4-modal';
import './Modal.scss'

class CleanModal extends React.Component {
	
  render() {
    return (
	    <div>
	      	
	      <Modal visible={this.props.visible} onClickBackdrop={this.props.close} className={'clean-modal'} dialogClassName={this.props.modalDialogClassName}>
	      
	      	{this.props.title && (
		        <div className="modal-header">
		          <h5 className="modal-title" style={{display: "inline-block", float:"left", }}>{this.props.title}</h5>
			        <button type="button" className="close" onClick={this.props.close}>
			          <span aria-hidden="true">&times;</span>
			        </button>
		        </div>
	        )}
	        <div className="modal-body">
	          {this.props.children}
	        </div>
	        {this.props.footer && (
		        <div className="modal-footer">
		          {this.props.footer}
		        </div>
	        )}
	      </Modal>
      </div>
    );
  }
}

export default CleanModal;
