import { contentUrl, baseUrl } from '@capcenter/shared';
import { isThere } from '../functions/Utils';

const GetImage = profile => {
  if (isThere(profile.image)) {
    return profile.image;
  } else {
    return isThere(profile.imageUrl)
      ? contentUrl + 'OurTeam/' + profile.imageUrl
      : baseUrl + '/site-map/svg-icons/agent.svg';
  }
};

export default GetImage;
