import React, { useState } from 'react';
import { remoteConfig } from '../../services/Firebase';

const { LiveChatLoaderProvider, HelpScout } = require('react-live-chat-loader')

const ChatWrapper: React.SFC<{children: React.ReactElement<any>}> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hideHelpScoutChat, setHideHelpScoutChat] = useState(false);

  React.useEffect(() => {
    if (isLoading) {
      remoteConfig.fetchAndActivate().then(() => {
        setHideHelpScoutChat(remoteConfig.getConfigBoolean("hideHelpScoutChat") || false);
        setIsLoading(false);
      });
    }
    else {
      setIsLoading(false);
    }
  }, []);
  if(isLoading || hideHelpScoutChat){
    return <>
      {props.children}
    </>
  }
  return (
    <LiveChatLoaderProvider providerKey="3ff7898a-af5e-44f3-9eb9-c1d384cb2bea" provider="helpScout">
			<HelpScout color={'#ea701b'} horizontalPosition={'right'} icon={'message'} />

	    {props.children}

		</LiveChatLoaderProvider>

	)
}

export default ChatWrapper
