
import Axios, { AxiosRequestConfig } from 'axios'

const firebaseAuthUrl = '/firebaseAuth'

export const sessionLogin = async (idToken: string) => {
  try {
    const options: AxiosRequestConfig = {
      method: 'post',
      url: `${firebaseAuthUrl}/session/login`,
      data: {
        idToken: idToken
      },
      withCredentials: true
    }
  
    return await Axios(options)
  } catch (error) {
    console.error('Error creating session', error)
  }
}

export const sessionLogout = async () => {
  try {
    const options: AxiosRequestConfig = {
      method: 'post',
      url: `${firebaseAuthUrl}/session/logout`
    }
  
    return await Axios(options)
  } catch (error) {
    console.error('Error checking if user is logged in', error)
  }
}

export const verifyHasValidSession = async () => {
  try {
    const options: AxiosRequestConfig = {
      method: 'get',
      url: `${firebaseAuthUrl}/session/verification`
    }
  
    return await Axios(options)
  } catch (error) {
    console.error('Error checking if user is logged in', error)
  }
}

export const getUid = async () => {
  try{
    const options: AxiosRequestConfig = {
      method: 'get',
      url: `/accountapi/account/get-uid`
    };
  
    return (await Axios(options)).data.userId;
  } catch(error) {
    return;
  }
}

export const getUserEmail = async () => {
  try{
    const options: AxiosRequestConfig = {
      method: 'get',
      url: `/accountapi/account/get-email`
    }
  
    return (await Axios(options)).data.email
  } catch(error) {
    return
  }
}

export const getUserPhoneNumber = async () => {
  try{
    const options: AxiosRequestConfig = {
      method: 'get',
      url: `/accountapi/account/get-phone`
    }
  
    return (await Axios(options)).data.phone
  } catch(error) {
    return
  }
}

export const updateUserWithPhoneNumber = async (
  phoneNumber: string,
  token?: string, 
  uid?: string
): Promise<void> => {
  try {
    const options: AxiosRequestConfig = {
      method: 'put',
      url: `${firebaseAuthUrl}/user/phone-number`,
      data: {
        phoneNumber,
        uid,
      },
      headers: {
        "Authorization": `Bearer ${token}`
      },
      withCredentials: true
    }
  
    await Axios(options)
  } catch (error: any) {
    const message: string = "error updating phone number for user"
    console.error(message)
    const err = { message, code: error?.response?.data?.code }
    throw err
  }
}

export const getEmailProvider = async (email: string): Promise<string | undefined> => {
  try {
    const options: AxiosRequestConfig = {
      method: 'post',
      url: `${firebaseAuthUrl}/user/provider`,
      data: {
        email,
      },
      withCredentials: true
    }
  
    const result = await Axios(options)
    return result.data.provider
  } catch (error) {
    console.error('Error creating session', error)
    return undefined
  }
}

export const doesUserExistWithPhoneNumber = async (phone: string): Promise<boolean> => {
  try {
    if (phone) {
      const options: AxiosRequestConfig = {
        method: 'get',
        url: `${firebaseAuthUrl}/user/phone-number/${phone}`,
      }
    
      const result = await Axios(options)
      return result.data.found
    } else {
        throw new Error("expected phone number but got undefined")
      }
  } catch (error) {
    const message: string = "error retrieving whether account exists phone number"
    console.error(message)
    throw error
  }
}

export const createCustomToken = async (uuid: string) => {
  try {
    const options: AxiosRequestConfig = {
      method: 'post',
      url: `${firebaseAuthUrl}/createCustomToken`,
      data: {
        uuid: uuid
      },
      withCredentials: true
    }
   
    return await Axios(options)
  } catch (error) {
    console.error('Error creating custom token', error)
  }
}

export const removeUser = async (uid: string, token: string) => {
  try {
    const options: AxiosRequestConfig = {
      method: 'delete',
      url: `${firebaseAuthUrl}/user/account`,
      data: {
        uid: uid
      },
      headers: {
        "Authorization": `Bearer ${token}`
      },
      withCredentials: true
    }
   
    return await Axios(options)
  } catch (error: any) {
    console.error("error occurred removing user from account management system:", error.message)
    throw error
  }
}

export const getUserFirebaseUserIdFromSession = async function () : Promise<string | undefined> {
  const url: string = `${firebaseAuthUrl}/session/id`

  try {
    const response = await fetch(url);
    if (response.status === 200) {
      const userId = await response.json()
      return userId.uid
    }
    else {
      return undefined
    }
  }
  catch (err) {
    console.log(err)

    return undefined
  }
}