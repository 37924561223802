import React from 'react';
import {AppWrapper, baseRealEstateUrl, baseUrl, getQuoteRates} from "@capcenter/shared";
import '../Realty.scss';
import {AddressSearch, Tooltip, getUrlWithUtmQueryString, getUtmCookieAsUrlQueryString} from "@capcenter/shared-legacy";
import numeral from "numeral";
import moment from "moment";
import {ReactComponent as TopWorkPlacesLogo} from '../../assets/top-work-places.svg';
import RegisteredTrademark from '../../assets/registered-trademark.svg';
import RegisteredTrademarkWhite from '../../assets/registered-trademark-white.svg';

// Core Page Sections
import FAQSection from '../sections/FAQ';
import RatesSection from '../sections/Rates';
import DataSection from '../sections/Data';
import ProcessSection from '../sections/Process';
import RefinanceSection from '../sections/Refinance';
import ReviewsSection from '../sections/Reviews';
import SavingsSection from '../sections/Savings';
import DownPaymentSection from '../sections/DownPayment';
import NextStepsSection from '../sections/NextSteps';
import ListingsSection from '../sections/Listings';
import HomesForSaleSection from '../sections/HomesForSale';

class GetPreapproved extends React.Component {
  render() {

    return <AppWrapper app="realtyPreapproval" noHeader>
      <div role="document">
        <main className="realty">
          <section className="section-search-hero">
            <div className="container-fluid">
              <div className="row justify-content-between">
                <article className="search-group buy">
                  <header>
                    <hr className="hero-rule"/>
                    <h1 data-aos="flip-left">
                      Online <a href={getUrlWithUtmQueryString(`${baseUrl}/apply-now/purchase`)}>pre-approvals</a><br/>in as few as 30 minutes <span><Tooltip
                      arrow
                      position="bottom"
                      html={<div><p>Our online application is mobile-friendly and can be saved and continued at your convenience.</p><p>Call us if you have any questions during the application process. We're here to help!</p></div>}
                    ><i className="fas fa-info-circle"></i></Tooltip></span>
                    </h1>
                  </header>
                  <div className="tab-container">
                    <div className="tab"><a href="/best-realtor-agency-buy-home" className={'d-none d-sm-inline-block tab-buy-link'}>Buy</a><a href="/best-realtor-agency-buy-home" className={'d-inline-block d-sm-none tab-buy-link'}>Buy</a></div>
                    <div className="tab"><a href="/best-realtor-agency-sell-home" className={'d-none d-sm-inline-block tab-sell-link'}>Sell</a><a href="/best-realtor-agency-sell-home" className={'d-inline-block d-sm-none tab-sell-link'}>Sell</a></div>
                    <div className="tab active"><a href="/get-preapproved-for-a-home-loan" className={'d-none d-sm-inline-block tab-preapproval-link'}>Pre-approval</a><a href="/get-preapproved-for-a-home-loan" className={'d-inline-block d-sm-none tab-preapproval-link'}>Pre-approval</a></div>
                    <div className="tab"><a href="/how-much-is-my-home-worth" className={'d-none d-sm-inline-block tab-homevalue-link'}>Home Value</a></div>
                  </div>
                  <div className="search-bar d-block d-sm-none">
                    <AddressSearch
                      success={(data) => {
                        let urlParameters = '';
                        const utmQueryString = getUtmCookieAsUrlQueryString();

                        if (data && data.countyData && data.stateData) {
                          const countyId = data.countyData.CountyId;
                          const stateId = data.stateData.StateId;
                          const address = `${data.county}, ${data.state}, USA`;

                          if (utmQueryString) {
                            urlParameters += utmQueryString;
                          } else {
                            urlParameters += "?";
                          }

                          urlParameters += `quoteWidget[CountyId]=${countyId}&quoteWidget[StateId]=${stateId}&quoteWidget[address]=${address}`
                        }

                        window.location.href = `${baseUrl}/apply-now/purchase${urlParameters}`;
                      }}
                      placeholder={'City, County, Zip, or Address'}
                      includeZip={true}
                      noPadding={true}
                      log={false}
                    />
                  </div>

                  <div className="search-bar d-none d-sm-block">
                    <AddressSearch
                      success={(data) => {
                        let urlParameters = '';
                        const utmQueryString = getUtmCookieAsUrlQueryString();

                        if (data && data.countyData && data.stateData) {
                          const countyId = data.countyData.CountyId;
                          const stateId = data.stateData.StateId;
                          const address = `${data.county}, ${data.state}, USA`;

                          if (utmQueryString) {
                            urlParameters += utmQueryString;
                          } else {
                            urlParameters += "?";
                          }

                          urlParameters += `quoteWidget[CountyId]=${countyId}&quoteWidget[StateId]=${stateId}&quoteWidget[address]=${address}`
                        }

                        window.location.href = `${baseUrl}/apply-now/purchase${urlParameters}`;
                      }}
                      placeholder={'Address, City, County, Zip, or Neighborhood'}
                      includeZip={true}
                      noPadding={true}
                      log={false}
                    />
                  </div>
                </article>
                <article className="hero-right preapproval">
                </article>
              </div>
            </div>
          </section>

          <SavingsSection />

          <RatesSection props={this.props} />

          <DownPaymentSection props={this.props} />

          <DataSection props={this.props} />

          <ReviewsSection props={this.props} />

          <ProcessSection props={this.props} lines={
            [
              {
                text: "Contact us for a no-cost and no-obligation consultation and get matched with a local CapCenter agent.",
                tooltip: <>Schedule a consultation<br/>via our website or call 757-334-2083</>
              },
              {
                text: "Boost your savings and get pre-approved for a CapCenter ZERO closing cost loan. You can receive an additional 1/8th off your rate just for working with CapCenter Realty!",
                tooltip: <>We recommend getting pre-approved for a mortgage before you find the right home.<ol><li>Understand your budget</li><li>Prepare your finances and lifestyle for a successful closing</li><li>Submit a strong offer before another offer is considered or accepted</li></ol>Our 1/8 rate reduction offer does not combine with our Zero Listing Fee bundle.</>
              },
              {
                text: "Get set up on your custom curated search and begin touring homes.",
                tooltip: <>We conduct a pre-offer property analysis to help assess the condition of the home to help you make more informed decisions, quickly.</>
              },
              {
                text: "Begin submitting offers.",
                tooltip: <>A strong offer usually includes a preapproval letter and earnest money deposit information.</>
              },
              {
                text: "Once an offer is accepted, you can review your mortgage commitment offer and lock your rate.",
                tooltip: <>Lock your mortgage rate after you know the subject property address.<br /><br />Lock your rate for free up to 30 days before closing.</>
              },
              {
                text: "Work with your realty and mortgage team to navigate the closing and loan underwriting process.",
                tooltip: <>The transaction typically involves contingencies such as inspections/repairs, appraisals, and financing and other processes such as chattel negotiation,  homeowner's insurance, and closing.<br /><br />A 30-day closing is typical for existing homes, but it can still feel rushed with everything else happening in life.<br /><br />This is part of the process when things get hectic and CapCenter excels.</>
              },            
              {
                text: "Complete your final walkthrough and attend closing."
              },           
              {
                text: "Enjoy your new home!"
              }
            ]}
            CTAButtons={
              <>
                <a href={getUrlWithUtmQueryString(`${baseUrl}/apply-now/purchase`)} className={"mr-4"}>
                  <button className="btn-primary mb-2 d-none d-sm-inline" title="Mortgage purchase pre-approval application">Fill out a pre-approval application</button>
                  <button className="btn-primary mb-2 d-sm-none d-inline" title="Mortgage purchase pre-approval application">Apply now</button>
                </a>
                <a href={`${baseRealEstateUrl}/guides/home-buyers-guide`}>
                  <button
                    className="btn-secondary mb-2 d-none d-sm-inline"
                    title="Learn more about the home buying process"
                  >
                    Learn more about home buying
                  </button>
                  <button
                    className="btn-secondary mb-2 d-sm-none d-inline"
                    title="Learn more about the home buying process"
                  >
                    Learn more
                  </button>
                </a>
              </>
            }
          />

          <NextStepsSection 
            figureContents={
              <>
                <img src={require("../../assets/best-mortgage-preapproved-coborrower.png")} alt="Co-borrowers getting pre-approved on mobile phone"/>
                <figcaption>Take the next step towards homeownership</figcaption>
              </>
            }
            CTAButtons={
              <>
                <a href={getUrlWithUtmQueryString(`${baseUrl}/apply-now/purchase`)} className={"mr-4"}>
                  <button className="btn-primary mb-2 d-none d-sm-inline" title="Submit your pre-approval application for a home loan">Start an online mortgage application</button>
                  <button className="btn-primary mb-2 d-sm-none d-inline" title="Submit your pre-approval application for a home loan">Apply now</button>
                </a>
                <a href={getUrlWithUtmQueryString(`${baseUrl}/forms/rate-alerts`)}>
                  <button className="btn-secondary mb-2 d-none d-sm-inline" title="Sign up for mortgage interest rate alerts">Sign up for rate alerts</button>
                  <button className="btn-secondary mb-2 d-sm-none d-inline" title="Sign up for mortgage interest rate alerts">Sign up for rate alerts</button>
                </a>
              </>
            }
          />
          
          <RefinanceSection props={this.props} />

          <FAQSection 
          questions={[
            {
              question: "Why should I get pre-approved for a mortgage?",
              answer: <>A pre-approval from a mortgage lender helps you understand your budget to narrow your search. When you find a home you want to purchase, your realtor<img src={RegisteredTrademark} className={"registered-trademark"}/> submits an official offer with a pre-approval letter. A non-cash offer without a pre-approval letter from a lender is deemed not serious and is often ignored by the seller.</>
            },
            {
              question: "How much money do I need to buy a house?",
              answer: <>The upfront costs vary widely on subject property, location, and borrower(s). The minimum amount you need includes costs that occur during closing such as the minimum down payment required for the loan product, government fees such as taxes, and escrows such as homeowners insurance premiums.<br/><br/>We have loan products that only require 0% to 3.5% minimum down payment. For a qualified first-time home buyer buying a $350k house in Richmond, VA, the total upfront costs are around $18,000.</>
            },
            {
              question: "How long does it take for me to get pre-approved?",
              answer: <>During business hours, you may get pre-approved in as few as thirty minutes. If our loan consultants are all assisting other clients, we may take a bit longer. For urgent requests, call us or leave us a voice mail on our main line to get your application expedited, even during off hours.</>
            },
            {
              question: "Why should I get pre-approved with CapCenter?",
              answer: <>Unmatched savings with convenient, efficient processes. We have low fees with low rates and guaranteed on-time closings.</>
            },
            {
              question: "What are closing costs?",
              answer: <>Closing costs differ by state and county and generally refer to costs that occur at closing excluding certain items. CapCenter waives costs that are not related to the owner's equity (e.g. down payment), escrows (e.g. homeowner's insurance), or government fees (e.g. taxes) for most standard products. For details, use our <a href={getUrlWithUtmQueryString(`${baseUrl}/mortgage-calculator/`)}>mortgage calculator</a> to see typical industry fees and our waived fees in your region.</>
            },
            {
              question: "I've seen lower rates on the Internet. Why is CapCenter so popular?",
              answer: <>Many lenders artificially lower rates with hidden fees and points to try to win your business. At CapCenter, we provide low rates with low or no fees and publish our rates and fees online for full transparency.  We value honesty and transparency, and most of our clients are experienced mortgage borrowers who are tired of playing games with high, often hidden, "standard" upfront costs.<br/><br/>The main benefit to Zero Closing Cost mortgages is the flexibility. If rates fall, you can refinance with another CapCenter Zero Closing Cost mortgage for savings or move without worrying about the high sunk costs that come with a regular mortgage. The best part is, we'll let you know when it makes sense to refinance, expedite the process, and save big, again!<br/><br/><a href={getUrlWithUtmQueryString(`${baseUrl}/learning/mortgages/what-does-apr-mean`)}>Learn more how rates and APR can be misleading in the mortgage industry.</a></>
            },
            {
              question: "Does CapCenter offer down payment assistance?",
              answer: <>Freddie Mac has a database of down payment assistance programs that are available. We check that database as part of our process to let you know if you may qualify for certain program(s). However, these programs are offered by external parties such as local governments, and there are typically eligibility requirements.<br/><br/>If there's a specific program you are interested in, please let your loan consultant know.</>
            },
            {
              question: "When is the best time to get pre-approved?",
              answer: <>We recommend getting pre-approved as soon as you're serious about purchasing a home. A pre-approval will help you understand your true budget, and if you happen to tour a home that you like and/or is priced below market value, you can put a serious offer immediately to gain a competitive advantage over other potential buyers.<br/><br/>It's important to understand that you don't lock your rate at the time of application. You want to have everything ready to go (i.e. the mortgage application is already submitted) before seriously touring homes, so you can make offers or lock rates when the perfect property is put on the market.</>
            }
          ]}/>

          <ListingsSection props={this.props} />

          <HomesForSaleSection props={{pageType: "buy"}} />
        </main>
      </div>
    </AppWrapper>;
  }
}

export default GetPreapproved