import RegisteredTrademark from '../../assets/registered-trademark.svg';
import React from 'react';
import '../Realty.scss';

class NextStepsSection extends React.Component {
  state = {
    figureContents: <></>,
    CTAContents: <></>,
  };

  componentDidMount() {
    this.setState({ figureContents: this.props.figureContents });
    this.setState({ CTAButtons: this.props.CTAButtons });
  }

  render() {
    return (
      <section className="section-next-steps rtb-flex">
        <div className="figure-wrapper">
          <figure>{this.state.figureContents}</figure>
        </div>

        <div className="rtb-text">
          <h2>
            <i className="fas fa-check-square"></i> Next steps
          </h2>
          <p>
            Speak with your local realtor
            <img src={RegisteredTrademark} className={'registered-trademark'} /> to see how we can help you discover
            your new home.
          </p>

          <div>{this.state.CTAButtons}</div>
        </div>
      </section>
    );
  }
}

export default NextStepsSection;
