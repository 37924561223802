import React from 'react';

import MapQuote from './MapQuoteV2';
import { getQuoteCalculator } from '@capcenter/shared-legacy';

export default class Quote extends React.Component<{
  ready: boolean;
  readyFields: any;
  fields: any;
  children: (props: any) => React.ReactElement<any>;
}> {
  readonly state = {
    quotes: [],
    mapped: {},
    initialLoad: false,
    loading: false,
    prevReady: false
  };

  componentDidMount(): void {
    this.setState({initialLoad: true})
  }

  componentDidUpdate() {
    if (this.props.ready !== this.state.prevReady) {
      if (this.props.ready && (this.state.initialLoad || this.props.fields.recalculate)) {
        this.getQuote();
        this.setState({initialLoad: false});
        this.props.fields.recalculate = false;
      }

      this.setState({ prevReady: this.props.ready });
    }
  }

  getQuote = async () => {
    const newMapped = MapQuote(this.props.readyFields);

    if (JSON.stringify(newMapped) !== JSON.stringify(this.state.mapped)) {
      this.setState({ mapped: newMapped, loading: true });
      await getQuoteCalculator(newMapped).then((quotes: any) => {
        if (JSON.stringify(quotes) !== JSON.stringify(this.state.quotes)) this.setState({ quotes, loading: false });
        else this.setState({ loading: false })
      });
    }
  };

  render() {
    return <>{this.props.children({ quotes: this.state.quotes, loading: this.state.loading, mapped: this.state.mapped })}</>;
  }
}
