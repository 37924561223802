import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '../Icons';
import PageV3 from './PageV3';
import { pages } from '../PagesJson';
import FourOFour from '../000/404';
import FiveHundred from '../000/500';
import RealtyBuy from "./realty/buy/RealtyBuy";
import GetPreapproved from "./realty/preapproval/GetPreapproved";
import RealtySell from "./realty/sell/RealtySell";
import HomeValue from "./realty/home-value/HomeValue";

class AppV3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: window.location.pathname,
      tooltipOpen: '',
      images: [],
      sellSavings: {},
      buySavings: {},
      refiSavings: {},
    };
  }

  tabClick = active => {
    this.setState({ active });
  };

  confirmRoute = () => {
    if (this.state.active !== window.location.pathname) {
      this.setState({ active: window.location.pathname });
    }
  };

  setOpen = tooltipOpen => this.setState({ tooltipOpen });

  setSellSavings = sellSavings => this.setState({ sellSavings });
  setBuySavings = buySavings => this.setState({ buySavings });
  setRefiSavings = refiSavings => this.setState({ refiSavings });

  render() {
    return (
      <Router
        ref={router => {
          this.router = router;
        }}
      >
        <Switch>
          <Route path="/404" render={() => <FourOFour />} />
          <Route path="/500" render={() => <FiveHundred />} />

          {pages.map((route, i) => (
            <Route
              key={i}
              path={route.app.url}
              exact={route.path === '/'}
              onChange={this.routeChange}
              render={() => (
                <PageV3
                  page={route}
                  pages={pages}
                  tabClick={this.tabClick}
                  active={this.state.active}
                  setOpen={this.setOpen}
                  tooltipOpen={this.state.tooltipOpen}
                  confirmRoute={this.confirmRoute}
                  sellSavings={this.state.sellSavings}
                  setSellSavings={this.setSellSavings}
                  refiSavings={this.state.refiSavings}
                  setRefiSavings={this.setRefiSavings}
                  buySavings={this.state.buySavings}
                  setBuySavings={this.setBuySavings}
                />
              )}
            />
          ))}
          <Route path="/best-realtor-agency-buy-home" component={RealtyBuy} />
          <Route path="/best-realtor-agency-sell-home" component={RealtySell} />
          <Route path="/get-preapproved-for-a-home-loan" component={GetPreapproved} />
          <Route path="/how-much-is-my-home-worth" component={HomeValue} />
          <Route
            path="*"
            component={() => {
              window.location.href = '/404';
              return null;
            }}
          />
        </Switch>
      </Router>
    );
  }
}

export default AppV3;
