import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { firebaseAuth } from '../services/Firebase'
import { setupCache } from 'axios-cache-adapter'

let axiosInstance: AxiosInstance;

export const initializeCache = () => {
  const cache = setupCache({
    maxAge: 30 * 60 * 1000,
    exclude: {
      paths: [ /paul\/can/ig ] // don't cache can paul?
    }
  })

  axiosInstance = Axios.create({
    adapter: cache.adapter
  })
}

initializeCache();

export const executeRequest = async (options: AxiosRequestConfig, token?: string) => {
    if (firebaseAuth.currentUser) {
      if (!options.headers)
        options.headers = {}
      options.headers.authorization = `Bearer ${token || await firebaseAuth.currentUser.getIdToken(true)}`
    }
  
    return await axiosInstance(options)
  }