import React from 'react';
import { Colors, Breakpoints } from '@capcenter/shared';
import Icon from '../components/Icon';
import Input from '../components/Input/Input';
import Shadows from '../constants/Shadows';
import zenscroll from 'zenscroll';

import 'simplebar';
import 'simplebar/dist/simplebar.min.css';

class DropSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      searchCount: 0,
      btns: [],
      loading: true,
      open: false,
      selection: { text: '' },
      active: { text: '' },
      topShadow: false,
      bottomShadow: true,
    };
  }

  componentDidMount() {
    if (this.props.btns.length !== 0) {
      this.setState({ btns: this.props.btns, selection: this.props.btns[0] });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.btns) !== JSON.stringify(this.props.btns) && this.props.btns.length !== 0) {
      this.setState({ btns: this.props.btns, selection: this.props.btns[0] });
    }

    if (JSON.stringify(prevState.btns) !== JSON.stringify(this.state.btns) && this.state.btns.length !== 0) {
      setTimeout(() => {
        this.getHeight();
      }, 200);
      window.addEventListener('resize', this.getHeight);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', null);
  }

  getHeight = () => {
    if (this.btnWrap !== null) {
      const btnHeight =
        this.btnWrap.children[0].children[1].children[0].children[0].children[0].children[0].offsetHeight - 1; //62

      this.setState({ btnHeight });
    }
  };

  handleScroll = event => {
    const scrollMax = event.target.scrollHeight - event.target.offsetHeight;

    const scrollPosition = event.target.scrollTop;

    if (scrollPosition <= 0) {
      this.setState({ topShadow: false, bottomShadow: true });
    } else if (scrollPosition >= scrollMax) {
      this.setState({ topShadow: true, bottomShadow: false });
    } else {
      this.setState({ topShadow: true, bottomShadow: true });
    }
  };

  handleChange = e => {
    const newCount = this.state.searchCount + 1;

    const val = e.target.value;

    this.setState(
      {
        search: val,
        searchCount: newCount,
        selection: 0,
      },
      () => this.filterBtns(val, newCount),
    );
  };

  filterBtns = (value, count) => {
    const filtered = this.props.btns.filter(o => o.text.toLowerCase().includes(value.toLowerCase()));

    const shadow = filtered.length >= 5 ? true : false;

    if (count === this.state.searchCount) {
      this.setState({ btns: filtered, selection: filtered[0], topShadow: shadow, bottomShadow: shadow });
    }
  };

  handleKeyDown = event => {
    if (event.key === 'Enter' && this.state.selection) {
      this.btnClick(this.state.selection);
      event.target.blur();
    }

    if (event.key === 'Tab' && this.state.selection) {
      event.persist();

      setTimeout(() => {
        this.btnClick(this.state.selection);
        event.target.blur();
      }, 200);
    }

    if (event.key === 'ArrowDown') {
      const thisKey = this.state.btns.findIndex(x => x.text === this.state.selection.text) + 1;

      if (thisKey !== this.state.btns.length) {
        this.setState({ selection: this.state.btns[thisKey] });

        this.btnWrap.children[0].children[1].children[0].children[0].scrollTop = this.state.btnHeight * thisKey;
      }
    }

    if (event.key === 'ArrowUp') {
      const thisKey = this.state.btns.findIndex(x => x.text === this.state.selection.text) - 1;

      if (thisKey >= 0) {
        this.setState({ selection: this.state.btns[thisKey] });

        this.btnWrap.children[0].children[1].children[0].children[0].scrollTop = this.state.btnHeight * thisKey;
      }
    }
  };

  btnClick = btn => {
    this.setState(
      {
        open: false,
        active: btn,
        selection: btn,
        search: '',
        btns: this.props.btns,
      },
      () => {
        if (btn.onClick) {
          btn.onClick(btn);
        } else {
          this.props.onChange(btn, this.props);
        }
      },
    );
  };

  closeDropdown = event => {
    setTimeout(() => {
      this.setState({ open: false });
    }, 200);
  };

  openDropdown = event => {
    if (window.innerWidth <= Breakpoints.small) {
      const element = event.target.getBoundingClientRect();

      const body = document.body.getBoundingClientRect();

      zenscroll.toY(element.top - body.top - 25, 500);
    }

    this.setState({ open: true });
  };

  render() {
    return (
      <>
        <Input
          disabled={this.props.btns.length === 0}
          invalid={this.props.invalid}
          invalidCheck={true}
          label={this.props.label}
          placeholder={this.props.placeholder}
          value={this.state.open ? this.state.search : this.state.active.text}
          onChange={this.handleChange}
          onBlur={this.closeDropdown}
          onKeyDown={this.handleKeyDown}
          onFocus={this.openDropdown}
          autoComplete={false}
          prepend={
            <span
              className="input-group-text"
              style={{
                backgroundColor: this.props.btns.length === 0 ? Colors.gray350 : Colors.white100,
                width: 34,
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: this.props.invalid ? Colors.brightRed : '#647778',
              }}
            >
              {this.state.active.text !== '' &&
                !this.state.open &&
                (this.state.active.icon || this.state.active.image) ? (
                this.state.active.icon ? (
                  <Icon style={{ fontSize: 16 }} icon={this.state.active.icon} />
                ) : (
                  <img
                    className="rounded-circle"
                    src={this.state.active.image}
                    alt={this.state.active.text}
                    style={{ width: 20, height: 20 }}
                  />
                )
              ) : (
                <Icon icon="caret-down" />
              )}
            </span>
          }
          prependClick
          {...this.props}
        />
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              opacity: this.state.open ? 1 : 0,
              pointerEvents: this.state.open ? 'auto' : 'none',
              zIndex: 100,
              top: '-1rem',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                boxShadow: 'inset 0px 5px 5px -2px rgba(0,0,0,0.25)',
                zIndex: 2,
                pointerEvents: 'none',
                transition: 'opacity .5s ease',
                opacity: this.state.topShadow ? 1 : 0,
              }}
            />

            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                boxShadow: 'inset 0px -5px 5px -2px rgba(0,0,0,0.25)',
                zIndex: 2,
                pointerEvents: 'none',
                transition: 'opacity .5s ease',
                opacity: this.state.bottomShadow ? 1 : 0,
              }}
            />

            <div
              data-simplebar={true}
              data-simplebar-auto-hide={false}
              onScroll={this.handleScroll.bind(this)}
              style={{
                maxHeight: 300,
                zIndex: 0,
                boxShadow: Shadows.dropdown,
              }}
              ref={node => (this.btnWrap = node)}
            >
              <div className="list-group">
                {this.state.btns.length > 0 &&
                  this.state.btns.map((btn, i) => {
                    const btnActive = btn.text === this.state.selection.text;

                    return (
                      <button
                        key={i}
                        tabIndex={0}
                        onMouseDown={() => this.btnClick(btn)}
                        style={{
                          textTransform: 'capitalize',
                          padding: '5px 15px',
                          backgroundColor: btnActive && Colors.gray350,
                          cursor: 'pointer',
                          minHeight: 50,
                        }}
                        type="button"
                        className={'list-group-item list-group-item-action d-flex align-items-center bold '}
                      >
                        <div style={{ display: 'inline-block', marginRight: 15 }}>
                          <div className="d-flex align-items-center justify-content-center" style={{ padding: 5 }}>
                            {btn.icon && <Icon style={{ fontSize: '150%' }} icon={btn.icon} />}

                            {btn.image && (
                              <img
                                className="rounded-circle"
                                src={btn.image}
                                alt={btn.text}
                                style={{ width: 40, backgroundColor: Colors.gray400 }}
                              />
                            )}
                          </div>
                        </div>
                        <div style={{ display: 'inline-block', textTransform: 'capitalize', lineHeight: 1 }}>
                          {btn.text}
                        </div>
                      </button>
                    );
                  })}

                {this.state.btns.length === 0 && this.state.search !== '' && (
                  <button
                    type="button"
                    className="
											list-group-item 
											list-group-item-action 
											disabled"
                    disabled
                  >
                    No Results
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DropSearch;
