import { homeValueUrl } from '@capcenter/shared';

const GetNetProceeds = (mortgage, salePrice, realtyRate) => {
  return fetch(
    homeValueUrl + 'netProceeds?mortgage=' + mortgage + '&salePrice=' + salePrice + '&realtyRate=' + realtyRate,
    {
      method: 'GET',
    },
  )
    .then(function (resp) {
      //console.log(resp)

      return resp.json();
    })
    .then(function (data) {
      //console.log(data)

      return data;
    })
    .catch(error => {
      console.error('Get Error', error);
    });
};

export default GetNetProceeds;
