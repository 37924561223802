import React, {Component} from 'react'

const Video = ({id, onClick}) => (
	<div style={{height: "300px", width: "100%", position: "relative"}}>
		<div style={{position: "absolute", zIndex: 100, top:0, right:0, bottom:0, left:0}}>
			<div className="container" style={{position: "relative", height: "100%", padding:0, }}>
		
				<iframe title="testimonial" 
					style={{display: "block", height: "100%", width: "100%", maxWidth: 711, margin: "0 auto"}} 
					src={"https://www.youtube.com/embed/"+id+"?controls=0&rel=0"} 
					frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
					allowFullScreen></iframe>
	    </div>
    </div>
    
  	<div style={{
    	position: "absolute", 
    	top:0,right:0,bottom:0,left:0, 
    	backgroundImage: "url(https://img.youtube.com/vi/"+id+"/0.jpg)", 
    	backgroundSize: "cover",
    	backgroundPosition: "center center",
    	filter: "blur(8px)",
    	transform: "scale(1.1)",
    	zIndex: 0}} />
    	
	</div>
)

export class WhyCapCenter extends Component {

  constructor(props) {
    super(props);  
    this.state = {
	    id:0
    }
  }
  
  toggle = () => this.setState({id: this.state.id === 0 ? 1 : 0})

  render() {

    return (
	    
			<>
					<Video id={"f5HLrly8Y5A"} onClick={this.toggle} />
			</>    
	    

    );
  }
}

export default WhyCapCenter

