import React from 'react';
import numeral from 'numeral';
import FindLoanLimits from './FindLoanLimitsRates';
import FindLoanAmountType from './FindLoanAmountType';
import { Input, Tooltip } from '@capcenter/shared-legacy';

// TURN THIS ON WHEN DEBUGGING
const isDebug = false;

export default class LoanAmountValue extends React.Component<{
  active: string;
  onChange: Function;
  fields: any;
  label: string | JSX.Element;
  inputKey: string;
  disabled: Boolean;
  invalid: Boolean;
  availability: any;
  exceptions: any;
}> {
  readonly state = {
    invalidHigh: false,
    invalidLow: false,
    tempValue: '',
    tempCount: 0,
    value: '',
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    const previousCountyId = prevProps.availability?.CountyId;
    const countyId = this.props.availability?.CountyId;

    const previousLoanProgram = prevProps.fields.LoanProgram;
    const loanProgram = this.props.fields.LoanProgram;

    const previousLoanTerm = prevProps.fields.LoanTerm;
    const loanTerm = this.props.fields.LoanTerm;

    let changeValue = false;

    // If Initial API data load is done
    if (
      Object.keys(prevProps.availability).length === 0 &&
      Object.keys(this.props.availability).length !== 0 &&
      this.props.active !== ''
    ) {
      changeValue = true;
    } else if (Object.keys(this.props.availability).length !== 0) {
      if ((previousCountyId !== countyId ||
          previousLoanTerm !== loanTerm ||
          previousLoanProgram !== loanProgram)) {
        changeValue = true;
      } else if (this.props.fields.LoanAmount && this.props.active
        && this.props.active !== this.props.fields.LoanAmount) {
        changeValue = true;
      }
    }

    if (changeValue) {
      this.changeValue(this.convertDollar(this.props.active));
    }
  }

  convertDollar = (value: string) => Number(value.replace(/[^0-9-]+/g, ''));

  findMinMaxAmounts = (fields: any) => {
    const { loanMinLimit, loanMaxLimit } = FindLoanLimits(
      fields,
      this.props.availability.ProductCombinations,
      this.props.exceptions,
      this.props.availability.LoanAmountLimits,
    );

    const minAmount = loanMinLimit;
    const maxAmount = loanMaxLimit;

    return { minAmount, maxAmount };
  };

  onChange = (value: number) => {
    const thisTempCount = this.state.tempCount + 1;

    this.setState({ tempCount: thisTempCount, tempValue: value.toString() });

    setTimeout(() => {
      if (thisTempCount.toString() === this.state.tempCount.toString()) {
        this.changeValue(value);
      }
    }, 400);
  };

  changeValue = (value: number) => {
    let fields = JSON.parse(JSON.stringify(this.props.fields));

    fields.LoanAmountType = FindLoanAmountType(value.toString(), this.props.availability.LoanAmountLimits);

    const { minAmount, maxAmount } = this.findMinMaxAmounts(fields);

    // Create current state object for comparison
    const currentState = {
      tempValue: this.state.tempValue,
      invalidHigh: this.state.invalidHigh,
      invalidLow: this.state.invalidLow,
      value: this.state.value,
    };

    if (value <= maxAmount && value >= minAmount) {
      const newState = {
        tempValue: '',
        invalidHigh: false,
        invalidLow: false,
        value: value.toString(),
      };

      if (JSON.stringify(newState) !== JSON.stringify(currentState)) {
        this.setState(newState, this.props.onChange(value.toString(), this.props));
      }
    } else {
      if (value > maxAmount) {
        const newState = {
          tempValue: '',
          invalidHigh: true,
          invalidLow: false,
          value: value.toString(),
        };
        if (JSON.stringify(newState) !== JSON.stringify(currentState)) {
          this.setState(newState, this.props.onChange('', this.props));
        }
      } else {
        const newState = {
          tempValue: '',
          invalidHigh: false,
          invalidLow: true,
          value: value.toString(),
        };
        if (JSON.stringify(newState) !== JSON.stringify(currentState)) {
          this.setState(newState, this.props.onChange('', this.props));
        }
      }
    }
  };

  render() {
    if (Object.keys(this.props.availability).length !== 0 && this.props.disabled !== true) {
      let fields = JSON.parse(JSON.stringify(this.props.fields));

      if (this.state.value === '' && this.props.fields.LoanAmount) {
        this.changeValue(this.props.fields.LoanAmount);
      }

      fields.LoanAmount = this.state.value.toString();

      fields.LoanAmountType = FindLoanAmountType(fields.LoanAmount, this.props.availability.LoanAmountLimits);

      const { minAmount, maxAmount } = this.findMinMaxAmounts(fields);

      return (
        <div className="form-row">
          <div className="col-12">
            <Tooltip
              html={<div />}
              error={true}
              distance={-25}
              errorHtml={
                <span>
                  {this.state.invalidLow ? (
                    <>
                      Amount is lower than ${numeral(minAmount).format('0,0')}
                      <br />
                      For lower amounts, Call Us
                    </>
                  ) : (
                    <></>
                  )}

                  {this.state.invalidHigh ? (
                    <>
                      Amount is higher than ${numeral(maxAmount).format('0,0')}
                    </>
                  ) : (
                    <></>
                  )}
                </span>
              }
              open={this.state.invalidHigh || this.state.invalidLow}
            >
              <div>
                <Input
                  id='quote--LoanAmount'
                  label={
                    <>
                      {this.props.label}
                      {isDebug &&
                        ' - ( $' +
                          numeral(minAmount).format('0a') +
                          ' - $' +
                          numeral(maxAmount).format('0a')}
                    </>
                  }
                  disabled={this.props.disabled}
                  value={
                    this.state.tempValue === ''
                      ? this.state.value === ''
                        ? ''
                        : parseInt(this.state.value)
                      : parseInt(this.state.tempValue)
                  }
                  mask="dollar"
                  invalid={this.state.invalidHigh || this.state.invalidLow || this.props.invalid}
                  invalidCheck={this.state.invalidHigh || this.state.invalidLow}
                  onChange={(e: any) => this.onChange(this.convertDollar(e.target.value))}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      );
    } else {
      return (
        <div className="form-row">
          <div className="col-12">
            <Input mask="dollar" disabled={true} label={this.props.label} value={''} id='quote--LoanAmount' />
          </div>
        </div>
      );
    }
  }
}
