import { Tooltip } from '@capcenter/shared-legacy';
import React from 'react';
import '../Realty.scss';

class ProcessSection extends React.Component {
  state = {
    lines: [],
    CTAContents: <></>
  };

  componentDidMount() {
    this.setState({ lines: this.props.lines });
    this.setState({ CTAButtons: this.props.CTAButtons });
  }

  render() {
    return (
      <section className="section-process rtb-flex">
        <div>
          <h2>
            <i id="howItWorksHeader" className="fas fa-project-diagram" data-aos-anchor-placement="top-center"></i> How it works
          </h2>
          <ol>
            {this.state.lines.map((line, index) => {
              return (
                <li
                  key={`processStep${index}`}
                  data-aos="fade-up"
                  data-aos-delay={300 * index}
                  data-aos-anchor="#howItWorksHeader"
                  data-aos-anchor-placement="bottom-center"
                  data-aos-once="true"
                  data-aos-duration="2000"
                >
                  {line.text}&nbsp;
                  {line.tooltip && (
                    <Tooltip
                      arrow
                      html={
                        <div>
                          <p>{line.tooltip}</p>
                        </div>
                      }
                    >
                      <i className="fas fa-info-circle"></i>
                    </Tooltip>
                  )}
                </li>
              );
            })}
          </ol>
          <p>Our dedicated teams work together behind the scenes to create a seamless experience for you.</p>

          <div className="text-center mt-4">
            {this.state.CTAButtons}
          </div>
        </div>
      </section>
    );
  }
}

export default ProcessSection;
