import React from 'react';
import '../Realty.scss';

class DataSection extends React.Component {
  render() {
    return (
      <section className="section-data">
        <h2>
          Why people choose{' '}
          <span className="text-nowrap">
            <svg className="h-5 capcenter-logo" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill="#EA701B"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.564 17.82H11.153V14.981L21.825 8.716V0L6 9.086V32.984H21.825V27.068H13.564V17.82Z"
              ></path>
              <path
                fill="#EA701B"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.104 0V8.716L35.775 14.981V17.82H33.362V27.068H25.104V32.984H40.926V9.086L25.104 0Z"
              ></path>
            </svg>{' '}
            CapCenter
          </span>
        </h2>
        <div className="rtb-flex">
          <div className="large">
            <h3 className="large">One-stop shop</h3>
            <p className="large" >
              From Search to Settlement - CapCenter has all the services you need under one roof, and our one-stop-shop
              approach let's you focus on what matters.
            </p>
          </div>
          <div className="large">
            <h3 className="large">26 years</h3>
            <p className="large" >
              26 years of Service, Savings, and Exceeding Expectations - From our 5-star rating with more than 500
              reviews on Zillow to our 95% client satisfaction rating, our clients have spoken. The savings are great
              but at CapCenter, service is absolute.
            </p>
          </div>
          <div className="large">
            <h3 className="large">Family owned and operated</h3>
            <p className="large" >
              Buying a home is personal, and we know that better than anyone. Our team of local experts live in the
              communities we serve and uses this knowledge to help guide you home.
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default DataSection;
