import React, { Component } from 'react';
import './BuyRealtyHero.scss';
import { baseUrl } from '@capcenter/shared';
import { getUrlWithUtmQueryString } from '@capcenter/shared-legacy';

export class BuyRealtyHero extends Component {
  render() {
    return (
      <div className='buy-realty-hero'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='buy-realty-hero-left col-lg'>

             </div>
            <div className='buy-realty-hero-right col-lg buy-realty-hero-image'>

            </div>
          </div>
        </div>
        <div className='container text-overlap'>
          <div className='row'>
            <div className='buy-realty-hero-left col-lg buy-realty-hero-main'>
              <h1>Buy a home with <div className='d-inline-block'>Zero Closing Costs</div></h1>
              <h2>Zero Closing Cost home purchases with top-rated REALTORS® and <a href="https://faq.capcenter.com/article/171-capcenter-1-rate-reduction-program" target="_blank" style={{whiteSpace: "nowrap"}} rel="noopener noreferrer">1% rate reduction program</a>.</h2>

              <div className='row'>
                <div className='col-sm'>
                  <a href='/forms/request-buyers-agent' title="Request a top-rated real estate agent"><button className='btn-primary'>Request a REALTOR®</button></a>
                </div>
                <div className='col-sm'>
                  <a href={getUrlWithUtmQueryString(`${baseUrl}/mortgage-calculator/purchase`)} title="Mortgage calculator that shows estimated savings and any fees"><button>See savings details</button></a>
                </div>
              </div>
            </div>
            <div className='buy-realty-hero-right col-lg'>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BuyRealtyHero;