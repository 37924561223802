import React from 'react';
import numeral from 'numeral';
import { BtnGroup } from '@capcenter/shared-legacy';
import { Colors } from '@capcenter/shared';

//const nFormat = '0,0';

export default class Rates extends React.Component<{
  active: string;
  onChange: Function;
  fields: any;
  invalid: Boolean;
  disabled: Boolean;
  quotes: Array<any>;
  hideSavings?: Boolean;
}> {
  changeRate = (rate: number, quotes: any) => {
    const quote = quotes[rate];

    this.props.onChange(
      rate.toString(),
      { inputKey: 'rateKey' },
      this.props.onChange(
        quote?.Rate?.BaseRate,
        { inputKey: 'BaseRate' },
        this.props.onChange(
          quote?.Rate?.Points,
          { inputKey: 'Points' },
          this.props.onChange(quote.refiOff, { inputKey: 'refiOff' }),
        ),
      ),
    );
  };

  ratesDefined = (quotes: any) => {
    return quotes.length !== 0 && quotes[0].Rate;
  };

  format = (i: number, key: string, quotes: any) => {
    if (this.ratesDefined(quotes)) {
      if (quotes[i].Rate.RateWithoutCapCenterMortgagePlusRealtyDiscountRate === undefined || quotes[i].Rate.RateWithoutCapCenterMortgagePlusRealtyDiscountRate === 0) {
        return (
          <span>
            {numeral((quotes as any)[i].Rate[key]).format('0.000')}
            {key === 'BaseRate' ? '%' : ''}
            {key === 'Points' ? ' pts' : ''}
            {key === 'Apr' ? (
              <span>
                % <small>APR</small>
              </span>
            ) : (
              ''
            )}
          </span>
        );
      } else if (key === 'RateWithoutCapCenterMortgagePlusRealtyDiscountRate') {
        return (
          <span>
            <del>
              {numeral((quotes as any)[i].Rate[key]).format('0.000')}
              {key === 'RateWithoutCapCenterMortgagePlusRealtyDiscountRate' ? '%' : ''}
            </del>
          </span>
        );
      } else if (key === 'BaseRate') {
        return (
          <span>
            <b>
              {numeral((quotes as any)[i].Rate[key]).format('0.000')}
              {key === 'BaseRate' ? '%' : ''}
            </b>
          </span>
        );
      } else {
        return (
          <span>
            {numeral((quotes as any)[i].Rate[key]).format('0.000')}
            {key === 'Points' ? ' pts' : ''}
            {key === 'Apr' ? (
              <span>
                % <small>APR</small>
              </span>
            ) : (
              ''
            )}
          </span>
        );
      }

    } else {
      return '-';
    }
  };

  rateBtn = (i: number, quotes: any) => {
    return (
      <span>
        {quotes[i]?.Rate?.RateWithoutCapCenterMortgagePlusRealtyDiscountRate !== 0 && quotes[i]?.Rate?.RateWithoutCapCenterMortgagePlusRealtyDiscountRate !== undefined ? <div style={{ paddingBottom: 3 }}>{this.format(i, 'RateWithoutCapCenterMortgagePlusRealtyDiscountRate', quotes)} <br /></div> : <></>}
        <div style={{ color: '#EA701B' }}>{this.format(i, 'BaseRate', quotes)}</div>
        <hr style={{ margin: 5 }} />
        {this.format(i, 'Apr', quotes)}
        <br />
        <div style={{ fontSize: '0.75rem' }}>{this.format(i, 'Points', quotes)}</div>
      </span>
    );
  };

  tip = (i: number, quotes: any) => {
    if (this.ratesDefined(quotes)) {
      if ((quotes as any)[i].Rate.Points == 0) {
        return 'Base rate.';
      } else {
        return (
          numeral((quotes as any)[i].Rate.Points).format('0.000') +
          '% of the loan is paid during closing to lower the interest rate.'
        );
      }
    } else {
      return '';
    }
  };

  componentDidUpdate(prevProps: any) {
    const currentQuotes = this.props.quotes;
    const currentFields = this.props.fields;

    if (currentQuotes.length !== 0 && currentFields.rateKey !== '') {

      const fieldsHaveChanged = (currentFields.BaseRate !== currentQuotes[Number(currentFields.rateKey)]?.Rate?.BaseRate ||
        currentFields.Points !== currentQuotes[Number(currentFields.rateKey)]?.Rate?.Points ||
        currentQuotes !== prevProps.quotes)

      if (currentFields.refiOff === '' || (currentFields.refiOff !== '' && fieldsHaveChanged)) {
        this.changeRate(currentFields.rateKey, currentQuotes);
      }
    }
  }

  render() {
    const quotes = this.props.quotes;

    const btnDisabled = this.props.quotes.length !== 0 ? false : true;

    return (
      <>
        {this.props.disabled ? (
          <div style={{ pointerEvents: 'none' }}>
            <BtnGroup
              btns={[
                {
                  children: [
                    <span key={0}>
                      -<hr style={{ margin: 5 }} />-<br />-
                    </span>,
                  ],
                  disabled: true,
                },
                {
                  children: [
                    <span key={1}>
                      -<hr style={{ margin: 5 }} />-<br />-
                    </span>,
                  ],
                  disabled: true,
                },
                {
                  children: [
                    <span key={2}>
                      -<hr style={{ margin: 5 }} />-<br />-
                    </span>,
                  ],
                  disabled: true,
                },
              ]}
            />
          </div>
        ) : (
          <BtnGroup
            className={'rate-group'}
            color={Colors.orangeSecondary}
            invalid={this.props.invalid}
            style={{ padding: '0.375rem 0.375rem' }}
            btns={quotes.map((quote, index) =>
              (({
                id: `quote--rate-${index}-button`,
                className: 'btn-rate',
                children: this.rateBtn(index, quotes),
                tooltip: this.tip(index, quotes),
                disabledTooltip: '',
                active: this.props.active === index.toString(),
                onClick: () => !btnDisabled && this.changeRate(index, quotes),
                disabled: btnDisabled,
              }))
            )}
          />
        )}
      </>
    );
  }
}
