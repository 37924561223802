import React, { Component } from 'react';
import { Colors, Weights, AppList, AppIcon } from '@capcenter/shared';
import getQuote from '../api/quote/getQuote';
import RatesUpdated from '../components/RatesUpdated';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './RatesCarousel.scss';
import numeral from 'numeral';

const CarouselItem = props => (
  <div className="text-center  col-xs-12 col-sm-9 col-lg-6 col-xl-5" style={{ margin: '0 auto' }}>
    <span style={{ fontSize: '140%' }}>
      <div style={{ display: 'inline-block', width: 30, height: 30, marginRight: 10, verticalAlign: 'middle' }}>
        <AppIcon app={AppList.todaysRates} />
      </div>
      {props.title}
    </span>
    <br />

    <div className="d-flex justify-content-around" style={{ padding: '5px 0' }}>
      {(Array.isArray(props.rates) && props.rates.length > 0) ? (
        props.rates.map((rate, i) => (
          <div key={i}>
            {numeral(rate.Rate.BaseRate).format('0.000')}%
            <hr style={{ margin: '2.5px 0' }} />
            {numeral(rate.Rate.Points).format('0.000')} pts
            <br />
            {numeral(rate.Rate.Apr).format('0.000')}%&nbsp;APR
          </div>
        ))
      ) : (
        <>
          <div>
            _.___%
            <hr style={{ margin: '2.5px 0' }} />
            _.___ pts
            <br />
            _.___% APR
          </div>

          <div>
            _.___%
            <hr style={{ margin: '2.5px 0' }} />
            _.___ pts
            <br />
            _.___% APR
          </div>

          <div>
            _.___%
            <hr style={{ margin: '2.5px 0' }} />
            _.___ pts
            <br />
            _.___% APR
          </div>
        </>
      )}
    </div>

    <div className="font-italic mb-4">
      30 Year Fixed in Richmond, VA
      <br />
      <small>
        <RatesUpdated />
      </small>
    </div>
  </div>
);

export class RatesCarousel extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      rates: [],
      refiRates: [],
      refiLoading: true,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    getQuote(
      'CountyId=123&DownPayment=60000&LoanAmount=240000&LoanProgram=1&LoanPurpose=2&LoanTerm=1&LoanType=1&NeedRealtyTeam=true&PropertyType=1&PropertyUse=1&PropertyValue=300000&PurchasePrice=300000&RegularMilitary=false&PreviousVALoan=false&HomeownersInsuranceEscrow=0&PropertyTax=0',
    ).then(quote => {
      if (quote.status === undefined) {
        if (this._isMounted) {
          this.setState({ refiRates: quote });
        }
      } else {
        this.setError(quote.statusText);
      }
    });

    getQuote(
      'CountyId=123&DownPayment=60000&LoanAmount=240000&LoanProgram=1&LoanPurpose=1&LoanTerm=1&LoanType=1&NeedRealtyTeam=true&PropertyType=1&PropertyUse=1&PropertyValue=300000&PurchasePrice=300000&RegularMilitary=false&PreviousVALoan=false&HomeownersInsuranceEscrow=0&PropertyTax=0',
    ).then(quote => {
      if (quote.status === undefined) {
        if (this._isMounted) {
          this.setState({ rates: quote });
        }
      } else {
        this.setError(quote.statusText);
      }
    });
  }

  setError = error => {
    console.log(error);
    this.setState({ error: error });
  };

  render() {
    return (
      <>
        <div
          className="carousel-parent"
          style={{
            height: '100%',
            width: '100%',
            background: 'linear-gradient(to bottom, ' + Colors.black500 + ' 0%,#000000 100%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: Colors.white100,
            fontSize: '130%',
            fontWeight: Weights.bold,
            textAlign: 'center',
          }}
        >
          <div style={{ width: '100%' }}>
            <Carousel
              selectedItem={this.props.selectedItem ? this.props.selectedItem : 0}
              showThumbs={false}
              showStatus={false}
              showArrows={false}
            >
              <CarouselItem rates={this.state.refiRates} title="Refinance Rates" {...this.props} />
              <CarouselItem rates={this.state.rates} title="Purchase Rates" {...this.props} />
            </Carousel>

            {this.props.link && <a href={this.props.link}>{this.props.linkText} &#8250;</a>}
          </div>
        </div>
      </>
    );
  }
}

export default RatesCarousel;
