import { quoteApiUrlLenderPrice } from '@capcenter/shared';
import State from './interfaces/State';
import checkSessionStorage from './checkSessionStorage';

export default async function getStates(): Promise<Array<State> | string> {
  const url: string = `${quoteApiUrlLenderPrice}v1/states`;
  const dataId = 'ccStates';
  const sessionData = checkSessionStorage(dataId);

  if (sessionData) {
    return sessionData;
  } else {
    try {
      let response = await fetch(url);
      let parsedResponse = await response.json();

      let states: Array<State> = parsedResponse;

      window.sessionStorage.setItem(dataId, JSON.stringify(states));
      window.sessionStorage.setItem(dataId + 'Date', JSON.stringify(new Date()));

      return states;
    } catch (err) {
      console.log(err);

      return 'Unexpected States Database Error. Please try again later.';
    }
  }
}
