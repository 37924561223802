import React from 'react';
import mapQuoteWidget from './mapQuoteWidget';
import defaultState from './defaultState.json';
import {mapQuoteWidgetFromFields} from '@capcenter/quote';
import queryString from "qs";

export default class QuoteState extends React.Component<{
  children: (props: any) => React.ReactElement<any>;
  LoanPurpose?: string;
  quoteWidget?: object;
  defaultState?: boolean;
  queryParameters?: boolean;
}> {

  readonly state = {
    // Quote & Apply Fields
    LoanProgram: '',
    LoanPurpose: '',
    LoanTerm: '',
    LoanType: '',
    PropertyType: '',
    PropertyUse: '',
    DownPayment: '',
    LoanAmount: '',
    PurchasePrice: '',
    PropertyLocation: '',
    CountyId: '',
    CountyRealty: '',

    // Apply Fields
    NeedRealtyTeam: '',
    BaseRate: '',
    Points: '',
    ProductCombinationId: '',

    // Maryland Apply Fields
    StateId: '',
    FirstTimeHomeBuyer: '',
    AnnualIncome: '',
    ExistingLoanBalance: '',
    OriginalLoanAmount: '',
    PurchasedWithinLastYear: false,

    // Xsell indicators
    listLead: false,
    buyLead: false,
    listCrossSell: false,
    buyCrossSell: false,



    // Needs Categorization
    region: '',
    LoanAmountType: '',
    rateKey: '',
    refiOff: '',
    isCashOutRefinanceDisabled: '',
    HomeownersInsuranceEscrow: '',
    PropertyTax: '',
  };

  componentDidMount() {
    let urlQuery = mapQuoteWidget();

    if (this.props.LoanPurpose) {
      urlQuery.LoanPurpose = this.props.LoanPurpose;
    }

    let duplicateDefaultState = Object.assign({}, defaultState);

    if (this.props.quoteWidget) {
      Object.assign(duplicateDefaultState, this.props.quoteWidget);
    }

    if (this.props.defaultState) {
      let urlQueryWithDefaults = this.overrideWithDefaults(urlQuery, duplicateDefaultState);
      this.setState(urlQueryWithDefaults, this.updateQueryParameters);
    } else {
      this.setState(urlQuery, this.updateQueryParameters);
    }
  }

  isEqualsOnDefaultKeys = (object1: any, object2: any) => {
    const keys = Object.keys(defaultState);

    for (let key of keys) {
      if (object1[key] != object2[key]) {
        return false;
      }
    }

    return true;
  };

  isStateFalseyOnDefaultKeys = (object: any) => {
    const keys = Object.keys(defaultState);

    for (let key of keys) {
      if ((object[key] === false && (defaultState as any)[key] === true) || object[key]) {
        return false;
      }
    }

    return true;
  };

  overrideWithDefaults = (object: any, defaults: any) => {
    const overriddenObject = Object.assign({}, object);
    const keys = Object.keys(defaults);

    for (let key of keys) {
      if (!(object[key] === false && defaults[key] === true) && !object[key]) {
        overriddenObject[key] = defaults[key];
      }
    }

    return overriddenObject;
  };

  updateQueryParameters = () => {
    if (this.props.queryParameters) {
      if (this.props.defaultState && this.isEqualsOnDefaultKeys(this.state, defaultState)
      || (!this.props.defaultState) &&  this.isStateFalseyOnDefaultKeys(this.state)) {
        window.history.pushState(null, '', window.location.pathname);
      } else {
        const locationWithUpdatedQueryString = window.location.pathname + '?' + queryString.stringify({quoteWidget: mapQuoteWidgetFromFields(this.state)});
        window.history.pushState(null, '', locationWithUpdatedQueryString);
      }
    }
  };

  onChange = (value: any, props: any, callback?: any) => {
    this.setState(
      {
        [props.inputKey]: value,
      },
      () => {
        if (callback) { callback(); }
        this.updateQueryParameters();
      },
    );
  };

  onChangeBoolean = (value: any, props: any, callback?: any) => {
    this.setState(
      {
        [props.inputKey]: value.text,
      },
      () => {
        if (callback) { callback(); }
        this.updateQueryParameters();
      },
    );
  };

  render() {
    return (
      <>
        {this.props.children({
          fields: this.state,
          onChange: this.onChange,
          onChangeBoolean: this.onChangeBoolean,
        })}
      </>
    );
  }
}
