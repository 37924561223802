import React from 'react';
import Slider from 'rc-slider';
import {Colors} from '@capcenter/shared';

import './Slider.scss'

class CCSlider extends React.Component {

 constructor(props) {
    super(props);
    
	  this.state = {
	    lastChanged: new Date(),
	    lastVal: 0,
	  }	
	}
		  
  onAfterChange = (val) => {
	  
	  // This fixes an issue with the slider, hopefully won't be needed in the future
	  
	  const newDate = new Date()
	  
	  const lastDate = this.state.lastChanged
	  
	  const diff = newDate - lastDate;
	  
	  if(diff > 100){
		  //console.log(diff, "change", val)
		  this.setState({lastChanged: new Date(), lastVal: val})
	  	this.props.onAfterChange(val)
	  }else{
		  //console.log(diff, "fix", this.props.value, val, this.state.lastVal)
		  this.props.onAfterChange(this.state.lastVal)
	  }
  }

  render() {
	  


    return (
	    <div>
	    	
				<Slider 
					tabIndex={0}
					{...this.props}
					onAfterChange={(val)=>this.onAfterChange(val)}
					style={{
						position: "relative",
						height: 14,
						padding: "5px 0",
						width: "93%",
						borderRadius: 6,
						MsTouchAction: "none",
						touchAction: "none",
						WebkitBoxSizing: "border-box",
						boxSizing: "border-box",
						WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
						margin: "0 3.5%",
						marginBottom: 20,
						marginTop: 12,
					}}
					handleStyle={{
						position: "absolute",
						marginLeft: -12,
						marginTop: -9,
						width: 24,
						height: 24,
						borderRadius: "50%",
						backgroundColor: Colors.white100,
						MsTouchAction: "pan-x",
						touchAction: "pan-x",
						boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.5)"	
					}}
					trackStyle={{
						position: "absolute",
						left: 0,
						height: 8,
						borderRadius: 8,
						backgroundColor: Colors.primary,
					}}
					railStyle={{
						position: "absolute",
						width: "100%",
						backgroundColor: Colors.gray400,
						height: 8,
						borderRadius: 8,
					}}
					dotStyle={{
						position: "absolute",
						bottom: -2,
						marginLeft: -1,
						width: 2,
						height: 14,
						backgroundColor: Colors.gray400,
						cursor: "pointer",
						verticalAlign: "middle",
					}}
				/>
			</div>
    );
  }
}

export default CCSlider;
